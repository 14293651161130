import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DashboardSideType } from 'src/@types/dashboard';
import {
  IDeleteUndefinedTransactionManagerFilter,
  IUndefinedTransactionState,
  IVerifyUndefinedTransactionFilter,
  IVerifyUndefinedTransactionManagerFilter,
  UndefinedTransactionSearchType,
  UndefinedTransactionTypeType,
} from 'src/@types/undefined-transaction';
import { getMonth, getYear } from 'src/utils/generateDate.util';

const initialState: IUndefinedTransactionState = {
  selectedUndefinedTransactionId: null,
  undefinedTransactions: [],
  default: {
    dashboardSide: 'revenue',
    revenue: {
      create: {
        dialog: {
          toggle: false,
        },
      },
      verify: {
        filter: {
          bankAccountId: null,
          ewalletAccountId: null,
          searchCharacter: '',
          month: getMonth(),
          year: getYear(),
          searchType: UndefinedTransactionSearchType.VERIFY,
          type: UndefinedTransactionTypeType.UNIDENTIFIED_RECEIPT,
        },
        mode: 'EDIT',
        dialog: {
          toggle: false,
        },
      },
      delete: {
        mode: 'EDIT',
      },
      reject: {
        mode: 'EDIT',
      },
    },
    expense: {
      create: {
        dialog: {
          toggle: false,
        },
      },
      verify: {
        filter: {
          bankAccountId: null,
          ewalletAccountId: null,
          searchCharacter: '',
          month: getMonth(),
          year: getYear(),
          searchType: UndefinedTransactionSearchType.VERIFY,
          type: UndefinedTransactionTypeType.UNIDENTIFIED_REFUND,
        },
        mode: 'EDIT',
        dialog: {
          toggle: false,
        },
      },
      delete: {
        mode: 'EDIT',
      },
      reject: {
        mode: 'EDIT',
      },
    },
  },
  manager: {
    dashboardSide: 'revenue',
    revenue: {
      verify: {
        mode: 'VERIFY',
        dialog: {
          toggle: false,
        },
        filter: {
          searchCharacter: '',
          limit: 12,
          searchType: UndefinedTransactionSearchType.MANAGER_VERIFY,
          type: UndefinedTransactionTypeType.UNIDENTIFIED_RECEIPT,
          bankAccountId: null,
          ewalletAccountId: null,
        },
      },
      delete: {
        mode: 'DELETE',
        dialog: {
          toggle: false,
        },
        filter: {
          searchCharacter: '',
          limit: 12,
          searchType: UndefinedTransactionSearchType.MANAGER_DELETE,
          type: UndefinedTransactionTypeType.UNIDENTIFIED_RECEIPT,
          bankAccountId: null,
          ewalletAccountId: null,
        },
      },
    },
    expense: {
      verify: {
        mode: 'VERIFY',
        dialog: {
          toggle: false,
        },
        filter: {
          searchCharacter: '',
          limit: 12,
          searchType: UndefinedTransactionSearchType.MANAGER_VERIFY,
          type: UndefinedTransactionTypeType.UNIDENTIFIED_REFUND,
          bankAccountId: null,
          ewalletAccountId: null,
        },
      },
      delete: {
        mode: 'DELETE',
        dialog: {
          toggle: false,
        },
        filter: {
          searchCharacter: '',
          limit: 12,
          searchType: UndefinedTransactionSearchType.MANAGER_DELETE,
          type: UndefinedTransactionTypeType.UNIDENTIFIED_REFUND,
          bankAccountId: null,
          ewalletAccountId: null,
        },
      },
    },
  },
};

const slice = createSlice({
  name: 'undefinedTransaction',
  initialState: initialState,
  reducers: {
    //Common
    setSelectedUndefinedTransactionId(
      state,
      action: PayloadAction<number | null>,
    ) {
      state.selectedUndefinedTransactionId = action.payload;
    },
    //---Default-----------------------
    //Set dashboardSide
    setCursor(state, action: PayloadAction<DashboardSideType>) {
      state.default.dashboardSide = action.payload;
    },
    //Set mode
    setVerify(
      state,
      action: PayloadAction<{
        mode: 'VIEW' | 'EDIT';
        dashboardSide: DashboardSideType;
      }>,
    ) {
      const { mode, dashboardSide } = action.payload;

      state.default[dashboardSide].verify.mode = mode;
    },
    setDelete(
      state,
      action: PayloadAction<{
        mode: 'VIEW' | 'EDIT';
        dashboardSide: DashboardSideType;
      }>,
    ) {
      const { dashboardSide, mode } = action.payload;

      state.default[dashboardSide].delete.mode = mode;
    },
    setReject(
      state,
      action: PayloadAction<{
        mode: 'VIEW' | 'EDIT';
        dashboardSide: DashboardSideType;
      }>,
    ) {
      const { dashboardSide, mode } = action.payload;

      state.default[dashboardSide].reject.mode = mode;
    },
    //Verify Dialog Toggle
    setVerifyDialogToggle(
      state,
      action: PayloadAction<{
        toggle: boolean;
        dashboardSide: DashboardSideType;
      }>,
    ) {
      const { toggle, dashboardSide } = action.payload;

      state.default[dashboardSide].verify.dialog.toggle = toggle;
    },
    //Set Verify Filter
    setVerifyFilter(
      state,
      action: PayloadAction<{
        filter: Partial<IVerifyUndefinedTransactionFilter>;
        dashboardSide: DashboardSideType;
      }>,
    ) {
      const { filter, dashboardSide } = action.payload;

      state.default[dashboardSide].verify.filter = {
        ...state.default[dashboardSide].verify.filter,
        ...filter,
      };
    },
    resetVerifyDialog(
      state,
      action: PayloadAction<{ dashboardSide: DashboardSideType }>,
    ) {
      const { dashboardSide } = action.payload;

      state.selectedUndefinedTransactionId = null;
      state.default[dashboardSide].verify = {
        ...state.default[dashboardSide].verify,
        dialog: initialState.default[dashboardSide].verify.dialog,
      };
    },
    //Create
    setCreateUndefinedTransactionDialogToggle(
      state,
      action: PayloadAction<{
        toggle: boolean;
        dashboardSide: DashboardSideType;
      }>,
    ) {
      const { toggle, dashboardSide } = action.payload;

      state.default[dashboardSide].create.dialog.toggle = toggle;
    },
    //---Manager-----------------------
    //Set dashboardSide
    setManagerCursor(state, action: PayloadAction<DashboardSideType>) {
      state.manager.dashboardSide = action.payload;
    },
    //Verify mode
    setVerifyManagerMode(
      state,
      action: PayloadAction<{
        mode: 'VIEW' | 'VERIFY';
        dashboardSide: DashboardSideType;
      }>,
    ) {
      const { mode, dashboardSide } = action.payload;

      state.manager[dashboardSide].verify.mode = mode;
    },
    setVerifyManagerDialogToggle(
      state,
      action: PayloadAction<{
        toggle: boolean;
        dashboardSide: DashboardSideType;
      }>,
    ) {
      const { toggle, dashboardSide } = action.payload;

      state.manager[dashboardSide].verify.dialog.toggle = toggle;
    },
    resetVerifyManagerDialog(
      state,
      action: PayloadAction<{ dashboardSide: DashboardSideType }>,
    ) {
      const { dashboardSide } = action.payload;

      state.selectedUndefinedTransactionId = null;

      state.manager[dashboardSide].verify.dialog = {
        ...initialState.manager[dashboardSide].verify.dialog,
      };
    },
    setVerifyManagerFilter(
      state,
      action: PayloadAction<{
        filter: Partial<IVerifyUndefinedTransactionManagerFilter>;
        dashboardSide: DashboardSideType;
      }>,
    ) {
      const { filter, dashboardSide } = action.payload;

      state.manager[dashboardSide].verify.filter = {
        ...state.manager[dashboardSide].verify.filter,
        ...filter,
      };
    },
    resetVerifyManagerFilter(
      state,
      action: PayloadAction<{ dashboardSide: DashboardSideType }>,
    ) {
      const { dashboardSide } = action.payload;

      state.manager[dashboardSide].verify.filter = {
        ...initialState.manager[dashboardSide].verify.filter,
      };
    },
    //For delete undefined transaction
    setDeleteManagerMode(
      state,
      action: PayloadAction<{
        mode: 'VIEW' | 'DELETE';
        dashboardSide: DashboardSideType;
      }>,
    ) {
      const { mode, dashboardSide } = action.payload;

      state.manager[dashboardSide].delete.mode = mode;
    },
    setDeleteManagerDialogToggle(
      state,
      action: PayloadAction<{
        toggle: boolean;
        dashboardSide: DashboardSideType;
      }>,
    ) {
      const { toggle, dashboardSide } = action.payload;

      state.manager[dashboardSide].delete.dialog.toggle = toggle;
    },
    resetDeleteManagerDialog(
      state,
      action: PayloadAction<{ dashboardSide: DashboardSideType }>,
    ) {
      const { dashboardSide } = action.payload;

      state.selectedUndefinedTransactionId = null;

      state.manager[dashboardSide].delete.dialog = {
        ...initialState.manager[dashboardSide].delete.dialog,
      };
    },
    setDeleteManagerFilter(
      state,
      action: PayloadAction<{
        filter: Partial<IDeleteUndefinedTransactionManagerFilter>;
        dashboardSide: DashboardSideType;
      }>,
    ) {
      const { filter, dashboardSide } = action.payload;

      state.manager[dashboardSide].delete.filter = {
        ...state.manager[dashboardSide].delete.filter,
        ...filter,
      };
    },
    resetDeleteManagerFilter(
      state,
      action: PayloadAction<{ dashboardSide: DashboardSideType }>,
    ) {
      const { dashboardSide } = action.payload;

      state.manager[dashboardSide].delete.filter = {
        ...initialState.manager[dashboardSide].delete.filter,
      };
    },
    reset(state, action: PayloadAction<DashboardSideType>) {
      state.selectedUndefinedTransactionId = null;
      state.default[action.payload] = {
        ...initialState.default[action.payload],
      };
    },
    managerReset(state, action: PayloadAction<DashboardSideType>) {
      state.selectedUndefinedTransactionId = null;
      state.manager[action.payload] = {
        ...initialState.manager[action.payload],
      };
    },
  },
});

//Reducer
export default slice.reducer;

export const {
  //Common
  setSelectedUndefinedTransactionId,

  //Default
  setCursor,
  setVerifyFilter,
  setVerifyDialogToggle,
  setCreateUndefinedTransactionDialogToggle,
  setDelete,
  setReject,
  setVerify,
  resetVerifyDialog,
  reset,
  //Manager
  resetVerifyManagerFilter,
  setManagerCursor,
  resetVerifyManagerDialog,
  setVerifyManagerDialogToggle,
  setVerifyManagerMode,
  resetDeleteManagerDialog,
  setDeleteManagerDialogToggle,
  setDeleteManagerMode,
  setVerifyManagerFilter,
  setDeleteManagerFilter,
  resetDeleteManagerFilter,
  managerReset,
} = slice.actions;
