import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IWorkShift } from 'src/@types/work-shift';
import { momentVi } from 'src/utils/date-handle';

const initialState: IWorkShift = {};

const slice = createSlice({
  name: 'workShift',
  initialState,
  reducers: {
    setMonth: (state, action: PayloadAction<{ month: number }>) => {
      const { month } = action.payload;

      state.month = month;
    },
    setYear: (state, action: PayloadAction<{ year: number }>) => {
      const { year } = action.payload;

      state.year = year;
    },
    setSelectedDate: (state, action: PayloadAction<{ date: Date }>) => {
      const { date } = action.payload;

      const dateMoment = momentVi(date);
      const month = dateMoment.month() + 1;
      const year = dateMoment.year();

      state.month = month;
      state.year = year;
      state.selectedDate = dateMoment.toDate();
    },
    setWeek: (state, action: PayloadAction<{ week: number }>) => {
      const { week } = action.payload;

      state.week = week;
    },
    setIsNextWeekRegistered: (
      state,
      action: PayloadAction<{
        isNextWeekRegistered: boolean;
      }>,
    ) => {
      const { isNextWeekRegistered } = action.payload;

      state.isNextWeekRegistered = isNextWeekRegistered;
    },
  },
});

export default slice.reducer;

export const {
  setMonth,
  setYear,
  setSelectedDate,
  setWeek,
  setIsNextWeekRegistered,
} = slice.actions;
