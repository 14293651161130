import { Icon } from '@iconify/react';
import {
  Box,
  BoxProps,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { MouseEvent, useState } from 'react';
import { IconSVG } from '../icon';
import { customIcon } from 'src/assets';
import { grey } from '@mui/material/colors';

interface NotificationMenuProps extends BoxProps {
  handleRemove: () => void;
  handlePin: () => void;
  isMark: boolean;
}

export default function NotificationMenu({
  handlePin,
  handleRemove,
  isMark,
  ...other
}: NotificationMenuProps) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box {...other}>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Icon
          color={theme.palette.common.black}
          fontSize={18}
          icon={'mingcute:more-1-fill'}
        />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleRemove();
          }}
        >
          <ListItemIcon sx={{ minWidth: 'auto !important' }}>
            <IconSVG
              css={{ width: 14, height: 20, color: grey[600] }}
              path={customIcon.closeWithBorder}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography>Gỡ thông báo</Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handlePin();
          }}
        >
          <ListItemIcon sx={{ minWidth: 'auto !important' }}>
            <IconSVG
              css={{ width: 14, height: 20, color: grey[600] }}
              path={customIcon.pin}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography>{isMark ? 'Bỏ ghim' : 'Ghim thông báo'}</Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
}
