import {
  resetWithdrawSupportRequestDialogVerification,
  setSelectedTransactionSupportRequestId,
  setWithdrawSupportRequestDialogVerificationToggle,
  setWithdrawSupportRequestVerificationMode,
} from 'src/redux/slices/transaction-support.slice';
import { setSelectedUndefinedTransactionId } from 'src/redux/slices/undefined-transaction.slice';
import { useDispatch, useSelector } from 'src/redux/store';

//----------------------------------------------------------------

//----------------------------------------------------------------

export function useWithdrawSupportVerificationDialog() {
  const toggle = useSelector(
    (state) =>
      state.transactionSupport.withdrawSupportRequestVerification.dialog.toggle,
  );
  const mode = useSelector(
    (state) => state.transactionSupport.withdrawSupportRequestVerification.mode,
  );
  const isShowByUndefinedTransaction = useSelector(
    (state) =>
      state.transactionSupport.withdrawSupportRequestVerification
        .isShowByUndefinedTransaction,
  );

  const dispatch = useDispatch();

  const openDialog = (
    transactionSupportRequestId: number,
    mode: 'VIEW' | 'EDIT',
  ) => {
    dispatch(
      setSelectedTransactionSupportRequestId(transactionSupportRequestId),
    );
    dispatch(setWithdrawSupportRequestDialogVerificationToggle(true));
    dispatch(
      setWithdrawSupportRequestVerificationMode({
        mode,
        isShowByUndefinedTransaction: false,
      }),
    );
  };

  const openByUndefinedTransactionDialog = (undefinedTransactionId: number) => {
    dispatch(setSelectedUndefinedTransactionId(undefinedTransactionId));
    dispatch(setWithdrawSupportRequestDialogVerificationToggle(true));
    dispatch(
      setWithdrawSupportRequestVerificationMode({
        mode: 'VIEW',
        isShowByUndefinedTransaction: true,
      }),
    );
  };

  const closeDialog = () => {
    dispatch(resetWithdrawSupportRequestDialogVerification());
    dispatch(setWithdrawSupportRequestDialogVerificationToggle(false));
  };

  const closeByUndefinedTransactionDialog = () => {
    dispatch(resetWithdrawSupportRequestDialogVerification());
    dispatch(setWithdrawSupportRequestDialogVerificationToggle(false));
  };

  return {
    openDialog,
    openByUndefinedTransactionDialog,
    closeDialog,
    closeByUndefinedTransactionDialog,
    toggle,
    mode,
    isShowByUndefinedTransaction,
  };
}
