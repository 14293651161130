import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  IMoneyHolderState,
  MoneyHolderTypeType,
} from 'src/@types/money-holder';

const initialState: IMoneyHolderState = {
  selectedCreditId: null,
  selectedMoneyHolderId: null,
  type: 'bank',
};

const slice = createSlice({
  name: 'moneyHolder',
  initialState,
  reducers: {
    setSelectedMoneyHolderId(
      state,
      action: PayloadAction<{ id: number | null; type: MoneyHolderTypeType }>,
    ) {
      state.selectedMoneyHolderId = action.payload.id;
      state.type = action.payload.type;
    },
    setSelectedCreditId(state, action: PayloadAction<number | null>) {
      state.selectedCreditId = action.payload;
    },
  },
});

export default slice.reducer;

export const { setSelectedMoneyHolderId, setSelectedCreditId } = slice.actions;
