import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CallConversation,
  Chat,
  ConversationsState,
  ConversationType,
  MessageType,
} from 'src/@types/conversation';

const initialState: ConversationsState = {
  selectedConversationId: null,
  conversations: [],
  maxChats: 2,
  chats: [],
};

const slice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    setSelectedConversationId(state, action: PayloadAction<number | null>) {
      state.selectedConversationId = action.payload;
    },

    addConversation(state, action: PayloadAction<ConversationType[]>) {
      state.conversations = [...state.conversations, ...action.payload];
    },

    setIsCall(
      state,
      action: PayloadAction<{
        conversationId: number;
        call: CallConversation;
      }>,
    ) {
      state.chats = [...state.chats].map((item) => {
        if (item.conversation.id === action.payload.conversationId) {
          return {
            ...item,
            call: {
              ...item.call,
              ...action.payload.call,
            },
          };
        }
        return item;
      });
    },

    addChatByKey(state, action: PayloadAction<number>) {
      for (const conversation of state.conversations) {
        if (action.payload === conversation.id) {
          state.chats = [
            ...state.chats.filter((item) => item.conversation.endTime == null),
            {
              conversation,
              isNewMsg: false,
              open: true,
              call: {
                isCall: false,
                isStart: false,
                isReceiver: false,
              },
            },
          ];
          break;
        }
      }
    },

    addChat(state, action: PayloadAction<ConversationType>) {
      const existed = state.chats.some(
        (item) => item.conversation.id === action.payload.id,
      );
      if (!existed) {
        state.chats = [
          ...state.chats.filter((item) => item.conversation.endTime == null),
          {
            conversation: action.payload,
            isNewMsg: false,
            open: false,
            call: {
              isCall: false,
              isStart: false,
              isReceiver: false,
            },
          },
        ];
      }
    },

    setMsgs(
      state,
      {
        payload: { id, msgs },
      }: PayloadAction<{
        id: number;
        msgs: MessageType[];
      }>,
    ) {
      for (let index = 0; index < state.chats.length; index++) {
        if (id === state.chats[index].conversation.id) {
          state.chats[index].conversation = {
            ...state.chats[index].conversation,
            messages: [...msgs],
          };
          break;
        }
      }
    },

    addMsg(
      state,
      {
        payload: { id, msg },
      }: PayloadAction<{
        id: number;
        msg: MessageType;
      }>,
    ) {
      for (let index = 0; index < state.chats.length; index++) {
        if (id === state.chats[index].conversation.id) {
          state.chats[index].conversation = {
            ...state.chats[index].conversation,
            messages: [...state.chats[index].conversation.messages, msg],
          };
          break;
        }
      }
    },

    insertConversation(state, action: PayloadAction<ConversationType>) {
      state.conversations = [action.payload, ...state.conversations];
    },

    removeConversation(state, action: PayloadAction<number>) {
      state.conversations = [
        ...state.conversations.filter((item) => item.id !== action.payload),
      ];
    },

    setIsNewMsg(
      state,
      {
        payload: { id, isNewMsg },
      }: PayloadAction<{
        id: number;
        isNewMsg: boolean;
      }>,
    ) {
      for (let index = 0; index < state.chats.length; index++) {
        if (id === state.chats[index].conversation.id) {
          if ((!state.chats[index].open && isNewMsg) || !isNewMsg) {
            state.chats[index].isNewMsg = isNewMsg;
          }
          break;
        }
      }
    },

    setOpenChat(
      state,
      {
        payload: { id, open },
      }: PayloadAction<{
        id: number;
        open: boolean;
      }>,
    ) {
      for (let index = 0; index < state.chats.length; index++) {
        if (id === state.chats[index].conversation.id) {
          state.chats[index].open = open;
          break;
        }
      }
    },

    updateChat(
      state,
      {
        payload: { conversation, id },
      }: PayloadAction<{ id: number; conversation: Partial<ConversationType> }>,
    ) {
      const data = [...state.chats].map((item) => {
        if (item.conversation.id === id) {
          return {
            ...item,
            conversation: {
              ...item.conversation,
              ...conversation,
            },
          };
        }
        return item;
      });
      state.chats = data;
    },

    closeAllChats(state, action) {
      state.chats = [...state.chats].map((item) => ({
        ...item,
        open: false,
      }));
    },

    deleteChat(state, { payload }: PayloadAction<Chat[]>) {
      console.log(payload);
      state.chats = payload;
    },

    updateConversation(
      state,
      {
        payload: { conversation, id },
      }: PayloadAction<{ id: number; conversation: Partial<ConversationType> }>,
    ) {
      for (let index = 0; index < state.conversations.length; index++) {
        if (id === state.conversations[index].id) {
          state.conversations[index] = {
            ...state.conversations[index],
            ...conversation,
          };
          break;
        }
      }
    },
  },
});

//Reducer
export default slice.reducer;

//Actions
export const {
  addConversation,
  updateConversation,
  addChatByKey,
  insertConversation,
  addChat,
  addMsg,
  setIsNewMsg,
  deleteChat,
  removeConversation,
  closeAllChats,
  setOpenChat,
  updateChat,
  setMsgs,
  setSelectedConversationId,
  setIsCall,
} = slice.actions;
