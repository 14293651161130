import { Theme } from '@mui/material/styles';

export default function Container(theme: Theme) {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {
          '@media (min-width: 1280px)': {
            paddingLeft: theme.spacing(5.5),
            paddingRight: theme.spacing(5.5),
          },
        },
      },
    },
  };
}
