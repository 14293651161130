import { LoadingButton } from '@mui/lab';
import { Box, Rating, Stack, Typography, useTheme } from '@mui/material';
import { User } from 'src/@types/user';
import MyAvatar from '../MyAvatar';

export interface ContactStarProps {
  user: User;
  open: boolean;
  rating: number;
  handle: () => void;
}

export default function ContactStar({
  open,
  user,
  rating,
  handle,
}: ContactStarProps) {
  const theme = useTheme();

  if (!open) return null;
  return (
    <Stack
      zIndex={10}
      bgcolor={'rgba(1,1,1,0.3)'}
      position={'absolute'}
      top={0}
      width={'100%'}
      height={'100%'}
      left={0}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Stack
        spacing={4}
        borderRadius={0.5}
        p={4}
        bgcolor={'white'}
        alignItems={'center'}
        justifyContent={'center'}
        width={'90%'}
        position={'relative'}
      >
        <Typography fontWeight={600} textAlign={'center'}>
          Người học đã kết thúc phiên chát và để lại đánh giá:
        </Typography>
        <Stack alignItems={'center'} spacing={2}>
          <Stack spacing={2} direction={'row'} alignItems={'center'}>
            <MyAvatar
              alt={user?.profile.fullName}
              src={user?.profile.avatar?.url}
              style={{ width: 30, height: 30 }}
            />
            <Typography
              color={theme.palette.primary.main}
              fontWeight={600}
              variant="subtitle2"
              noWrap
              maxWidth={250}
            >
              {user?.profile.fullName}
            </Typography>
          </Stack>
          <Rating
            name="simple-controlled"
            value={rating}
            size="large"
            readOnly
          />
        </Stack>
        <Box width={'100%'} px={5}>
          <LoadingButton
            onClick={handle}
            fullWidth
            style={{
              background: theme.palette.common.black,
              color: 'white',
              borderRadius: 100,
            }}
          >
            Đóng
          </LoadingButton>
        </Box>
      </Stack>
    </Stack>
  );
}
