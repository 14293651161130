export enum PrefixType {
  ADMIN = 'admin',
  CONSULTANT = 'consultant',
  ACCOUNTANT = 'accountant',
  USER = 'user',
  NONE = '',
}

export enum FrontendPrefixType {
  ADMIN = 'cau-hinh',
  CONSULTANT = 'tu-van',
  ACCOUNTANT = 'ke-toan',
}
