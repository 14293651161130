import { Box, IconButton, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { MotionStyle, m } from 'framer-motion';
import { ReactNode } from 'react';
import { customIcon } from 'src/assets';
import { IconSVG } from 'src/components/icon';
import useResponsive from 'src/hooks/useResponsive';

interface DrawerHeaderProps extends MotionStyle {
  open?: boolean;
  toggleDrawer: (newOpen: boolean) => any;
  children: ReactNode;
  heightEachItem?: number;
  totalItems?: number;
  title: string;
  heightDrawer?: number;
  topArrow?: number;
  rightArrow?: number;
  topDrawer?: number;
  speaker?: {
    open: boolean;
    isShow: boolean;
    setOpen: (data: boolean) => void;
  };
}

export const ArrowDrawer = ({
  right = 0,
  top = 0,
  open = false,
}: {
  right?: number;
  top?: number;
  open?: boolean;
}) => (
  <m.div
    initial={{
      borderTopLeftRadius: 3,
      borderTopRightRadius: 3,
      height: 0,
      width: 30,
      background: 'white',
      position: 'fixed',
      top: 50,
      right: 5,
    }}
    animate={{
      right,
      top,
      height: open ? 4 : 0,
    }}
  />
);

export default function DrawerHeader({
  open,
  toggleDrawer,
  children,
  totalItems = 0,
  heightEachItem = 0,
  title,
  heightDrawer,
  topArrow = 0,
  rightArrow = 0,
  speaker = {
    isShow: false,
    open: false,
    setOpen: () => null,
  },
  ...other
}: DrawerHeaderProps) {
  const theme = useTheme();
  const down500 = useResponsive('down', 500);

  return (
    <>
      {open && (
        <Box
          onClick={() => toggleDrawer(false)}
          position={'fixed'}
          top={0}
          left={0}
          width={'100%'}
          height={'100vh'}
          zIndex={10000000}
        />
      )}

      <ArrowDrawer top={topArrow} right={rightArrow} open={open} />

      {open && (
        <m.div
          style={{
            boxShadow: theme.shadows[10],
            position: 'fixed',
            top: '60px',
            right: 0,
            background: 'white',
            borderBottomLeftRadius: 5,
            overflow: 'auto',
            height: 0,
            minHeight: 60,
            zIndex: 10000001,
            ...other,
          }}
          initial={{
            width: '0',
            height: 0,
            opacity: 0,
            paddingBottom: 25,
            paddingTop: 0,
            display: 'block',
            zIndex: 10000001,
          }}
          animate={{
            opacity: open ? 1 : 0,
            maxWidth: '425px',
            width: '100%',
            height: !open
              ? '0px'
              : down500
                ? '100vh'
                : heightDrawer
                  ? `${heightDrawer}px`
                  : totalItems === 0
                    ? '85px'
                    : `calc( 97px + ${totalItems * heightEachItem}px )`,
            maxHeight: `calc( 100vh - ${down500 ? 63 : 140}px )`,
          }}
        >
          <Box bgcolor={'white'} zIndex={2} position={'sticky'} top={0}>
            <span
              style={{
                fontSize: '18px',
                fontWeight: 700,
                padding: 25,
                paddingBottom: 20,
                display: 'inline-block',

                width: 'fit-content',
                color: theme.palette.common.black,
              }}
            >
              {title}
            </span>
            {speaker.isShow && (
              <IconButton
                style={{
                  width: 40,
                  minWidth: 40,
                  height: 40,
                  top: 5,
                  right: 40,
                  position: 'absolute',
                }}
                onClick={() => speaker.setOpen(!speaker.open)}
              >
                <IconSVG
                  path={
                    speaker.open ? customIcon.volumeUp : customIcon.volumeOff
                  }
                  css={{
                    width: speaker.open ? 19 : 17,
                    minWidth: 13,
                    height: 40,
                    color: grey[600],
                  }}
                />
              </IconButton>
            )}
            {open && (
              <IconButton
                style={{
                  width: 40,
                  minWidth: 40,
                  height: 40,
                  top: 5,
                  right: 0,
                  position: 'absolute',
                }}
                onClick={() => toggleDrawer(false)}
              >
                <IconSVG
                  path={customIcon.close}
                  css={{
                    width: 13,
                    minWidth: 13,
                    height: 40,
                    color: grey[600],
                  }}
                />
              </IconButton>
            )}
          </Box>
          {children}
        </m.div>
      )}
    </>
  );
}
