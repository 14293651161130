import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps } from '@mui/material';
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
  src?: string;
}

export default function Logo({ disabledLink = false, sx, src }: Props) {
  const isDesktop = useResponsive('up', 'lg');

  // OR
  // -------------------------------------------------------
  const logo = (
    <Box
      component="img"
      src="/logo/logo_normal.svg" //=> your path
      sx={{ width: isDesktop ? '175px' : '150px', cursor: 'pointer', ...sx }}
    />
  );

  // const logo = (
  //   <Image src='/logo/logo_normal.svg'/>
  // );

  if (disabledLink || !src) {
    return <>{logo}</>;
  }

  return <RouterLink to={src}>{logo}</RouterLink>;
}
