import { Stack, StackProps, Typography, styled, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

//----------------------------------------------------------------
type Props = {
  year: number;
  isActive?: boolean;
} & StackProps;

type RootStyleProps = {
  isActive?: boolean;
} & StackProps;

//----------------------------------------------------------------
const RootStyle = styled(Stack)<RootStyleProps>(({ theme, isActive }) => ({
  minHeight: '50px',
  padding: theme.spacing(0.5, 3.5),
  cursor: 'pointer',
  ':hover': {
    backgroundColor: grey[200],
  },
  ...(isActive && {
    backgroundColor: grey[100],
  }),
}));

//----------------------------------------------------------------
export default function WorkYearCalenderItem({ year, ...other }: Props) {
  const theme = useTheme();

  return (
    <RootStyle direction={'row'} {...other}>
      <Stack width="30px" />
      <Stack>
        <Typography fontSize={16} color={theme.palette.primary.main}>
          Năm <b>{year}</b>
        </Typography>
      </Stack>
    </RootStyle>
  );
}
