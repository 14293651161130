import { DeepPartial } from 'react-hook-form';
import { MoneyHolderAccountTypeType } from './money-holder-accountant';
import { Pagination } from './pagination';
import { Transaction, TransactionTypeType } from './transaction';
import { User } from './user';

export enum TransactionReportTypeType {
  OVERPAYMENT_AMOUNT = 'Thừa tiền',
  UNDERPAYMENT_AMOUNT = 'Thiếu tiền',
}

export enum TransactionReportStatusType {
  PENDING = 'Đang chờ',
  CONFIRMED = 'Chấp nhận',
  REJECTED = 'Từ chối',
}

export enum ManagerSearchMode {
  VERIFY = 'VERIFY',
  HISTORY = 'HISTORY',
}

export enum TransactionReportFailReasonType {
  INCORRECT_IMAGE = 'Ảnh sao kê không chính xác',
  INCORRECT_AMOUNT = 'Số tiền trền sao kê bạn nhập không chính xác',
}

//Global State
export type CreateMode = 'VIEW' | 'CREATE';
export type VerifyMode = 'VIEW' | 'VERIFY' | 'HISTORY' | 'NOTI';
export type CreateDialog = {
  toggle: boolean;
};
export type VerifyDialog = {
  toggle: boolean;
};

export type TransactionReportVerifyFilter = {
  searchCharacter?: string;
  transactionType: TransactionTypeType;
  searchMode?: ManagerSearchMode;
  bankAccountId?: number | null;
  ewalletAccountId?: number | null;
};

export interface ITransactionReportState {
  selectedTransactionReportId: number | null;
  create: {
    deposit: {
      type: TransactionReportTypeType | null;
      mode: CreateMode;
      dialog: CreateDialog;
    };
    withdraw: {
      mode: CreateMode;
      type: TransactionReportTypeType | null;
      dialog: CreateDialog;
    };
  };
  verify: {
    type: TransactionReportTypeType | null;
    mode: VerifyMode;
    filter: TransactionReportVerifyFilter;
    deposit: {
      dialog: VerifyDialog;
    };
    withdraw: {
      dialog: VerifyDialog;
    };
  };
}

//Type
export type TransactionReportFailReason = {
  id: number;
  reasons: TransactionReportFailReasonType[];
  transactionReportId: number;
  transactionReport: TransactionReport;
  transactionType: 'nạp' | 'chuyển';
  type: 'thừa tiền' | 'rút tiền';
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
};

export type TransactionReport = {
  id: number;
  amount: number;
  type: TransactionReportTypeType;
  status: TransactionReportStatusType;
  description: string;
  transactionId?: number;
  transaction?: Transaction;
  parentTransactionId?: number;
  parentTransaction?: Transaction;
  createdById: number;
  createdBy: User;
  verifiedById: number;
  verifiedBy: User;
  verifiedAt: Date;
  transactionReportFailReason: TransactionReportFailReason;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
};

//Form
export type RejectVerifyFormValueProps = {
  reasons: TransactionReportFailReasonType[];
};

export type CreateTransactionReportFormValueProps = DeepPartial<
  CreateTransactionReportRequest & {
    isConfirmed: boolean;
  }
>;

export type VerifyWithdrawTransactionReportFormValueProps = {
  isConfirmed?: boolean;
  amount?: number;
};

//Service
export type CreateTransactionReportRequest = {
  parentTransactionId: number;
  type: TransactionReportTypeType;
  amount: number;
  moneyHolderAccountType: MoneyHolderAccountTypeType;
};

export type SearchTransactionReportRequest = {
  code?: string;
  month?: number;
  year?: number;
  type?: TransactionReportTypeType;
  moneyHolderAccountType?: MoneyHolderAccountTypeType;
} & Pagination;

export type ManagerSearchTransactionReportRequest = {
  searchCharacter?: string;
  transactionType?: TransactionTypeType;
  searchMode?: ManagerSearchMode;
  bankId?: number;
  ewalletId?: number;
} & Pagination;

export type FindOneTransRepReq = {
  id?: number;
  transactionCode?: string;
  status?: TransactionReportStatusType;
  transactionType?: TransactionTypeType;
};

export type ConfirmWithdrawTransactionReportReq = {
  amount?: number;
  id: number;
};

export type RejectTransRepReq = {
  id: number;
  reasons: TransactionReportFailReasonType[];
};

export type OverAndUnderCountRequest = {
  moneyHolderAccountType: MoneyHolderAccountTypeType;
};

export type OverAndUnderCountResponse = {
  over: number;
  under: number;
};
