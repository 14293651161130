import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FileData, IFileState } from 'src/@types/file';

const initialState: IFileState = {
  bucket: '',
  nameFolder: '',
  fileToUpload: null,
  form: {
    error: null,
    file: null,
    isError: false,
  },
  isLoading: false,
  isUpload: false,
};

const slice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    resetFile: (state) => {
      state.bucket = initialState.bucket;
      state.nameFolder = initialState.nameFolder;
      state.fileToUpload = initialState.fileToUpload;
      state.form = initialState.form;
      state.isLoading = initialState.isLoading;
      state.isUpload = initialState.isUpload;
    },

    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setIsUpload: (state, action: PayloadAction<boolean>) => {
      state.isUpload = action.payload;
    },

    setFileToUpload: (
      state,
      action: PayloadAction<{
        file: File | null;
        bucket?: string;
        nameFolder?: string;
      }>,
    ) => {
      state.fileToUpload = action.payload.file;
      if (action.payload.bucket) state.bucket = action.payload.bucket;
      if (action.payload.nameFolder)
        state.nameFolder = action.payload.nameFolder;
    },

    setFileForm: (state, action: PayloadAction<FileData | null>) => {
      state.form.file = action.payload;
    },

    setFileFormError: (
      state,
      action: PayloadAction<{ error?: any; isError: boolean }>,
    ) => {
      state.form = {
        ...state.form,
        ...action.payload,
      };
    },
  },
});

export default slice.reducer;

export const {
  setIsLoading,
  setIsUpload,
  setFileToUpload,
  setFileForm,
  setFileFormError,
  resetFile,
} = slice.actions;
