import { Button, Stack, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';
import { getMonth, getYear } from 'src/utils/generateDate.util';
import { DialogAnimate } from '../animate';
import WorkCalendar from './WorkCalendar';

//------------------------------------------------------------------------------
export type WorkCalendarValue = {
  year: number;
  month: number;
};

type Props = {
  open: boolean;
  onClose?: VoidFunction;
  value?: {
    year: number;
    month: number;
  };
  // onChange?: (data: { year: number; month: number }) => void;
  onClick?: (data: { year: number; month: number }) => void;
};

//------------------------------------------------------------------------------
const DialogActionsCustom = styled(Stack)(({ theme }) => ({
  backgroundColor: grey[100],
  justifyContent: 'space-between',
  padding: theme.spacing(1.5, 3),
}));

//------------------------------------------------------------------------------
export default React.memo(function WorkCalendarDialog({
  open,
  onClose,
  onClick,
  value: data,
  ...other
}: Props) {
  const [value, setValue] = useState<WorkCalendarValue>({
    month: getMonth(),
    year: getYear(),
  });

  const maxWidth = 'md';

  const handleOnClick = () => {
    if (onClick && value) onClick(value);
    if (onClose) onClose();
  };

  const handleYearClick = (year: number) => {
    setValue((prv) => ({
      ...prv,
      year,
    }));
  };

  const handleMonthClick = (month: number) => {
    setValue((prv) => ({
      ...prv,
      month,
    }));
  };

  useEffect(() => {
    if (data) setValue(data);
  }, [data]);

  return (
    <DialogAnimate open={open} onClose={onClose} maxWidth={maxWidth}>
      <WorkCalendar
        {...other}
        value={value}
        onMonthClick={handleMonthClick}
        onYearClick={handleYearClick}
      />
      <DialogActionsCustom direction={'row'}>
        <Button variant="text" color="error" onClick={onClose}>
          Hủy
        </Button>
        <Button variant="text" onClick={handleOnClick}>
          Xác nhận
        </Button>
      </DialogActionsCustom>
    </DialogAnimate>
  );
});
