import Slider, { Settings } from 'react-slick';
import Image from '../../../components/Image';
import { CarouselMinus } from '../../../components/carousel';
// @mui
import { Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import useResponsive from 'src/hooks/useResponsive';
// @types

//

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.grey[200],
}));

// ----------------------------------------------------------------------

type Props = {
  imagePaths: string[];
};

export default function AuthTutorialCarousel({ imagePaths }: Props) {
  const theme = useTheme();

  const settings: Settings = {
    speed: 2000,
    autoplaySpeed: 4000,
    dots: true,
    arrows: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === 'rtl'),
    ...CarouselMinus({ rounded: true }),
  };

  const isDesktop = useResponsive('up', 'lg');

  return (
    <RootStyle>
      <Box sx={{ width: isDesktop ? '700px' : '500px' }}>
        <Slider {...settings}>
          {imagePaths.map((image, index) => (
            <Box key={image}>
              <Image src={image} />
            </Box>
          ))}
        </Slider>
      </Box>
    </RootStyle>
  );
}
