import {
  AccountantEmploymentType,
  AccountantRoleType,
  ConsultantEmploymentType,
  ConsultantRoleType,
  UserType,
} from './user';

export enum TokenType {
  CONSULTANT_ACCESS_TOKEN = 'consultant_access_token',
  ADMIN_ACCESS_TOKEN = 'admin_access_token',
  ACCOUNTANT_ACCESS_TOKEN = 'accountant_access_token',
  CURRENT_USER_ID = 'current_user_id',
}

export type UserTypeType = UserType | null;

export type UserRoleType = ConsultantRoleType | AccountantRoleType | null;

export type EmploymentType =
  | AccountantEmploymentType
  | ConsultantEmploymentType
  | null;
