import { useQueryClient } from '@tanstack/react-query';
import { CreateMode } from 'src/@types/transaction';
import { QUERY_KEYS } from 'src/constants/queryKey';
import {
  setCreateCompanyDepositDialog,
  setSelectedTransactionId,
} from 'src/redux/slices/transaction.slice';
import { useDispatch, useSelector } from 'src/redux/store';

export function useCreateDialog() {
  const toggle = useSelector(
    (state) =>
      state.transaction.default.expense.createCompanyDeposit.dialog.toggle,
  );

  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const open = () => {
    dispatch(
      setCreateCompanyDepositDialog({
        dialog: {
          toggle: true,
        },
      }),
    );
  };

  const openWithId = (id: number, mode: CreateMode) => {
    dispatch(setSelectedTransactionId(id));
    dispatch(
      setCreateCompanyDepositDialog({
        dialog: {
          toggle: true,
          mode: mode,
        },
      }),
    );
  };

  const close = () => {
    dispatch(setSelectedTransactionId(null));
    dispatch(
      setCreateCompanyDepositDialog({
        dialog: {
          toggle: false,
        },
      }),
    );
    queryClient.resetQueries({
      queryKey: [
        QUERY_KEYS.VERIFY_PENDING_COMPANY_ACCOUNT_WITHDRAW_TRANSACTION,
      ],
    });
  };

  return { toggle, open, close, openWithId };
}
