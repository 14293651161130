import { AuthForm, NewPasswordForm } from 'src/@types/auth.consultant';
import { AUTH_API } from 'src/services/constants/auth.api.constant';
import { PrefixType } from 'src/constants/prefix';
import axios from 'src/utils/axios';
import { Accountant } from 'src/@types/user';

export const postLogin = (data: AuthForm) =>
  axios.post(`/${PrefixType.ACCOUNTANT}${AUTH_API.LOGIN}`, data);

export const postSendOtpVerifyEmail = (dto: {
  code: string;
  isCompany: boolean;
}) =>
  axios.post(`/${PrefixType.ACCOUNTANT}${AUTH_API.SEND_OTP_VERIFY_EMAIL}`, dto);

export const postSendOtpResetPasswordEmail = (code: string) =>
  axios.post(
    `/${PrefixType.ACCOUNTANT}${AUTH_API.SEND_OTP_RESET_PASSWORD_EMAIL}`,
    {
      code,
    },
  );

export const getCurrentUser = () =>
  axios.get(`/${PrefixType.ACCOUNTANT}${AUTH_API.CURRENT_USER}`);

export const postResetPassword = (data: NewPasswordForm) =>
  axios.post(`/${PrefixType.ACCOUNTANT}${AUTH_API.RESET_PASSWORD}`, data);

export type LoginResponse = {};

export type SendVerifyResponse = {
  verify_otp_token: string;
};

export const checkRole = (code: string): Promise<Accountant> =>
  axios.post(`${PrefixType.ACCOUNTANT}${AUTH_API.CHECK_ROLE}`, {
    code,
  });
