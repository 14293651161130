import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BankAccount, IBankAccountState } from 'src/@types/bank-account';

const initialState: IBankAccountState = {
  bankAccount: null,
  selectedBankAccount: null,
};

const slice = createSlice({
  name: 'bankAccount',
  initialState,
  reducers: {
    setBankAccount: (state, action: PayloadAction<BankAccount | null>) => {
      state.bankAccount = action.payload;
    },

    setSelectedBankAccount: (
      state,
      action: PayloadAction<BankAccount | null>,
    ) => {
      state.selectedBankAccount = action.payload;
    },
  },
});

export default slice.reducer;

export const { setBankAccount, setSelectedBankAccount } = slice.actions;
