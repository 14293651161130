import {
  setSelectedTransactionId,
  setVerifyWithdrawTransactionDialog,
} from 'src/redux/slices/transaction.slice';
import { useDispatch, useSelector } from 'src/redux/store';

export function useWithdrawVerificationDialog() {
  //state
  const toggle = useSelector(
    (state) =>
      state.transaction.default.expense.withdrawVerification.dialog.toggle,
  );

  //hooks
  const dispatch = useDispatch();

  const openDialog = () => {
    dispatch(setVerifyWithdrawTransactionDialog({ toggle: true }));
  };

  const setData = (transactionId: number) => {
    dispatch(setSelectedTransactionId(transactionId));
  };

  const closeDialog = () => {
    dispatch(setSelectedTransactionId(null));
    dispatch(setVerifyWithdrawTransactionDialog({ toggle: false }));
  };

  return { toggle, openDialog, setData, closeDialog };
}
