import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import bankAccountReducer from './slices/bank-account.slice';
import classApplicationInformationReducer from './slices/class-application-information.slice';
import classReducer from './slices/class.slice';
import companyMoneyHolderAccountVerificationReducer from './slices/company-money-holder-account-verification.slice';
import conversationsReducer from './slices/conversations.slice';
import cvRequestReducer from './slices/cv-request.slice';
import cvReducer from './slices/cv.slice';
import dashboardReducer from './slices/dashboard.slice';
import fileReducer from './slices/file.slice';
import identificationReducer from './slices/identification.slice';
import industryReducer from './slices/industry.slice';
import manageStaffWorkShiftReducer from './slices/manage-staff-schedule.slice';
import moneyHolderReducer from './slices/money-holder.slice';
import notificationReducer from './slices/notification.slice';
import otpTokenReducer from './slices/otp-token.slice';
import queryKeyReducer from './slices/query-key.slice';
import taskHistoryReducer from './slices/task-history.slice';
import transactionReportReducer from './slices/transaction-report.slice';
import transactionSupportReducer from './slices/transaction-support.slice';
import transactionReducer from './slices/transaction.slice';
import undefinedTransactionReducer from './slices/undefined-transaction.slice';
import userReducer from './slices/user.slice';
import walletReducer from './slices/wallet.slice';
import workShiftReducer from './slices/work-shift.slice';
//----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['notification'],
};

const rootReducer = combineReducers({
  industry: industryReducer,
  cv: cvReducer,
  cvRequest: cvRequestReducer,
  conversations: conversationsReducer,
  undefinedTransaction: undefinedTransactionReducer,
  transactionSupport: transactionSupportReducer,
  user: userReducer,
  wallet: walletReducer,
  transaction: transactionReducer,
  bankAccount: bankAccountReducer,
  identification: identificationReducer,
  file: fileReducer,
  notification: notificationReducer,
  transactionReport: transactionReportReducer,
  dashboard: dashboardReducer,
  taskHistory: taskHistoryReducer,
  class: classReducer,
  classApplicationInformation: classApplicationInformationReducer,
  workShift: workShiftReducer,
  manageStaffWorkShift: manageStaffWorkShiftReducer,
  moneyHolder: moneyHolderReducer,
  queryKey: queryKeyReducer,
  otpToken: otpTokenReducer,
  companyMoneyHolderAccountVerification:
    companyMoneyHolderAccountVerificationReducer,
});

export { rootPersistConfig, rootReducer };

