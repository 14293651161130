import {
  resetVerifyDepositManagerDialog,
  setSelectedTransactionId,
  setVerifyDepositManagerDialog,
} from 'src/redux/slices/transaction.slice';
import { useDispatch, useSelector } from 'src/redux/store';

export function useVerifyDialog() {
  //state
  const toggle = useSelector(
    (state) =>
      state.transaction.manager.expense.companyDepositVerification.dialog
        .toggle,
  );

  //hooks
  const dispatch = useDispatch();

  const openDialogById = (id: number) => {
    dispatch(setSelectedTransactionId(id));
    dispatch(
      setVerifyDepositManagerDialog({
        toggle: true,
      }),
    );
  };

  const closeDialog = () => {
    dispatch(resetVerifyDepositManagerDialog());
  };

  return { toggle, closeDialog, openDialogById };
}
