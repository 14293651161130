import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  DashboardSideType,
  IDashboardFilter,
  IDashboardState,
} from 'src/@types/dashboard';
import { TransactionMethodType } from 'src/@types/transaction';
import { momentVi } from 'src/utils/date-handle';

const initialFilter = {
  month: momentVi().month() + 1,
  year: momentVi().year(),
  bankAccountId: null,
  ewalletAccountId: null,
  searchCharacter: '',
  types: [],
  userTypes: [],
};

const initialState: IDashboardState = {
  cursor: 'expense',
  revenue: {
    method: null,
    filter: initialFilter,
  },
  expense: {
    method: null,
    filter: initialFilter,
  },
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    //Common
    setCursor: (state, action: PayloadAction<DashboardSideType>) => {
      state.cursor = action.payload;
    },

    setMethod: (
      state,
      action: PayloadAction<{
        method: TransactionMethodType | null;
        dashboardSide: DashboardSideType;
      }>,
    ) => {
      const { dashboardSide, method } = action.payload;

      state[dashboardSide].method = method;
    },

    setFilter: (
      state,
      action: PayloadAction<{
        filter: Partial<IDashboardFilter>;
        dashboardSideType: DashboardSideType;
      }>,
    ) => {
      const { dashboardSideType, filter } = action.payload;

      state[dashboardSideType].filter = {
        ...state[dashboardSideType].filter,
        ...filter,
      };
    },

    resetFilter: (
      state,
      action: PayloadAction<{ dashboardSideType: DashboardSideType }>,
    ) => {
      const { dashboardSideType } = action.payload;

      state[dashboardSideType].filter = {
        ...state[dashboardSideType].filter,
        ...initialFilter,
      };
    },
  },
});

export default slice.reducer;

export const { setMethod, setCursor, setFilter, resetFilter } = slice.actions;
