import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  FilterType,
  IManageStaffScheduleState,
  OverviewShift,
} from 'src/@types/manage-staff-schedule';
import { momentVi } from 'src/utils/date-handle';

const initialState: IManageStaffScheduleState = {
  filter: {
    employeeType: 'all',
  },
  overviewShifts: {},
  selectedDate: momentVi().toDate(),
  selectedEmployeeId: null,
};

const slice = createSlice({
  name: 'manageStaffSchedule',
  initialState,
  reducers: {
    addOrUpdateManageStaffSchedule(
      state,
      action: PayloadAction<Partial<IManageStaffScheduleState>>,
    ) {
      const { filter, overviewShifts, selectedDate, selectedEmployeeId } =
        action.payload;

      state.filter = {
        employeeType: 'all',
        ...filter,
      };
      state.overviewShifts = {
        ...state.overviewShifts,
        ...overviewShifts,
      };
      state.selectedDate = momentVi(selectedDate).toDate();
      state.selectedEmployeeId = selectedEmployeeId || null;
    },
    setSelectedDate(state, action: PayloadAction<Date>) {
      const date = action.payload;
      const dateMoment = momentVi(date);
      state.selectedDate = dateMoment.toDate();
    },
    addMoreOverviewShift(
      state,
      action: PayloadAction<{
        ownerId: number;
        overviewShifts: OverviewShift[];
      }>,
    ) {
      const { overviewShifts, ownerId } = action.payload;

      state.overviewShifts[ownerId] = overviewShifts;
    },
    resetOverviewShift(state) {
      state.overviewShifts = {};
    },
    setSelectedEmployeeId(state, action: PayloadAction<number | null>) {
      const employeeId = action.payload;

      state.selectedEmployeeId = employeeId;
    },
    setFilter(state, action: PayloadAction<FilterType>) {
      const filter = action.payload;

      state.filter = {
        ...state?.filter,
        ...filter,
      };
    },
  },
});

export default slice.reducer;

export const {
  addOrUpdateManageStaffSchedule,
  setSelectedDate,
  addMoreOverviewShift,
  resetOverviewShift,
  setSelectedEmployeeId,
  setFilter,
} = slice.actions;
