import {
  resetDepositSupportRequestVerificationForm,
  setDepositSupportRequestDialogVerificationToggle,
  setDepositSupportRequestVerificationMode,
  setIsShowDepositSupportRequestVerificationByUndefinedTransaction,
  setSelectedTransactionSupportRequestId,
} from 'src/redux/slices/transaction-support.slice';
import { setSelectedUndefinedTransactionId } from 'src/redux/slices/undefined-transaction.slice';
import { useDispatch, useSelector } from 'src/redux/store';

export function useDepositSupportRequestVerificationDialog() {
  const toggle = useSelector(
    (state) =>
      state.transactionSupport.depositSupportRequestVerification.dialog.toggle,
  );
  const mode = useSelector(
    (state) => state.transactionSupport.depositSupportRequestVerification.mode,
  );
  const isShowByUndefinedTransaction = useSelector(
    (state) =>
      state.transactionSupport.depositSupportRequestVerification
        .isShowByUndefinedTransaction,
  );
  const dispatch = useDispatch();

  const openTransactionDialog = (
    transactionSupportRequestId: number,
    mode: 'VIEW' | 'EDIT',
  ) => {
    dispatch(
      setSelectedTransactionSupportRequestId(transactionSupportRequestId),
    );
    dispatch(setDepositSupportRequestDialogVerificationToggle(true));
    dispatch(setDepositSupportRequestVerificationMode(mode));
    dispatch(resetDepositSupportRequestVerificationForm());
  };

  const openTransactionByUndefinedTransactionId = (undTranId: number) => {
    dispatch(setSelectedUndefinedTransactionId(undTranId));
    dispatch(setDepositSupportRequestDialogVerificationToggle(true));
    dispatch(setDepositSupportRequestVerificationMode('VIEW'));
    dispatch(
      setIsShowDepositSupportRequestVerificationByUndefinedTransaction(true),
    );
  };

  const closeTransactionDialog = () => {
    dispatch(setSelectedTransactionSupportRequestId(null));
    dispatch(setDepositSupportRequestDialogVerificationToggle(false));
    dispatch(resetDepositSupportRequestVerificationForm());
  };

  return {
    mode,
    toggle,
    isShowByUndefinedTransaction,
    openTransactionDialog,
    closeTransactionDialog,
    openTransactionByUndefinedTransactionId,
  };
}
