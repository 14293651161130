import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { PaginationRes } from 'src/@types/app-response';
import { TransactionReport, VerifyMode } from 'src/@types/transaction-report';
import { QUERY_KEYS } from 'src/constants/queryKey';
import {
  closeVerifyDialog,
  setSelectedTransactionReportId,
  setVerifyDialog,
  setVerifyMode,
} from 'src/redux/slices/transaction-report.slice';
import { useDispatch, useSelector } from 'src/redux/store';

export function useVerifyDialog() {
  const toggle = useSelector(
    (state) => state.transactionReport.verify.deposit.dialog.toggle,
  );

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const openWithId = (id: number, mode: VerifyMode) => {
    dispatch(setVerifyMode(mode));
    dispatch(
      setVerifyDialog({
        verifyDialog: {
          toggle: true,
        },
        verifyType: 'deposit',
      }),
    );
    dispatch(setSelectedTransactionReportId(id));
  };

  const close = () => {
    dispatch(setSelectedTransactionReportId(null));
    dispatch(
      closeVerifyDialog({
        verifyType: 'deposit',
      }),
    );
  };

  const handleUpdateTranRepoCache = (id: number) => {
    queryClient.setQueryData(
      [QUERY_KEYS.TRANSACTION_REPORTS],
      (
        infiniteData:
          | InfiniteData<PaginationRes<TransactionReport>, unknown>
          | undefined,
      ) => {
        const updatedData = {
          ...infiniteData,
          pages: infiniteData?.pages.map((page) => {
            let items: TransactionReport[] = [];

            items = page.items.filter((item) => item.id !== id);

            return {
              ...page,
              items,
            };
          }),
        };

        if (
          updatedData?.pages &&
          updatedData.pages[0].meta?.totalItems &&
          updatedData.pages[0].meta.totalItems > 0
        ) {
          updatedData.pages[0].meta.totalItems -= 1;
        }

        return updatedData;
      },
    );
  };

  return { toggle, close, openWithId, handleUpdateTranRepoCache };
}
