import { useContext } from 'react';
import { DialogContext } from 'src/contexts/DialogContext';

const useDialog = () => {
  const context = useContext(DialogContext);

  if (!context)
    throw new Error('Dialog context must be use inside DialogProvider');

  return context;
};

export default useDialog;
