import { AuthForm } from 'src/@types/auth.admin';
import { AUTH_API } from 'src/services/constants/auth.api.constant';
import { PrefixType } from 'src/constants/prefix';
import axios from 'src/utils/axios';

export const postLogin = (data: AuthForm) =>
  axios.post(`/${PrefixType.ADMIN}${AUTH_API.LOGIN}`, data);

export const postSendOtpVerifyEmail = (email: string) =>
  axios.post(`/${PrefixType.ADMIN}${AUTH_API.SEND_OTP_VERIFY_EMAIL}`, {
    email,
  });

export const getCurrentUser = () =>
  axios.get(`/${PrefixType.ADMIN}${AUTH_API.CURRENT_USER}`);

export type LoginResponse = {};

export type SendVerifyResponse = {
  verify_otp_token: string;
};
