import { Stack } from '@mui/material';
import { useState } from 'react';
import { customIcon } from 'src/assets';
import { IconSVG } from 'src/components/IconSVG';
import DrawerHeader from './DrawerHeader';

export default function NotificationPopover() {
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <>
      <Stack
        style={{
          cursor: 'pointer',
        }}
        alignItems={'center'}
        onClick={() => setOpenDrawer(true)}
        position={'relative'}
      >
        <IconSVG
          path={customIcon.notification}
          css={{ width: '25px', height: '25px', color: 'white' }}
        />
      </Stack>
      <DrawerHeader
        totalItems={0}
        heightEachItem={100}
        toggleDrawer={(newOpen) => setOpenDrawer(newOpen)}
        title="Thông báo"
        open={openDrawer}
        heightDrawer={1000}
        topArrow={openDrawer ? 67 : 80}
        rightArrow={74}
      >
        ta da
      </DrawerHeader>
    </>
  );
}
