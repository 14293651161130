import { Box, Stack, StackProps, alpha } from '@mui/material';
import { m } from 'framer-motion';
import Logo from './Logo';

type Props = StackProps & {
  size?: 'medium' | 'small' | 'large';
};

const sizeVariant = {
  large: {
    iconSize: 64,
    outBoxSize: 120,
    inBoxSize: 100,
    outBoxBorder: 8,
    inBoxBorder: 3,
  },
  medium: {
    iconSize: 50,
    outBoxSize: 100,
    inBoxSize: 80,
    outBoxBorder: 6,
    inBoxBorder: 2,
  },
  small: {
    iconSize: 40,
    outBoxSize: 75,
    inBoxSize: 65,
    outBoxBorder: 4,
    inBoxBorder: 2,
  },
};

export default function Loading({ size = 'large', ...other }: Props) {
  return (
    <Stack
      minHeight={500}
      alignItems={'center'}
      justifyContent={'center'}
      {...other}
    >
      <m.div
        animate={{
          scale: [1, 0.9, 0.9, 1, 1],
          opacity: [1, 0.48, 0.48, 1, 1],
        }}
        transition={{
          duration: 2,
          ease: 'easeInOut',
          repeatDelay: 1,
          repeat: Infinity,
        }}
      >
        <Logo
          disabledLink
          sx={{
            width: sizeVariant[size].iconSize,
            height: sizeVariant[size].iconSize,
          }}
        />
      </m.div>

      <Box
        component={m.div}
        animate={{
          scale: [1.2, 1, 1, 1.2, 1.2],
          rotate: [270, 0, 0, 270, 270],
          opacity: [0.25, 1, 1, 1, 0.25],
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
        }}
        transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
        sx={{
          width: sizeVariant[size].inBoxSize,
          height: sizeVariant[size].inBoxSize,
          borderRadius: '25%',
          position: 'absolute',
          border: (theme) =>
            `solid ${sizeVariant[size].inBoxBorder}px ${alpha(
              theme.palette.primary.dark,
              0.24,
            )}`,
        }}
      />

      <Box
        component={m.div}
        animate={{
          scale: [1, 1.2, 1.2, 1, 1],
          rotate: [0, 270, 270, 0, 0],
          opacity: [1, 0.25, 0.25, 0.25, 1],
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
        }}
        transition={{
          ease: 'linear',
          duration: 3.2,
          repeat: Infinity,
        }}
        sx={{
          width: sizeVariant[size].outBoxSize,
          height: sizeVariant[size].outBoxSize,
          borderRadius: '25%',
          position: 'absolute',
          border: (theme) =>
            `solid ${sizeVariant[size].outBoxBorder}px ${alpha(
              theme.palette.primary.dark,
              0.24,
            )}`,
        }}
      />
    </Stack>
  );
}
