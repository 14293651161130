export const AUTH_API = Object.freeze({
  LOGIN: '/auth/login',
  SEND_OTP_RESET_PASSWORD_EMAIL: '/auth/send-otp-reset-password-email',
  CURRENT_USER: '/auth/current-user',
  RESET_PASSWORD: '/auth/reset-password',
  CHECK_ROLE: '/auth/check-role',
  SIGN_IN_BY_MANAGER: '/auth/login-by-manager',
  SEND_OTP_OPTIONS_SMS: '/auth/send-otp-sms-options',
  SEND_OTP_OPTIONS_EMAIL: '/auth/send-otp-email-options',
  SEND_OTP_VERIFY_SMS:  '/auth/send-otp-verify-sms',
  SEND_OTP_VERIFY_EMAIL: '/auth/send-otp-verify-email',
  VERIFY_SMS: '/auth/verify-sms',
  VERIFY_EMAIL: '/auth/verify-email',
  UPDATE_PHONE: '/auth/update-phone',
  UPDATE_EMAIL: '/auth/update-email'
});
