import { EmploymentType, User, UserType } from 'src/@types/user';
import { PrefixType } from 'src/constants/prefix';

export function getPrefixByUserType(userType?: UserType | null) {
  if (userType === UserType.ACCOUNTANT) return PrefixType.ACCOUNTANT;
  if (userType === UserType.CONSULTANT) return PrefixType.CONSULTANT;
  if (userType === UserType.ADMIN) return PrefixType.ADMIN;

  return PrefixType.NONE;
}

export function getGStateKeyByUserType(userType?: UserType | null) {
  if (userType === UserType.ACCOUNTANT) return 'accountant';
  if (userType === UserType.CONSULTANT) return 'consultant';
}

export function getUserInfoFromUser(user?: User) {
  if (user?.accountant)
    return {
      accountType: UserType.ACCOUNTANT,
      role: user.accountant.role,
      employmentType: user.accountant
        .employmentType as unknown as EmploymentType,
      isOfficial: user.accountant.isOfficial,
    };
  if (user?.consultant)
    return {
      accountType: UserType.CONSULTANT,
      role: user.consultant.role,
      employmentType: user.consultant
        .employmentType as unknown as EmploymentType,
      isOfficial: user.consultant.isOfficial,
    };

  return null;
}
