import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  IIdentificationState,
  Identification,
} from 'src/@types/identification';

const initialState: IIdentificationState = {
  identification: null,
};

const slice = createSlice({
  name: 'identification',
  initialState,
  reducers: {
    setIdentification: (
      state,
      action: PayloadAction<Identification | null>,
    ) => {
      state.identification = action.payload;
    },
  },
});

export default slice.reducer;

export const { setIdentification } = slice.actions;
