import { yupResolver } from '@hookform/resolvers/yup';
import { Box, IconButton, Stack, StackProps, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useForm } from 'react-hook-form';
import { customIcon } from 'src/assets';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { IconSVG } from 'src/components/icon';
import * as yup from 'yup';
import ContactChatEmoji from '../../../../components/conversation/ContactChatEmoji';
import ContactUploadImg from './ContactUploadImg';

const RHFTextFieldCustom = styled(RHFTextField)(() => ({
  '& .MuiInputBase-root': {
    borderRadius: '100px',
  },
  '& input': {
    paddingRight: 40,
  },
}));

interface ContactChatFormProps extends StackProps {
  disabled?: boolean;
  handleSubmit: (data: { content: string }) => void;
  keyItem: number;
  handleUploadImg: (data: File[]) => void;
}

export default function ContactChatForm({
  disabled,
  handleSubmit,
  handleUploadImg,
  keyItem,
  ...other
}: ContactChatFormProps) {
  const defaultValues = {
    content: '',
  };

  const formMessageDefaultSchema = yup.object().shape({});

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(formMessageDefaultSchema),
  });

  const onSubmit = (data: { content: string }) => {
    if (data.content) {
      handleSubmit(data);
      methods.setValue('content', '');
    }
  };

  return (
    <Box position={'sticky'} bottom={0}>
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack
          px={2.5}
          borderTop={'1px solid'}
          borderColor={grey[200]}
          width={'100%'}
          height={70}
          direction={'row'}
          bgcolor={'white'}
          alignItems={'center'}
          justifyContent={'center'}
          spacing={1.5}
          {...other}
        >
          <Stack position={'relative'} flex={1}>
            <RHFTextFieldCustom
              disabled={disabled}
              value={methods.watch('content')}
              color={'primary'}
              size="small"
              name="content"
              placeholder="Aa ..."
            />
            <Stack position={'absolute'} direction={'row'} right={0}>
              <ContactChatEmoji
                keyItem={keyItem}
                disabled={disabled}
                handleSelectEmoji={(data) =>
                  methods.setValue(
                    'content',
                    `${methods.watch('content')} ${data}`,
                  )
                }
              />
              <ContactUploadImg handleUploadImg={handleUploadImg} />
            </Stack>
          </Stack>
          <IconButton
            disabled={!methods.watch('content') || disabled}
            color={'primary'}
            type="submit"
            style={{
              width: 40,
              height: 40,
            }}
          >
            <IconSVG
              path={customIcon.send}
              css={{
                color: !methods.watch('content') ? grey[400] : 'currentcolor',
                height: 40,
              }}
            />
          </IconButton>
        </Stack>
      </FormProvider>
    </Box>
  );
}
