import { momentVi } from './date-handle';

export function generateYear(options: {
  startYear?: number;
  endYear?: number;
  beforeYearCount?: number;
  afterYearCount?: number;
}) {
  const {
    startYear = momentVi().year(),
    endYear = startYear,
    beforeYearCount = 0,
    afterYearCount = 0,
  } = options;

  const years: number[] = [];

  // Create year list before startYear
  for (let i = startYear - beforeYearCount; i < startYear; i++) {
    years.push(i);
  }

  // Create year list form startYear to endYear
  for (let i = startYear; i <= endYear; i++) {
    years.push(i);
  }

  // Create year list after endYear
  for (let i = endYear + 1; i <= endYear + afterYearCount; i++) {
    years.push(i);
  }

  return years;
}

export function getYear(date?: string | number | Date) {
  return momentVi(date).year();
}

export function getMonth(date?: string | number | Date) {
  return momentVi(date).month() + 1;
}

export function getDate(date?: string | number | Date) {
  return momentVi(date).toDate();
}
