import { Icon } from '@iconify/react';
import {
  Box,
  BoxProps,
  Button,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { m } from 'framer-motion';
import { ReactNode } from 'react';
import { customIcon } from 'src/assets';
import { IconSVG } from 'src/components/icon';

interface ContactChatContainerProps extends BoxProps {
  children: ReactNode;
  handleClose: () => void;
  handleCloseWithMinus: () => void;
  description?: string;
  title?: string;
  showMinus?: boolean;
  open?: boolean;
  icon: string;
  isCall?: boolean;
  onCall: () => void;
}

export default function ContactChatContainer({
  children,
  description,
  handleClose,
  title,
  showMinus,
  handleCloseWithMinus,
  icon,
  open = false,
  onCall,
  isCall = false,
  ...other
}: ContactChatContainerProps) {
  const theme = useTheme();
  return (
    <m.div
      style={{
        height: '100%',
        maxHeight: 720,
        opacity: 0,
        zIndex: 11,
      }}
      initial={{
        height: '100%',
        maxHeight: 720,
        opacity: 1,
        scale: 0,
      }}
      animate={{
        scale: open ? 1 : 0,
        opacity: open ? 1 : 0,
        transformOrigin: 'center bottom',
      }}
    >
      <Stack
        position={'fixed'}
        right={other.right}
        bottom={0}
        height={'calc(100vh - 70px)'}
        justifyContent={'end'}
        top={'70px'}
      >
        <Box
          height={'100%'}
          maxHeight={720}
          maxWidth={428}
          minWidth={428}
          boxShadow={theme.shadows[7]}
          borderRadius={3}
          overflow={'hidden'}
          bgcolor={'white'}
          {...other}
        >
          <Stack
            zIndex={3}
            bgcolor={'white'}
            position={'sticky'}
            top={0}
            py={1.5}
            px={2.5}
            spacing={2}
            borderBottom={'1px solid'}
            borderColor={grey[300]}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            pr={13}
          >
            <Stack
              spacing={1.5}
              direction={'row'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Stack
                justifyContent={'center'}
                alignItems={'center'}
                borderRadius={'100%'}
                bgcolor={'white'}
                width={45}
                height={45}
                border={'1px solid'}
                borderColor={theme.palette.primary.main}
              >
                <IconSVG
                  path={icon}
                  css={{
                    color: theme.palette.primary.main,
                    width: 22,
                    height: 22,
                  }}
                />
              </Stack>
              <Stack>
                <Typography
                  fontWeight={700}
                  color={theme.palette.primary.main}
                  variant="subtitle2"
                  noWrap
                  maxWidth={130}
                >
                  {title}
                </Typography>
                <Typography
                  color={grey[400]}
                  fontWeight={500}
                  variant="subtitle2"
                >
                  {description}
                </Typography>
              </Stack>
            </Stack>

            {showMinus && (
              <Button
                disabled={isCall}
                size="small"
                onClick={onCall}
                style={{
                  color: isCall ? undefined : '#1dc346',
                  borderColor: 'currentcolor',
                  height: 34,
                  fontWeight: 500,
                  whiteSpace: 'nowrap',
                  paddingInline: '10px !important',
                  minWidth: 100
                }}
                startIcon={<Icon style={{}} icon={'mage:phone-call'} />}
                variant="outlined"
              >
                Gọi thoại
              </Button>
            )}

            {showMinus && (
              <IconButton
                style={{
                  position: 'absolute',
                  top: 6,
                  right: 50,
                  cursor: 'pointer',
                }}
                size="small"
                onClick={handleClose}
              >
                <Stack
                  minWidth={12}
                  minHeight={12}
                  justifyContent={'center'}
                  alignItems={'center'}
                  overflow={'hidden'}
                >
                  <Icon
                    color={grey[600]}
                    fontSize={25}
                    icon={'ic:round-minus'}
                  />
                </Stack>
              </IconButton>
            )}

            <IconButton
              style={{
                position: 'absolute',
                top: 6,
                right: 13,
                cursor: 'pointer',
              }}
              size="large"
              onClick={!showMinus ? handleClose : handleCloseWithMinus}
            >
              <Stack
                minWidth={12}
                minHeight={12}
                justifyContent={'center'}
                alignItems={'center'}
                overflow={'hidden'}
              >
                <Box mt={-3.2}>
                  <IconSVG
                    path={customIcon.close}
                    css={{ color: grey[600], width: 12, height: 12 }}
                  />
                </Box>
              </Stack>
            </IconButton>
          </Stack>
          {children}
        </Box>
      </Stack>
    </m.div>
  );
}
