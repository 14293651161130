import { useInfiniteQuery } from '@tanstack/react-query';
import { PaginationRes } from 'src/@types/app-response';
import { Notification } from 'src/@types/notification';
import {
  GetAllNotificationRequest,
  getCurrentUserNotifications,
} from 'src/services/common/notification.common.service';
import { NOTIFICATION_QUERY_KEYS } from './index.constants';

export default function useGetCurrentUserNotificationsQuery(
  dto: GetAllNotificationRequest,
  userId: number,
) {
  return {
    ...useInfiniteQuery({
      queryKey: [NOTIFICATION_QUERY_KEYS.CURRENT_USER_NOTIFICATIONS, userId],
      initialPageParam: {
        page: 1,
      },
      queryFn: ({ pageParam }) =>
        getCurrentUserNotifications({ ...dto, ...pageParam }),
      getNextPageParam: (lastPage: PaginationRes<Notification>) => {
        if (!lastPage) return null;
        const { currentPage, totalPages } = lastPage.meta;

        if (!totalPages) return null;
        if (currentPage >= totalPages) return null;
        return {
          page: currentPage + 1,
        };
      },
      enabled: false,
    }),
  };
}
