import { Box, Stack, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

// @ts-ignore
import moment from 'moment';

// @ts-ignore
import vi from 'moment/locale/vi';
import { Folder } from 'src/@types/folder';
import { User } from 'src/@types/user';
import MyAvatar from '../MyAvatar';
import { GET_PRIVATE_IMAGE_API } from 'src/config';

interface MessageProps {
  user?: User;
  color?: string;
  bgColor: string;
  isSender?: boolean;
  text?: string;
  time?: string | Date;
  folder?: Folder;
  handleClickImg: (url: string) => void;
  isShowAvatar?: boolean;
}

moment.locale('vi', vi);
export default function Message({
  user,
  folder,
  handleClickImg,
  color,
  bgColor,
  isSender = true,
  text,
  time,
  isShowAvatar = true,
}: MessageProps) {
  const theme = useTheme();

  return (
    <Stack
      direction={'row'}
      justifyContent={isSender ? 'end' : 'start'}
      width={'100%'}
    >
      <Stack
        mt={isShowAvatar ? 3 : 0}
        mb={!time ? 1.3 : 0}
        direction={'row'}
        width={'85%'}
      >
        <Box
          order={isSender ? 1 : 0}
          ml={isSender ? 1.8 : 0}
          mr={!isSender ? 1.8 : 0}
        >
          {isShowAvatar ? (
            <MyAvatar
              style={{ width: 45, height: 45 }}
              src={user?.profile?.avatar?.url}
              alt={user?.profile?.fullName}
            />
          ) : (
            <Box width={45} />
          )}
        </Box>
        <Box width={'100%'}>
          <Stack alignItems={isSender ? 'end' : 'start'} width={'100%'}>
            {text && (
              <Stack borderRadius={1.5} p={1} bgcolor={bgColor}>
                {isShowAvatar && !isSender && (
                  <Typography
                    color={theme.palette.primary.main}
                    variant="caption"
                    noWrap
                    maxWidth={250}
                  >
                    {user?.profile.fullName}
                  </Typography>
                )}
                <Typography
                  whiteSpace={'pre-line'}
                  display={'inline-block'}
                  color={color}
                  fontWeight={500}
                  width={'fit-content'}
                >
                  {text}
                </Typography>
              </Stack>
            )}
            {folder && folder.files.length === 1 && (
              <Stack spacing={2} width={'100%'}>
                {isShowAvatar && !isSender && (
                  <Typography
                    borderRadius={1.5}
                    p={1}
                    bgcolor={bgColor}
                    whiteSpace={'pre-line'}
                    display={'inline-block'}
                    color={theme.palette.primary.main}
                    fontWeight={500}
                    width={'fit-content'}
                    noWrap
                    maxWidth={250}
                  >
                    {user?.profile.fullName}
                  </Typography>
                )}
                <img
                  className="img-conversation"
                  onClick={() =>
                    handleClickImg(
                      folder.files[0]?.id
                        ? `${GET_PRIVATE_IMAGE_API(folder.files[0].id)}`
                        : folder.files[0]?.url
                          ? folder.files[0].url
                          : '',
                    )
                  }
                  alt={folder.files[0].name}
                  style={{
                    width: '100%',
                    objectFit: 'contain',
                    borderRadius: '6px',
                  }}
                  src={
                    folder.files[0]?.id
                      ? `${GET_PRIVATE_IMAGE_API(folder.files[0].id)}`
                      : folder.files[0].url
                  }
                />
              </Stack>
            )}
            {folder && folder.files.length > 1 && (
              <Stack spacing={3} width={'100%'}>
                {isShowAvatar && !isSender && (
                  <Typography
                    borderRadius={1.5}
                    p={2}
                    bgcolor={bgColor}
                    whiteSpace={'pre-line'}
                    display={'inline-block'}
                    color={theme.palette.primary.main}
                    fontWeight={500}
                    width={'fit-content'}
                    noWrap
                    maxWidth={250}
                  >
                    {user?.profile.fullName}
                  </Typography>
                )}

                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    width: '100%',
                    borderRadius: '6px',
                    overflow: 'hidden',
                    gridGap: '2px',
                    direction: isSender ? 'rtl' : 'ltr',
                  }}
                >
                  {folder.files.map((item, index) => (
                    <img
                      key={index}
                      className="img-conversation"
                      onClick={() =>
                        handleClickImg(
                          item?.id
                            ? `${GET_PRIVATE_IMAGE_API(item.id)}`
                            : item?.url
                              ? item.url
                              : '',
                        )
                      }
                      alt={item.name}
                      style={{
                        width: '100%',
                        objectFit: 'cover',
                        height: '90px',
                        cursor: 'pointer',
                      }}
                      src={
                        item?.id
                          ? `${GET_PRIVATE_IMAGE_API(item.id)}`
                          : item.url
                      }
                    />
                  ))}
                </div>
              </Stack>
            )}
          </Stack>
          {time && (
            <Typography
              variant="caption"
              display={'inline-block'}
              color={grey[400]}
              width={'100%'}
              textAlign={isSender ? 'end' : 'start'}
            >
              {moment(time).from(new Date()).charAt(0).toUpperCase() +
                moment(time).from(new Date()).slice(1)}
            </Typography>
          )}
        </Box>
      </Stack>
    </Stack>
  );
}
