import { DeepPartial } from 'react-hook-form';
import { BankAccount } from './bank-account';
import { EwalletAccount } from './e-wallet-account';
import { FileData } from './file';
import { TransactionSupportRequest } from './transaction-support';
import { User } from './user';
import { MoneyHolderAccountTypeType } from './money-holder-accountant';
import { DashboardSideType } from './dashboard';

//----------------------------------------------------------------
//State
export interface IVerifyUndefinedTransactionFilter
  extends UndefinedTransactionSearchRequest {}

export interface IVerifyUndefinedTransactionManagerFilter {
  searchCharacter?: string;
  limit: number;
  searchType?: UndefinedTransactionSearchType;
  type: UndefinedTransactionTypeType;
  bankAccountId: number | null;
  ewalletAccountId: number | null;
}

export interface IDeleteUndefinedTransactionManagerFilter {
  bankAccountId: number | null;
  ewalletAccountId: number | null;
  searchCharacter?: string;
  limit: number;
  searchType?: UndefinedTransactionSearchType;
  type: UndefinedTransactionTypeType;
}

export interface IUndefinedTransactionSide {
  create: {
    dialog: {
      toggle: boolean;
    };
  };
  verify: {
    mode: 'VIEW' | 'EDIT';
    dialog: {
      toggle: boolean;
    };
    filter: IVerifyUndefinedTransactionFilter;
  };
  delete: {
    mode: 'VIEW' | 'EDIT';
  };
  reject: {
    mode: 'VIEW' | 'EDIT';
  };
}

export interface IUndefinedTransactionManagerSide {
  verify: {
    mode: 'VIEW' | 'VERIFY';
    dialog: {
      toggle: boolean;
    };
    filter: IVerifyUndefinedTransactionManagerFilter;
  };
  delete: {
    mode: 'VIEW' | 'DELETE';
    dialog: {
      toggle: boolean;
    };
    filter: IDeleteUndefinedTransactionManagerFilter;
  };
}

export interface IUndefinedTransactionState {
  selectedUndefinedTransactionId: number | null;
  undefinedTransactions: UndefinedTransaction[];
  default: {
    dashboardSide: DashboardSideType;
    revenue: IUndefinedTransactionSide;
    expense: IUndefinedTransactionSide;
  };
  manager: {
    dashboardSide: DashboardSideType;
    revenue: IUndefinedTransactionManagerSide;
    expense: IUndefinedTransactionManagerSide;
  };
}

//----------------------------------------------------------------
export enum UndefinedTransactionStatusType {
  PENDING = 'Chờ',
  PENDING_DELETE_CONFIRM = 'Chờ xác nhận xóa',
  PENDING_CONFIRM = 'Chờ xác nhận',
  COMPLETED = 'Hoàn thành',
  REJECTED = 'Từ chối',
  ACCEPTED = 'Chấp nhận',
  DELETED = 'Xóa',
}

export enum UndefinedTransactionTypeType {
  UNIDENTIFIED_RECEIPT = 'Nhận tiền chưa xác định',
  UNIDENTIFIED_REFUND = 'Hoàn tiền chưa xác định',
}

export enum HistoryUndefinedTransactionSearchTypeType {
  SUPPORT = 'Trợ giúp',
  OUT_OF_BAND = 'Ngoài luồng',
}

export enum UndefinedTransactionDeleteRequestReasonType {
  SELF_ADDED_CODE = 'Tự ý thêm mã',
  RECEIVED_AT = 'Ngày & giờ nhận',
  RECEIVED_AMOUNT = 'Số tiền nhận',
  CONTENT = 'Nội dung',
  BANK_STATEMENT_IMAGE = 'Ảnh sao kê',
  BANK = 'Ngân hàng',
}

export enum UndefinedTransactionSearchType {
  VERIFY = 'verify',
  MANAGER_VERIFY = 'manager_verify',
  MANAGER_VERIFY_HISTORY = 'manager_verify_history',
  MANAGER_DELETE = 'manager_delete',
  MANAGER_DELETE_HISTORY = 'manager_delete_history',
  SUPPORT_HISTORY = 'support_history',
  OUT_OF_BAND_HISTORY = 'out_of_bands_history',
}

export enum UndefinedTransactionDeleteRequestReasonFailReasonEnum {
  INCORRECT_UNDEFINED_RECEIVER_TRANSACTION_CODE = 'Chọn nhầm mã chưa xác định',
  INCORRECT_DEDUCTION_DATE = 'Ngày và giờ trừ tiền chưa chính xác',
  INCORRECT_DEDUCTION_AMOUNT = 'Số tiền bị trừ không chính xác',
  INCORRECT_VERIFY_IMAGE = 'Ảnh sao kê không chính xác',
  INCORRECT_CONTENT = 'Nội dung trừ tiền không chính xác',
}

export enum UndefinedTransactionVerifyRequestReasonFailReasonEnum {
  INCORRECT_UNDEFINED_RECEIVER_TRANSACTION_CODE = 'Chọn nhầm mã chưa xác định',
  INCORRECT_DEDUCTION_DATE = 'Ngày và giờ trừ tiền chưa chính xác',
  INCORRECT_DEDUCTION_AMOUNT = 'Số tiền bị trừ không chính xác',
  INCORRECT_VERIFY_IMAGE = 'Ảnh sao kê không chính xác',
  INCORRECT_CONTENT = 'Nội dung trừ tiền không chính xác',
}

//----------------------------------------------------------------
export type MoneySummary = {
  month: number;
  year: number;
  total: number;
  pendingTotal: number;
  verifiedTotal: number;
  supportedTotal: number;
};

export type HistoryMoneySummary = {
  month: number;
  year: number;
  verifiedTotal: number;
  supportedTotal: number;
};

export type UndefinedTransaction = {
  id: number;
  amount: number;
  content: string;
  type: UndefinedTransactionTypeType;
  code: string;
  status: UndefinedTransactionStatusType;
  undefinedTransactionImageId: number;
  undefinedTransactionImage: FileData | null;
  receivedBankAccountId: number;
  receivedBankAccount: BankAccount | null;
  receivedEwalletAccountId: number;
  receivedEwalletAccount: EwalletAccount | null;
  receivedAt: Date;
  createdByAccountantId: number;
  createdByAccountant: User | null;
  undefinedTransactionDeleteRequest: UndefinedTransactionDeleteRequest | null;
  undefinedTransactionVerifyRequest: UndefinedTransaction | null;
  transactionSupportRequest: TransactionSupportRequest | null;
  createdAt: Date;
  updatedAt: Date;
};

export type UndefinedTransactionDeleteRequest = {
  id: number;
  reasons: UndefinedTransactionDeleteRequestReasonType[];
  undefinedTransactionId: number;
  undefinedTransaction: UndefinedTransaction | null;
  createdByAccountantId: number;
  createdByAccountant: User;
  deletedByAccountantId: number;
  deletedByAccountant: User;
  createdAt: Date;
  updatedAt: Date;
};

export type UndefinedTransactionVerifyRequest = {
  id: number;
  receivedAt: Date;
  amount: number;
  content: string;
  undefinedTransactionVerifyRequestImageId: number;
  undefinedTransactionVerifyRequestImage: DeepPartial<FileData> | null;
  undefinedTransactionId: number;
  undefinedTransaction: UndefinedTransaction | null;
  createdByAccountantId: number;
  createdByAccountant: User;
  reasons: UndefinedTransactionVerifyRequestReasonFailReasonEnum[];
  verifyById: number;
  verifyBy: User;
  createdAt: Date;
  updatedAt: Date;
};

//Form
export type CreateUndefinedTransactionFormProps = {
  receivedAt: Date;
  amount: number;
  content: string;
  undefinedTransactionImage: DeepPartial<FileData> | null;
  receivedBankAccountId?: number | null;
  receivedEwalletAccountId?: null | number;
  type: UndefinedTransactionTypeType;
};

export type DeleteUndefinedTransactionFormProps = {
  id: number | null;
  type: UndefinedTransactionTypeType | null;
  reasons: UndefinedTransactionDeleteRequestReasonType[];
};

export type CreateUndefinedTransactionRequestForm = {
  receivedAt: string;
  amount: number;
  content: string;
  undefinedTransactionVerifyRequestImage: DeepPartial<FileData> | null;
};

export type VerifyUndefinedDepositTransactionForm = {
  id: number;
  type: UndefinedTransactionTypeType | null;
  verifyRequest: CreateUndefinedTransactionRequestForm | null;
};

export type RejectVerifyUndefinedTransactionRequestForm = {
  reasons: UndefinedTransactionVerifyRequestReasonFailReasonEnum[];
};

/**
 * Request type
 *
 *
 */
export type OverviewTaskHistoryRequest = {
  month: number;
  year: number;
};

export type OverviewTaskHistoryResponse = {
  month: number;
  year: number;
  receiptUndefinedTransactionCount: number;
  refundUndefinedTransactionCount: number;
};

export type SearchTaskHistoryRequest = {
  searchCharacter?: string;
  month?: number;
  year?: number;
  type?: UndefinedTransactionTypeType;
};

export type SearchTaskHistoryResponse = {
  id: number;
  code: string;
  receivedAt: Date;
  status: UndefinedTransactionStatusType;
  description: string;
  requestCreatedAt: Date;
  type: UndefinedTransactionTypeType;
};

export type CreateUndefinedTransactionRequest = {
  receivedAt: Date | string;
  amount: number;
  content: string;
  undefinedTransactionImage: DeepPartial<FileData> | null;
  receivedBankAccountId?: number | null;
  receivedEwalletAccountId?: null | number;
  type: UndefinedTransactionTypeType;
};

export type SearchUndefinedTransactionRequest = {
  searchType: UndefinedTransactionSearchType;
  searchCharacter?: string;
  bankId?: number;
  ewalletId?: number;
  month?: number;
  year?: number;
  page?: number;
  limit?: number;
  type?: UndefinedTransactionTypeType;
};

export type SummaryUndefinedTransactionMoneyRequest = {
  month: number;
  year: number;
  type: UndefinedTransactionTypeType;
  bankAccountId?: number;
  ewalletAccountId?: number;
  moneyHolderAccountType: MoneyHolderAccountTypeType;
};

export type HistorySummaryUndefinedTransactionMoneyRequest = {
  month: number;
  year: number;
  type: UndefinedTransactionTypeType;
  bankAccountId?: number;
  ewalletAccountId?: number;
  historyType: HistoryUndefinedTransactionSearchTypeType;
};

export type FindUndefinedTransactionByCodeRequest = {
  code: string;
  status?: UndefinedTransactionStatusType;
  type?: UndefinedTransactionTypeType;
};

export type CreateUndefinedTransactionVerifyRequestRequest = {
  receivedAt: string;
  amount: number;
  content: string;
  undefinedTransactionVerifyRequestImage: DeepPartial<FileData>;
};

export type CreateVerifyUndefinedTransactionRequestRequest = {
  id: number;
  type: UndefinedTransactionTypeType;
  verifyRequest: CreateUndefinedTransactionVerifyRequestRequest;
};

export type CreateDeleteUndefinedTransactionRequestRequest = {
  id: number;
  type: UndefinedTransactionTypeType;
  reasons: UndefinedTransactionDeleteRequestReasonType[];
};

export type ConfirmVerifyUndefinedTransactionRequest = {
  id: number;
  type: UndefinedTransactionTypeType;
};

export type RejectVerifyUndefinedTransactionRequest = {
  id: number;
  type: UndefinedTransactionTypeType;

  reasons: UndefinedTransactionVerifyRequestReasonFailReasonEnum[];
};

export type ConfirmDeleteUndefinedTransactionRequest = {
  id: number;
  type: UndefinedTransactionTypeType;
};

export type RejectDeleteUndefinedTransactionRequest = {
  id: number;
  type: UndefinedTransactionTypeType;
};

export type GetAllMoneyUndefinedTransactionRequest = {
  ewalletAccountId?: number | null;
  bankAccountId?: number | null;
};

export type GetAllMoneyUndefinedTransactionResponse = {
  total: number;
};
/**
 * Hook
 *
 *
 */
export type UndefinedTransactionSearchRequest = {
  searchCharacter?: string;
  bankAccountId?: number | null;
  ewalletAccountId?: number | null;
  month?: number | null;
  year?: number | null;
  searchType?: UndefinedTransactionSearchType;
  limit?: number;
  type?: UndefinedTransactionTypeType;
};
