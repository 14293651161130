import { useMutation } from '@tanstack/react-query';
import { CallServiceHook } from 'src/@types/hook';
import { deleteNotification } from 'src/services/common/notification.common.service';

export default function useDeleteNotificationMutation({
  onError,
  onSuccess,
}: CallServiceHook) {
  return {
    ...useMutation({
      mutationFn: deleteNotification,
      onError,
      onSuccess,
    }),
  };
}
