import data from '@emoji-mart/data';

import Picker from '@emoji-mart/react';
import { Icon } from '@iconify/react';
import {
  Box,
  IconButton,
  IconButtonProps,
  Popover,
  styled,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { MouseEvent, useState } from 'react';

interface ContactChatEmojiProps extends IconButtonProps {
  handleSelectEmoji: (data: string) => void;
  keyItem: number;
}

const PopoverCustom = styled(Popover)(({ keyItem }: { keyItem: number }) => ({
  '& .MuiPaper-root': {
    marginLeft:
      keyItem === 0 ? '-10px !important' : `-${keyItem * 277}px !important`,
  },
}));

export default function ContactChatEmoji({
  handleSelectEmoji,
  keyItem,
  ...other
}: ContactChatEmojiProps) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{
          width: 40,
          height: 40,
        }}
        {...other}
      >
        <Icon
          width={'100%'}
          height={'100%'}
          icon={'fluent:emoji-48-filled'}
          color={other.disabled ? grey[400] : theme.palette.primary.main}
        />
      </IconButton>

      <PopoverCustom
        keyItem={keyItem}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: {
            background: 'transparent',
            boxShadow: 'none',
          },
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        open={open}
      >
        <Box height={'0px'} style={{ opacity: 0 }}>
          d df fd dsf ddf df ds dfsdfsdfs sdfds df d fd dff f g
        </Box>
        <Picker
          locale="vi"
          previewPosition={'none'}
          data={data}
          onEmojiSelect={(data: any) => handleSelectEmoji(data.native)}
          showPreview={false}
        />
        <Box onClick={handleClose} height={54} />
      </PopoverCustom>
    </>
  );
}
