import { Stack, StackProps, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { MessageType } from 'src/@types/conversation';
import { User } from 'src/@types/user';
import Message from 'src/components/conversation/Message';

import { SlideshowLightbox } from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css';
import { useCallback, useEffect, useState } from 'react';
import { GET_PRIVATE_IMAGE_API } from 'src/config';

interface ContactMessagesProps extends StackProps {
  receiver?: User;
  sender?: User;
  messages?: MessageType[];
  isCall?: boolean
}

export default function ContactMessages({
  sender,
  receiver,
  messages = [],
  isCall = false,
  ...other
}: ContactMessagesProps) {
  const theme = useTheme();

  const [isOpenLightBox, setIsOpenLightBox] = useState(false);
  const [images, setImages] = useState<
    (
      | {
          alt: string;
          src: string;
        }
      | undefined
    )[]
  >([]);

  const handleClickImg = (url: string) => {
    const newImages = images.filter((item) => item && item.src !== url);
    setImages([{ alt: '', src: url }, ...newImages]);
    setIsOpenLightBox(true);
  };

  useEffect(() => {
    const folders = messages
      .filter((item) => !!item.folder)
      .map((item) => item.folder);
    const imagesCurrent = folders.flatMap(
      (item) =>
        item?.files.map((file) => ({
          alt: file?.name || '',
          src: file?.id ? `${GET_PRIVATE_IMAGE_API(file.id)}` : file?.url || '',
        })),
    );
    setImages(imagesCurrent);
  }, [messages]);

  const groupMessageHandle = useCallback((arr: MessageType[]) => {
    const result = [];
    let group = [];
    let previous = arr[0];

    for (let i = 0; i < arr.length; i++) {
      const current = arr[i];
      if (current.senderId !== previous.senderId) {
        result.push(group);
        group = [];
      }
      group.push(current);
      previous = current;
    }
    result.push(group);

    return result;
  }, []);

  const messages2d = groupMessageHandle(messages);

  return (
    <Stack
      overflow={'auto'}
      p={2.5}
      py={1}
      height={`calc(100% - 62px - ${isCall ? 156: 70}px)`}
      style={{
        transition: '0.2s'
      }}
      {...other}
    >
      <SlideshowLightbox
        showAllImages={false}
        showThumbnails
        showThumbnailIcon
        onClose={() => setIsOpenLightBox(false)}
        open={isOpenLightBox}
        images={images}
      />
      {messages2d.map((items) =>
        items.map((message, key) => (
          <>
            {message.senderId === sender?.id ? (
              <Message
                key={key}
                time={key !== items.length - 1 ? undefined : message.createdAt}
                color={'white'}
                bgColor={theme.palette.primary.main}
                text={message.content}
                user={sender}
                folder={message.folder}
                handleClickImg={handleClickImg}
                isShowAvatar={key === 0}
              />
            ) : (
              <Message
                key={key}
                isSender={false}
                bgColor={grey[300]}
                text={message.content}
                user={receiver}
                time={key !== items.length - 1 ? undefined : message.createdAt}
                folder={message.folder}
                handleClickImg={handleClickImg}
                isShowAvatar={key === 0}
              />
            )}
          </>
        )),
      )}
    </Stack>
  );
}
