import { TokenType } from 'src/@types/auth';
import { UserType } from 'src/@types/user';

export type AppToken = {
  type: UserType;
  tokenKey: TokenType;
};

export const appTokenConfig: AppToken[] = [
  {
    type: UserType.ACCOUNTANT,
    tokenKey: TokenType.ACCOUNTANT_ACCESS_TOKEN,
  },
  {
    type: UserType.CONSULTANT,
    tokenKey: TokenType.CONSULTANT_ACCESS_TOKEN,
  },
  {
    type: UserType.ADMIN,
    tokenKey: TokenType.ADMIN_ACCESS_TOKEN,
  },
];

export const getTokenKeyByRole = (type: UserType) => {
  const matchedToken = appTokenConfig.find(
    (tokenConfig) => tokenConfig.type === type,
  );

  return matchedToken ? matchedToken.tokenKey : undefined;
};
