import {
  GetObjectCommand,
  PutObjectCommand,
  PutObjectCommandInput,
  S3Client,
} from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { FileType } from 'src/@types/file';
import { S3, s3Config } from 'src/config';
import { handleImageCompression } from './handle/image-handle';

// Type s3
export type S3UploadFile = {
  fileName: string;
  file: File | Blob;
  bucket: string;
  isPublic?: boolean;
  typeFile: FileType;
};

export type S3GetUrlFilePrivate = {
  fileName: string;
  bucket: string;
  expiresIn: number;
};

export type S3GetUrlFilePublic = {
  fileName: string;
  bucket: string;
  isUpload?: boolean;
};

export type S3DeleteFiles = {
  fileName: string;
  bucket: string;
};

export type S3UploadFileHandlePromise = {
  fileName: string;
  file?: File;
  bucket: string;
  isPublic?: boolean;
  typeFile: FileType;
  sizeCompression?: number;
};

// Handle upload file if public => alc = 'public-read'
export const s3UploadFile = async ({
  fileName,
  file,
  bucket,
  isPublic,
  typeFile,
}: S3UploadFile) => {
  // Create new client s3
  const client = new S3Client(s3Config);

  let contentType = 'image/jpeg';

  switch (typeFile) {
    case 'video':
      contentType = 'video/mp4';
      break;

    default:
      break;
  }

  // Pram upload file
  const params: PutObjectCommandInput = {
    Key: fileName,
    Body: file,
    Bucket: bucket,
    ContentType: contentType,
    ACL: isPublic ? 'public-read' : undefined,
  };

  // Upload file
  try {
    const command = new PutObjectCommand(params);

    return await client.send(command);
  } catch (err) {
    console.log(err);
    throw new Error('Upload file error');
  }
};

// Handle get url file with file uploader have option ACL = private_read
export const s3GetUrlFilePublic = ({
  bucket,
  fileName,
  isUpload = true,
}: S3GetUrlFilePublic) =>
  `${isUpload ? S3.endPointUrl : S3.endPointUrlShow}${bucket}/${fileName}`;

// Handle get url file private will return url have expiresIn
export const s3GetUrlFilePrivate = async ({
  bucket,
  fileName,
  expiresIn,
}: S3GetUrlFilePrivate) => {
  const client = new S3Client(s3Config);

  const command = new GetObjectCommand({
    Bucket: bucket,
    Key: fileName,
  });

  try {
    const url = await getSignedUrl(client, command, { expiresIn });

    return url;
  } catch (err) {
    throw new Error('Get url file error');
  }
};

// handleUploadFileToS3 promise
export const handleUploadFileToS3Promise = async ({
  file,
  typeFile,
  bucket,
  fileName,
  isPublic,
  sizeCompression = 200,
}: S3UploadFileHandlePromise): Promise<boolean> => {
  try {
    if (file) {
      let fileCurrent = file;
      if (typeFile === FileType.IMAGE) {
        fileCurrent = await handleImageCompression(
          fileCurrent,
          sizeCompression,
        );
      }
      await s3UploadFile({
        file: fileCurrent,
        bucket,
        fileName,
        isPublic,
        typeFile,
      });
    }
    return true;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw new Error('File upload failed');
  }
};
