import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_ACCOUNTANT, PATH_ADMIN, PATH_CONSULTANT } from '../routes/paths';
// components
import { UserType } from 'src/@types/user';
import { FrontendPrefixType } from 'src/constants/prefix';
import { useGetPrefixType } from 'src/hooks/useGetPreflixType';
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated, isInitialized, userType } = useAuth();
  const currentPrefix = useGetPrefixType();

  if (currentPrefix) {
    switch (currentPrefix) {
      //Consultant
      case FrontendPrefixType.CONSULTANT: {
        if (userType === UserType.CONSULTANT && isAuthenticated) {
          return <Navigate to={PATH_CONSULTANT.root} />;
        }
        if (!isInitialized) {
          return <LoadingScreen />;
        }

        return <>{children}</>;
      }
      //Accountant
      case FrontendPrefixType.ACCOUNTANT: {
        if (userType === UserType.ACCOUNTANT && isAuthenticated) {
          return <Navigate to={PATH_ACCOUNTANT.root} />;
        }
        if (!isInitialized) {
          return <LoadingScreen />;
        }

        return <>{children}</>;
      }
      case FrontendPrefixType.ADMIN: {
        if (userType === UserType.ADMIN && isAuthenticated)
          return <Navigate to={PATH_ADMIN.root} />;
        if (!isInitialized) {
          return <LoadingScreen />;
        }

        return <>{children}</>;
      }
    }
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
}
