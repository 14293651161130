import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IClassState, VerificationMode } from 'src/@types/class';

const initialState: IClassState = {
  selectedClassId: null,
  verification: {
    mode: 'VERIFY',
    verifyDialog: {
      toggle: false,
    },
  },
};

const slice = createSlice({
  name: 'class',
  initialState,
  reducers: {
    setSelectedClassId: (state, action: PayloadAction<number | null>) => {
      state.selectedClassId = action.payload;
    },
    setVerifyDialogToggle: (state, action: PayloadAction<boolean>) => {
      state.verification.verifyDialog.toggle = action.payload;
    },
    setVerificationMode: (state, action: PayloadAction<VerificationMode>) => {
      state.verification.mode = action.payload;
    },
    openWithClassId: (
      state,
      action: PayloadAction<{ id: number; mode: VerificationMode }>,
    ) => {
      const { id, mode } = action.payload;

      state.selectedClassId = id;
      state.verification.verifyDialog.toggle = true;
      state.verification.mode = mode;
    },
    resetVerification: (state) => {
      state.selectedClassId = null;
      state.verification = initialState.verification;
    },
  },
});

//Reducer
export default slice.reducer;

//actions
export const {
  resetVerification,
  setSelectedClassId,
  setVerificationMode,
  setVerifyDialogToggle,
  openWithClassId,
} = slice.actions;
