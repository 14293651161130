import { useEffect, useState } from 'react';

export default function useInitialRender() {
  const [initialRender, setInitialRender] = useState<boolean>(false);

  useEffect(() => {
    setInitialRender(true);
  }, []);

  return {
    initialRender,
  };
}
