import { LoadingButton } from '@mui/lab';
import { Box, Stack, StackProps, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { customIcon } from 'src/assets';
import { IconSVG } from 'src/components/icon';

interface CloseContactConfirmProps extends StackProps {
  handle: () => void;
  handleClose: () => void;
}

export default function CloseContactConfirm({
  handle,
  handleClose,
}: CloseContactConfirmProps) {
  const theme = useTheme();
  return (
    <Stack
      justifyContent={'center'}
      alignItems={'center'}
      position={'absolute'}
      top={0}
      left={0}
      bgcolor={'rgba(1,1,1,0.3)'}
      width={'100%'}
      height={'100%'}
    >
      <Stack
        spacing={4}
        borderRadius={0.5}
        p={4}
        bgcolor={'white'}
        alignItems={'center'}
        justifyContent={'center'}
        width={'90%'}
        position={'relative'}
      >
        <Typography fontWeight={600} textAlign={'center'}>
          Bạn có chắc chắn muốn đóng phiên chát này không?
        </Typography>
        <Box width={'100%'} px={5}>
          <LoadingButton
            onClick={handle}
            fullWidth
            style={{
              background: theme.palette.common.black,
              color: 'white',
              borderRadius: 100,
            }}
          >
            Có, chắc chắn
          </LoadingButton>
        </Box>
        <Box
          position={'absolute'}
          top={-25}
          right={13}
          style={{
            cursor: 'pointer',
          }}
          onClick={handleClose}
        >
          <IconSVG
            path={customIcon.close}
            css={{ color: grey[600], width: 12, height: 12 }}
          />
        </Box>
      </Stack>
    </Stack>
  );
}
