import { Box, MenuItem, Stack, Typography } from '@mui/material';
import { IconSVG } from '../IconSVG';
import React from 'react';

type Props = {
  title: string;
  onClick?: React.MouseEventHandler<HTMLLIElement>;
  icon?: string | React.ReactElement;
};

const HeaderMenuMenuItem = ({
  title,
  icon = '',
  onClick = () => {},
}: Props) => (
    <MenuItem onClick={onClick}>
      <Stack direction="row" spacing={2}>
        <Box
          sx={{
            width: 18,
            height: 18,
          }}
        >
          {typeof icon === 'string' && <IconSVG path={icon} />}
          {React.isValidElement(icon) && icon}
        </Box>
        <Typography>{title}</Typography>
      </Stack>
    </MenuItem>
  );

export default HeaderMenuMenuItem;
