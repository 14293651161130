import imageCompression from 'browser-image-compression';
import * as imageConversion from 'image-conversion';
import { CropperImage, CropperState } from 'react-advanced-cropper';

// Handle compression image
export const handleImageCompression = async (file: File, maxSize: number) => {
  let fileConvert = file;

  // Check type file and convert to jpg if file' extension is png
  if (file.type === 'image/png') {
    fileConvert = await convertPngToJpg(file);
  }

  if (fileConvert.size < maxSize) {
    return fileConvert;
  } else {
    try {
      const compressedFile = await imageCompression(fileConvert, {
        maxSizeMB: maxSize / 1024,
        useWebWorker: false,
      });

      return compressedFile;
    } catch (error) {
      throw new Error('Error compression image');
    }
  }
};

// Get canvas by cropper advanced react state and image
type GetCanvasImageCropperAdvancedReact = {
  state: CropperState;
  image: CropperImage;
};
export const getCanvasImageCropperAdvancedReact = async ({
  state,
  image,
}: GetCanvasImageCropperAdvancedReact) => {
  const originalImage = new Image();
  originalImage.src = image.src;
  let urlCanvas = '';

  // -------------------------------------------------------------------
  // Create promise to await image onload
  const imageLoadedPromise = new Promise((resolve) => {
    originalImage.onload = () => resolve(true);
  });

  // await image onload before draw canvas because if not will be return canvas empty
  await imageLoadedPromise;

  // -------------------------------------------------------------------
  // Handle rotate
  // Convert rotate to radian
  // Create canvas to draw image rotate
  const canvasOriginImageRotate = document.createElement('canvas');

  // Caculate size with and height canvas after rotate image
  const radians = (state.transforms.rotate || 0) * (Math.PI / 180);
  const absCosRadians = Math.abs(Math.cos(radians));
  const absSinRadians = Math.abs(Math.sin(radians));
  const canvasWidth =
    absCosRadians * originalImage.width + absSinRadians * originalImage.height;
  const canvasHeight =
    absCosRadians * originalImage.height + absSinRadians * originalImage.width;

  canvasOriginImageRotate.width = canvasWidth;
  canvasOriginImageRotate.height = canvasHeight;

  // Get context 2D to draw image on canvas
  const contextOrigin = canvasOriginImageRotate.getContext(
    '2d',
  ) as CanvasRenderingContext2D;

  // Move canvas to center
  contextOrigin.translate(canvasWidth / 2, canvasHeight / 2);

  // rotate convas
  contextOrigin.rotate(radians);

  // draw image rotated on canvas
  contextOrigin.drawImage(
    originalImage,
    -originalImage.width / 2,
    -originalImage.height / 2,
    originalImage.width,
    originalImage.height,
  );

  // -------------------------------------------------------------------
  // Create new canvas to draw origin image on this
  const canvas = document.createElement('canvas');
  canvas.width = state.coordinates?.width || image.width; // with canvas will be match with with crop
  canvas.height = state.coordinates?.height || image.height; // height canvas will be match height crop

  // Get context to draw image on canvas
  const context = canvas.getContext('2d') as CanvasRenderingContext2D;

  // draw image origin on canvas
  context.drawImage(
    canvasOriginImageRotate, // origin image
    state?.coordinates?.left || 0,
    state?.coordinates?.top || 0,
    canvas.width, // with of zone crop
    canvas.height, // height of zone crop
    0, // location x on canvas to draw
    0, // location y on canvas to draw
    canvas.width,
    canvas.height,
  );

  // Convert canvas to URL
  try {
    // get url
    urlCanvas = canvas.toDataURL();

    // // get file
    // fileResult = await new Promise(resolve => {
    //   canvas.toBlob(blob => {
    //     if (blob) {
    //       // create new jpg blob because when use compression function
    //       // will convert png to jpg and background will have black
    //       const jpgBlob = new Blob([blob], { type: 'image/jpeg' })

    //       // create new file jpg
    //       const fileName = `canvas_image.${jpgBlob.type}` // name of file name
    //       const file = new File([jpgBlob], fileName, { type: jpgBlob.type })

    //       resolve(file)
    //     } else {
    //       resolve(null)
    //     }
    //   })
    // })

    return urlCanvas;
  } catch (error) {
    console.log('error handle image');
    return '';
  }
};

// handle convert image png to jpg
export const convertPngToJpg = async (file: File) => {
  // convert file to jpeg
  const fileConvert = await imageConversion.compress(file, {
    quality: 0.8,
    type: imageConversion.EImageType.JPEG,
  });

  // create new file just need line code bellow will help fast compression not need code convert file to jpeg
  const jpgFile = new File(
    [fileConvert],
    file.name.replace(/\.[^.]+$/, '.jpg'),
    { type: 'image/jpeg' },
  );

  return jpgFile;
};

export const getFileCanvasImageClearExif = async (file: File) => {
  const originalImage = new Image();
  originalImage.src = URL.createObjectURL(file);
  let fileResult: File = file;

  // -------------------------------------------------------------------
  // Create promise to await image onload
  const imageLoadedPromise = new Promise((resolve) => {
    originalImage.onload = () => resolve(true);
  });

  // await image onload before draw canvas because if not will be return canvas empty
  await imageLoadedPromise;

  // -------------------------------------------------------------------
  // Create new canvas to draw origin image on this
  const canvas = document.createElement('canvas');
  canvas.width = originalImage.width; // with canvas will be match with with crop
  canvas.height = originalImage.height; // height canvas will be match height crop

  // Get context to draw image on canvas
  const context = canvas.getContext('2d') as CanvasRenderingContext2D;

  // draw image origin on canvas
  context.drawImage(
    originalImage, // origin image
    0,
    0,
    canvas.width, // with of zone crop
    canvas.height, // height of zone crop
    0, // location x on canvas to draw
    0, // location y on canvas to draw
    canvas.width,
    canvas.height,
  );

  // Convert canvas to file
  try {
    fileResult = await new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], `imageClone.${blob.type}`, {
            type: blob.type,
          });
          resolve(file);

          // Bây giờ bạn có thể sử dụng biến 'file' cho mục đích của mình
        } else {
          resolve(file);
        }
      });
    });

    return fileResult;
  } catch (error) {
    console.log('error handle image');
    return file;
  }
};
