import { useMutation } from '@tanstack/react-query';
import { CallServiceHook } from 'src/@types/hook';
import { markNotification } from 'src/services/common/notification.common.service';

export default function useMarkNotificationMutation({
  onError,
  onSuccess,
}: CallServiceHook) {
  return {
    ...useMutation({
      mutationFn: markNotification,
      onError,
      onSuccess,
    }),
  };
}
