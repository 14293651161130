import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IWalletState, Wallet } from 'src/@types/wallet';

const initialState: IWalletState = {
  wallet: null,
  moneySourceStatisticSum: 0,
};

const slice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWallet: (state, action: PayloadAction<Wallet | null>) => {
      state.wallet = action.payload;
    },

    setMoneySourceStatisticSum: (state, action: PayloadAction<number>) => {
      state.moneySourceStatisticSum = action.payload;
    },
  },
});

export default slice.reducer;

export const { setWallet, setMoneySourceStatisticSum } = slice.actions;
