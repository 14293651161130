import { memo } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

function ShieldActiveIcon({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.99291 0.549927C8.99291 0.549927 10.3938 0.837836 13.4322 1.63525C16.3038 2.38899 17.3092 2.68984 17.3092 2.68984C17.3028 2.70925 18.3034 13.9604 8.99291 19.3821C-0.914793 13.9604 0.695725 2.74807 0.675078 2.68984C0.675078 2.68984 1.68205 2.38899 4.55207 1.63525C7.59205 0.837836 8.99291 0.549927 8.99291 0.549927Z"
          fill="#4A7F00"
        />
        <path
          d="M8.99291 0.549927C8.99291 0.549927 10.3938 0.837836 13.4322 1.63525C16.3038 2.38899 17.3092 2.68984 17.3092 2.68984C17.3028 2.70925 18.3034 13.9604 8.99291 19.3821C-0.914793 13.9604 0.695725 2.74807 0.675078 2.68984C0.675078 2.68984 1.68205 2.38899 4.55207 1.63525C7.59205 0.837836 8.99291 0.549927 8.99291 0.549927Z"
          fill="#0D9912"
        />
        <path
          d="M8.99612 2.02021C9.53773 2.1399 10.8766 2.44883 13.0828 3.02789C14.3963 3.37241 15.3175 3.6215 15.9274 3.79133C15.902 6.49251 15.2142 13.6272 8.9723 17.7161C5.76079 15.746 3.62455 12.8637 2.61758 9.13707C2.03468 6.97775 1.97433 5.01899 2.0188 3.80266C2.6287 3.63444 3.56261 3.3805 4.90789 3.02789C7.1156 2.44883 8.45452 2.14152 8.99612 2.02021ZM8.99612 0.549927C8.99612 0.549927 7.59526 0.837836 4.55688 1.63525C1.68526 2.38899 0.67988 2.68984 0.67988 2.68984C0.698939 2.74807 -0.911579 13.9604 8.99612 19.3821C18.3066 13.9604 17.306 2.70925 17.3124 2.68984C17.3124 2.68984 16.3054 2.38899 13.4354 1.63525C10.397 0.837836 8.99612 0.549927 8.99612 0.549927Z"
          fill="#D9D9D9"
        />
        <path
          d="M9.00715 20L8.74826 19.8577C-0.473308 14.8112 -0.050825 4.82329 0.144534 2.75778L0 2.33239L0.532074 2.17226C0.57337 2.15932 1.58034 1.86009 4.42654 1.11282C7.44428 0.320259 8.83561 0.0339668 8.89438 0.0210271L8.99921 0L9.10403 0.0210271C9.16121 0.0323494 10.5525 0.320259 13.5719 1.11282C16.4181 1.86009 17.425 2.16094 17.4663 2.17226L18 2.33239L17.8523 2.76587C17.9365 4.30732 18.1572 14.6721 9.26286 19.8512L9.00715 20ZM1.17851 3.10716C1.00062 5.46704 0.937087 14.1723 8.99126 18.7626C16.6785 14.0962 16.8739 5.20663 16.8056 3.1023C16.2958 2.95835 15.2094 2.65588 13.3066 2.15609C10.7765 1.49292 9.37563 1.18237 8.99921 1.1015C8.62278 1.18237 7.22192 1.49454 4.69179 2.1577C2.76996 2.66235 1.6804 2.96482 1.17851 3.10716Z"
          fill="white"
        />
        <path
          d="M14.2244 6.74169L12.8092 5.30053L7.59012 10.6171L5.57141 8.56135L4.15625 10.0025L6.17337 12.0583L7.59012 13.4995L9.00528 12.0583L14.2244 6.74169Z"
          fill="white"
        />
      </svg>
    </Box>
  );
}

export default memo(ShieldActiveIcon);
