import { PaginationRes } from 'src/@types/app-response';
import { Notification } from 'src/@types/notification';
import { NOTIFICATION_HOST_API } from 'src/config';
import axiosInstance from 'src/utils/axios';

export type GetAllNotificationRequest = {
  page?: number;
  limit?: number;
  dateGetPage?: string | Date;
};

const NOTIFICATION_APIS = {
  ROOT: 'notification',
  MARK: (id: string) => `notification/${id}/mark`,
  READ: (id: string) => `notification/${id}/read`,
  DELETE: (id: string) => `notification/${id}/delete`,
  CURRENT_USER: 'notification/current-user',
};

export const getCurrentUserNotifications = ({
  page = 1,
  limit = 10,
  dateGetPage,
}: GetAllNotificationRequest): Promise<PaginationRes<Notification>> =>
  axiosInstance.get(`/${NOTIFICATION_APIS.CURRENT_USER}`, {
    params: {
      page,
      limit,
      dateGetPage,
      app: 'recruitment_app',
    },
    baseURL: NOTIFICATION_HOST_API,
  });

export const markNotification = (id: string): Promise<Notification> =>
  axiosInstance.put(
    `/${NOTIFICATION_APIS.MARK(id)}`,
    {},
    {
      baseURL: NOTIFICATION_HOST_API,
    },
  );

export const readNotification = (id: string): Promise<Notification> =>
  axiosInstance.put(
    `/${NOTIFICATION_APIS.READ(id)}`,
    {},
    {
      baseURL: NOTIFICATION_HOST_API,
    },
  );

export const deleteNotification = (id: string): Promise<Notification> =>
  axiosInstance.delete(`/${NOTIFICATION_APIS.DELETE(id)}`, {
    baseURL: NOTIFICATION_HOST_API,
  });
