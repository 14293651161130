import { skipToken, useQuery } from '@tanstack/react-query';
import { ErrorRes } from 'src/@types/app-response';
import { WorkShift } from 'src/@types/work-shift';
import { PrefixType } from 'src/constants/prefix';
import { QUERY_KEYS } from 'src/constants/queryKey';
import { checkInTimeWork } from 'src/services/common/work-shift.service';

export function useCheckInTimeWork(prefixType: PrefixType) {
  return {
    ...useQuery<WorkShift, ErrorRes>({
      queryFn: prefixType ? () => checkInTimeWork(prefixType) : skipToken,
      queryKey: [QUERY_KEYS.WORK_SHIFT, QUERY_KEYS.CHECK_IN_TIME_WORK],
    }),
  };
}
