import { resetFile } from 'src/redux/slices/file.slice';
import {
  resetVerifyDialog,
  setSelectedUndefinedTransactionId,
  setVerifyDialogToggle,
} from 'src/redux/slices/undefined-transaction.slice';
import { useDispatch, useSelector } from 'src/redux/store';

export function useVerifyUndefinedTransactionDialog() {
  //state
  const dashboardSide = useSelector(
    (state) => state.undefinedTransaction.default.dashboardSide,
  );
  const toggle = useSelector(
    (state) =>
      state.undefinedTransaction.default[dashboardSide].verify.dialog.toggle,
  );

  //hooks
  const dispatch = useDispatch();

  const openDialog = (id: number) => {
    dispatch(setSelectedUndefinedTransactionId(id));
    dispatch(
      setVerifyDialogToggle({ toggle: true, dashboardSide: dashboardSide }),
    );
    dispatch(resetFile());
  };

  const closeDialog = () => {
    dispatch(resetVerifyDialog({ dashboardSide: dashboardSide }));
    dispatch(resetFile());
  };

  return { toggle, openDialog, closeDialog };
}
