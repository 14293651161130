// routes
import { PATH_CONSULTANT } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import { ConsultantRoleType } from 'src/@types/user';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  servicesPlumber: getIcon('services-plumber-icon'),
  archive: getIcon('archive-icon'),
  archiveLine: getIcon('archive-line-icon'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  book: getIcon('book-icon'),
  globe: getIcon('globe-icon'),
  publisher: getIcon('publisher-icon'),
  description: getIcon('description-icon'),
  pen: getIcon('pen-icon'),
  admin: getIcon('admin-icon'),
  termPolicy: getIcon('term-policy-icon'),
  customGraduationHat: getIcon('custom-graduation-hat'),
  customFileTick: getIcon('custom-file-tick'),
  checkClass: getIcon('check_class_icon'),
  bookTool: getIcon('book-tool-icon'),
  penTool1: getIcon('pen-tool-icon-1'),
};

// STAFF
// ----------------------------------------------------------------------
const navConfig = [
  // STUDENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Người học',
    icon: ICONS.penTool1,
    items: [
      // Industry
      {
        title: 'Xét duyệt lớp',
        path: PATH_CONSULTANT.checkClass,
        icon: ICONS.checkClass,
      },
    ],
    roles: [ConsultantRoleType.STAFF],
  },
  // TEACHER
  // ----------------------------------------------------------------------
  {
    subheader: 'Người dạy',
    icon: ICONS.bookTool,
    items: [
      // Industry
      {
        title: 'Kiểm duyệt CV',
        path: PATH_CONSULTANT.checkCv,
        icon: ICONS.customFileTick,
      },
    ],
    roles: [ConsultantRoleType.STAFF],
  },
];

export default navConfig;
