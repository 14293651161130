// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  moreError?: string;
  endAdornmentLabel?: string;
  isShowClose?: boolean;
  subLabel?: string;
  isNumber?: boolean;
  isShowError?: boolean;
};

type Props = IProps & TextFieldProps;

export default function RHFTextField({
  endAdornmentLabel,
  name,
  moreError,
  isShowClose,
  subLabel,
  isNumber,
  isShowError = true,
  ...other
}: Props) {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Stack alignItems="flex-start">
          <TextField
            {...field}
            fullWidth
            value={
              typeof field.value === 'number' && field.value === 0
                ? ''
                : field.value
            }
            // for number
            {...(isNumber && {
              inputMode: 'numeric',
              onInput: (event: any) => {
                const inputValue = event.target.value
                  .slice(0, 12)
                  .replace(/[^0-9]/g, '');
                const numericValue = parseInt(inputValue, 10);
                event.target.value = numericValue || 0;
              },
            })}
            error={!!error}
            helperText={isShowError ? error?.message : undefined}
            InputProps={{
              endAdornment: (
                <Stack direction={'row'} alignItems={'center'}>
                  {endAdornmentLabel && (
                    <Typography color={grey[500]}>
                      {endAdornmentLabel}
                    </Typography>
                  )}
                  <InputAdornment
                    position="end"
                    sx={{
                      padding: 0,
                    }}
                  >
                    {subLabel && (
                      <Typography variant="caption" color={'#B6B6B6'}>
                        {subLabel}
                      </Typography>
                    )}

                    {isShowClose && (
                      <IconButton
                        aria-label="clear"
                        onClick={() => setValue(name, '')}
                        disabled={other.disabled}
                        size={other.size}
                      >
                        <Iconify
                          icon={'ant-design:close'}
                          fontSize={other.size === 'small' ? 12 : 16}
                        />
                      </IconButton>
                    )}
                  </InputAdornment>
                </Stack>
              ),
            }}
            {...other}
          />
          {moreError && isShowError && (
            <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
              {moreError}
            </FormHelperText>
          )}
        </Stack>
      )}
    />
  );
}
