// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  Grid,
  Stack,
  Typography,
  TypographyProps,
  styled,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------
type CustomCheckboxProps = {} & CheckboxProps;

const CustomCheckbox = styled(Checkbox)<CustomCheckboxProps>(({ theme }) => ({
  '&.checkboxCustom-error': {
    color: theme.palette.error.main,
  },
}));
// ----------------------------------------------------------------------

interface RHFCheckboxProps extends Omit<FormControlLabelProps, 'control'> {
  name: string;
  checkboxProps?: CheckboxProps;
}

const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== 'error',
})<{ error?: boolean }>`
  ${(props) =>
    props.error &&
    `
    color: ${props.theme.palette.error.main};
    & .MuiTypography-root {
      color: ${props.theme.palette.error.main};
    }
  `}
`;

export function RHFCheckbox({
  name,
  checkboxProps,
  label,
  ...other
}: RHFCheckboxProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <StyledFormControlLabel
          error={!!error}
          control={
            <CustomCheckbox
              {...field}
              className={error ? 'checkboxCustom-error' : undefined}
              checked={field.value}
              {...checkboxProps}
            />
          }
          label={label}
          {...other}
        />
      )}
    />
  );
}

// ----------------------------------------------------------------------

type FormControlLabelStyleProps = FormControlLabelProps & {
  checkedcolor?: string;
  selectedcolor?: string;
  disablecolor?: string;
  labelcolor?: string;
  checkboxcolor?: string;
};

const FormControlLabelStyle = styled(
  FormControlLabel,
)<FormControlLabelStyleProps>(
  ({
    theme,
    checkedcolor = theme.palette.primary.light,
    selectedcolor = theme.palette.primary.darker,
    disablecolor = theme.palette.grey[500],
    color = theme.palette.text.primary,
    checkboxcolor,
    labelcolor,
    disabled,
  }) => ({
    ...(checkboxcolor
      ? {
          color: color,
          '> .Custom-checkbox': {
            color: checkboxcolor || color,
          },
        }
      : {
          color: color,
        }),
    '.Mui-disabled': {
      color: disablecolor,
    },
    ...(checkedcolor && {
      '.Mui-checked': {
        color: `${checkedcolor}`,
      },
    }),
    '&.Custom-selected': {
      color: selectedcolor,
      '.Mui-disabled,  > .Custom-checkbox': {
        color: `${selectedcolor} `,
        cursor: 'pointer',
      },
      '.Custom-checkbox-label': {
        cursor: 'pointer',
      },
    },
  }),
);

export type MultiCheckBoxOptions = {
  label: string;
  value: any;
};

export enum MultiCheckBoxErrorType {
  EMPTY = 'empty',
}

export type MultiCheckBoxError = {
  value: any;
  message: string;
  type: MultiCheckBoxErrorType;
};

export interface RHFMultiCheckboxProps
  extends Omit<FormControlLabelProps, 'control' | 'label'> {
  name: string;
  options: MultiCheckBoxOptions[];
  columnNumber?: 1 | 2 | 3 | 4 | 5 | 6;
  icon?: React.ReactNode;
  checkedIcon?: React.ReactNode;
  checkedColor?: string;
  selectedColor?: string;
  disableColor?: string;
  isSelectedMode?: boolean;
  labelColor?: string;
  checkBoxColor?: string;
  layout?: 'grid' | 'stack';
  value?: any;
  onSelectedChange?: (value: any) => void;
  labelStyle?: TypographyProps;
}

export function RHFMultiCheckbox({
  name,
  options,
  columnNumber = 4,
  icon,
  checkedIcon,
  value = null,
  isSelectedMode = false,
  layout = 'grid',
  onSelectedChange,
  labelStyle,
  labelColor,
  checkedColor,
  ...other
}: RHFMultiCheckboxProps) {
  const { control } = useFormContext();
  const [selectedValue, setSelectedValue] = useState<any>(value);

  const theme = useTheme();

  useEffect(() => {
    if (onSelectedChange) {
      onSelectedChange(selectedValue);
    }
  }, [selectedValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const onSelected = (option: string) =>
          field.value?.includes(option)
            ? field.value.filter((value: string) => value !== option)
            : [...field.value, option];

        const handleOnclick = (value: any) => {
          if (isSelectedMode) {
            if (field.value?.includes(value)) {
              if (value !== selectedValue) {
                setSelectedValue(value);
                return;
              } else {
                setSelectedValue(null);
                return field.onChange(onSelected(value));
              }
            } else {
              setSelectedValue(value);
              return field.onChange(onSelected(value));
            }
          }
          return field.onChange(onSelected(value));
        };

        const handleDisableOnclick = (value: any) => {
          if (other.disabled) {
            if (field.value?.includes(value)) {
              if (value !== selectedValue) {
                return setSelectedValue(value);
              } else {
                return setSelectedValue(null);
              }
            }
          }
        };

        const isError = error ? true : false;

        if (layout === 'stack') {
          return (
            <Stack direction={'row'}>
              {options.map((option) => {
                const className = () => {
                  if (isSelectedMode && option.value === selectedValue)
                    return 'Custom-selected';
                  if (field.value?.includes(option.value))
                    return 'Custom-checked';
                  return undefined;
                };

                const labelClassName = other.disabled
                  ? 'Mui-disabled'
                  : undefined;

                return (
                  <Stack
                    direction="row"
                    alignItems="justifyContent"
                    key={option.value}
                    onClick={() => handleDisableOnclick(option.value)}
                  >
                    <FormControlLabelStyle
                      control={
                        <Checkbox
                          className="Custom-checkbox"
                          checkedIcon={checkedIcon}
                          checked={field.value?.includes(option.value)}
                          onChange={() => handleOnclick(option.value)}
                          sx={{
                            ...(isError && {
                              color: theme.palette.error.main,
                            }),
                          }}
                        />
                      }
                      className={className()}
                      label={
                        <Typography
                          {...labelStyle}
                          display="inline-block"
                          className={labelClassName}
                        >
                          {option.label}
                        </Typography>
                      }
                      labelcolor={labelColor}
                      checkedcolor={checkedColor}
                      {...other}
                    />
                  </Stack>
                );
              })}
            </Stack>
          );
        }

        return (
          <Grid container>
            {options.map((option) => {
              const className = () => {
                if (isSelectedMode && option.value === selectedValue)
                  return 'Custom-selected';
                if (field.value?.includes(option.value))
                  return 'Custom-checked';
                return undefined;
              };

              const labelClassName = other.disabled
                ? 'Mui-disabled'
                : undefined;

              return (
                <Grid item key={option.value} xs={12 / columnNumber}>
                  <Stack
                    direction="row"
                    alignItems="justifyContent"
                    onClick={() => handleDisableOnclick(option.value)}
                  >
                    <FormControlLabelStyle
                      control={
                        <Checkbox
                          className="Custom-checkbox"
                          checkedIcon={checkedIcon}
                          checked={field.value?.includes(option.value)}
                          onChange={() => handleOnclick(option.value)}
                          sx={{
                            ...(isError && {
                              color: theme.palette.error.main,
                            }),
                          }}
                        />
                      }
                      className={className()}
                      label={
                        <Typography
                          {...labelStyle}
                          display="inline-block"
                          className={labelClassName}
                        >
                          {option.label}
                        </Typography>
                      }
                      {...other}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        );
      }}
    />
  );
}

//---------------------------------------------------------------------
//Custom checkbox return value

type CheckboxFieldProps = {
  name: string;
  label?: string;
  value: string | number;
  moreError?: string;
  checkboxProps?: CheckboxProps;
  isShowErrorMessage?: boolean;
  onClick?: VoidFunction;
  endAdornment?: React.ReactNode;
  disabled?: boolean;
};

export default function RHFCheckboxReturnValue({
  name,
  label,
  value = '',
  moreError,
  checkboxProps,
  isShowErrorMessage,
  onClick,
  endAdornment,
  ...other
}: CheckboxFieldProps) {
  const { control } = useFormContext();
  const theme = useTheme();
  const isError = moreError ? true : false;

  const handleOnclick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Stack spacing={1}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  checked={field.value === value}
                  onChange={(e) => {
                    field.onChange(e.target.checked ? value : undefined);
                  }}
                  onClick={handleOnclick}
                  style={{
                    ...(isError && {
                      color: theme.palette.error.main,
                    }),
                  }}
                  {...checkboxProps}
                  {...other}
                />
              }
              label={label}
              style={{
                ...(isError && {
                  color: theme.palette.error.main,
                }),
              }}
            />
            {endAdornment && endAdornment}
          </Stack>
          {(!!error || moreError) && isShowErrorMessage && (
            <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
              {error?.message || moreError}
            </FormHelperText>
          )}
        </Stack>
      )}
    />
  );
}
