import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OtpKeyType, OTPType } from 'src/@types/otp';

export interface OtpToken {
  token: string;
  endTime: Date;
  key: OtpKeyType;
  phoneOrEmail?: string;
  code?: string;
  type: OTPType;
}

interface OtpState {
  otpTokens: OtpToken[];
}

const initialState: OtpState = {
  otpTokens: [],
};

export const getOtpTokenData = ({
  otpTokens,
  type,
  phone,
  email,
  code,
  isCheckDate = true,
}: {
  otpTokens: OtpToken[];
  phone?: string;
  email?: string;
  code?: string;
  type: OtpKeyType;
  isCheckDate?: boolean;
}) => {
  return otpTokens.find((item) => {
    const isExisted = isCheckDate
      ? item.key === type &&
        new Date(item.endTime).getTime() > new Date().getTime()
      : item.key === type;
    if (isExisted) {
      if (email) {
        return email === item.phoneOrEmail;
      }
      if (phone) {
        return phone === item.phoneOrEmail;
      }
      if (code) {
        return code === item.code;
      }

      return true;
    }
    return false;
  });
};

const slice = createSlice({
  name: 'otpTokens',
  initialState,
  reducers: {
    addOrUpdateOtpToken(state, action: PayloadAction<OtpToken>) {
      const index = state.otpTokens.findIndex(
        (item) => item.key === action.payload.key,
      );
      if (index !== -1) {
        state.otpTokens[index] = action.payload;
      } else {
        state.otpTokens = [...state.otpTokens, action.payload];
      }
    },
    removeOtpToken(state, action: PayloadAction<OtpKeyType>) {
      state.otpTokens = [
        ...(state.otpTokens.filter((item) => item.key !== action.payload) ||
          []),
      ];
    },
  },
});

//Reducer
export default slice.reducer;

//Action
export const { addOrUpdateOtpToken, removeOtpToken } = slice.actions;
