import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ITaskHistoryState } from 'src/@types/task-history';
import { TASK_HISTORY_TABS_TITLE as CONSULTANT_TASK_HISTORY_TABS_TITLE } from 'src/pages/consultant/dashboard/TaskHistory';
import { TASK_HISTORY_TABS_TITLE as ACCOUNTANT_TASK_HISTORY_TABS_TITLE } from 'src/pages/accountant/dashboard/TaskHistory';

const initialState: ITaskHistoryState = {
  consultant: {
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    currentTab: CONSULTANT_TASK_HISTORY_TABS_TITLE.conversation,
  },
  accountant: {
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    currentTab: ACCOUNTANT_TASK_HISTORY_TABS_TITLE.depositRequest,
  },
};

type UserType = 'accountant' | 'consultant';

const slice = createSlice({
  name: 'taskHistory',
  initialState,
  reducers: {
    setMonth: (
      state,
      action: PayloadAction<{ month: number; userType: UserType }>,
    ) => {
      const { month, userType } = action.payload;

      state[userType].month = month;
    },
    setYear: (
      state,
      action: PayloadAction<{ year: number; userType: UserType }>,
    ) => {
      const { year, userType } = action.payload;

      state[userType].year = year;
    },
    setCurrentTab: (
      state,
      action: PayloadAction<{ currentTab: string; userType: UserType }>,
    ) => {
      const { currentTab, userType } = action.payload;

      state[userType].currentTab = currentTab;
    },
  },
});

export default slice.reducer;

export const { setMonth, setYear, setCurrentTab } = slice.actions;
