import { AvatarProps, Box, Avatar as MUIAvatar, styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { forwardRef } from 'react';

// ----------------------------------------------------------------------

type AvatarColor =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error';
// ----------------------------------------------------------------------
const IsOnlineCustom = styled(Box)(({ theme }) => ({
  width: 19,
  height: 19,
  backgroundColor: theme.palette.success.main,
  position: 'absolute',
  top: -1,
  right: -1,
  borderRadius: '50%',
  border: '2px solid white',
}));

const BoxAvatarCustom = styled(Box)(({ theme }) => ({
  position: 'relative',
}));
//----------------------------------------------------------------
export interface Props extends AvatarProps {
  color?: AvatarColor;
  isOnline?: boolean;
}

const Avatar = forwardRef<HTMLDivElement, Props>(
  ({ color = 'default', children, sx, isOnline, ...other }, ref) => {
    const theme = useTheme();

    if (color === 'default') {
      return (
        <BoxAvatarCustom>
          <MUIAvatar
            ref={ref}
            sx={{
              ...sx,
            }}
            {...other}
          >
            {children}
          </MUIAvatar>
          {isOnline && <IsOnlineCustom />}
        </BoxAvatarCustom>
      );
    }

    return (
      <BoxAvatarCustom>
        <MUIAvatar
          ref={ref}
          sx={{
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette[color].contrastText,
            backgroundColor: theme.palette[color].main,
            ...sx,
          }}
          {...other}
        >
          {children}
        </MUIAvatar>
        {isOnline && <IsOnlineCustom />}
      </BoxAvatarCustom>
    );
  },
);

export default Avatar;
