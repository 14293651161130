import { Menu, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router';
import { HeaderMenuItemProps } from './HeaderMenuItem';
import HeaderMenuMenuItem from './HeaderMenuMenuItem';

const CustomMenu = styled(Menu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    boxShadow: theme.shadows[8],
    border: `1px solid ${grey[300]}`,
    padding: theme.spacing(0, 0.5),
  },
  '& .MuiMenuItem-root': {
    margin: theme.spacing(0.3, 0),
  },
}));

export interface HeaderMenuMenuHandle {
  openMenu: (event: React.MouseEvent<HTMLElement>) => void;
  closeMenu: () => void;
}

type Props = {
  items: HeaderMenuItemProps[];
};

const HeaderMenuMenu = forwardRef<HeaderMenuMenuHandle, Props>(
  ({ items }, ref) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const navigate = useNavigate();

    const handleClose = () => {
      setAnchorEl(null);
    };

    useImperativeHandle(ref, () => ({
      openMenu: (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
      },
      closeMenu: () => {
        setAnchorEl(null);
      },
    }));

    return (
      <CustomMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {items?.map((item, index) => (
          <HeaderMenuMenuItem
            key={index}
            title={item.title}
            icon={item.icon}
            onClick={(event) => {
              handleClose();
              event.preventDefault();
              if (item.path) navigate(item.path);
            }}
          />
        ))}
      </CustomMenu>
    );
  },
);

export default HeaderMenuMenu;
