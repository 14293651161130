import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DashboardSideType } from 'src/@types/dashboard';
import { FileData } from 'src/@types/file';
import { MoneyHolderAccountTypeType } from 'src/@types/money-holder-accountant';
import {
  CheckBankAccountDialog,
  CreateDialog,
  DepositVerificationDialog,
  Filter,
  TransactionState,
  TransactionStatusType,
  TransactionTypeType,
  VerComTranDiag,
  VerifyCompanyTransactionFilter,
  WithdrawVerificationDialog,
} from 'src/@types/transaction';
import { UserType } from 'src/@types/user';

const initialState: TransactionState = {
  default: {
    revenue: {
      depositVerification: {
        dialog: {
          toggle: false,
          mode: 'VERIFY',
        },
        filter: {
          searchCharacter: '',
          userType: UserType.TEACHER,
        },
        form: {
          transactionVerificationImage: null,
        },
      },
      createCompanyWithdraw: {
        dialog: {
          mode: 'CREATE',
          toggle: false,
        },
      },
    },
    expense: {
      withdrawVerification: {
        checkBankAccountDialog: {
          isFulfilled: false,
          toggle: false,
        },
        dialog: {
          toggle: false,
          mode: 'VERIFY',
        },
        filter: {
          searchCharacter: '',
          userType: UserType.TEACHER,
        },
      },
      createCompanyDeposit: {
        dialog: {
          mode: 'CREATE',
          toggle: false,
        },
      },
      createCompanyWithdraw: {
        dialog: {
          mode: 'CREATE',
          toggle: false,
        },
      },
    },
  },
  manager: {
    revenue: {
      companyWithdrawVerification: {
        filter: {
          searchCharacter: '',
          type: TransactionTypeType.COMPANY_REVENUE_ACCOUNT_WITHDRAW,
          statuses: [TransactionStatusType.VERIFY_PENDING],
          moneyHolderAccountType: MoneyHolderAccountTypeType.REVENUE,
          bankAccountId: null,
          ewalletAccountId: null,
        },
        dialog: {
          toggle: false,
        },
      },
    },
    expense: {
      companyDepositVerification: {
        filter: {
          searchCharacter: '',
          type: TransactionTypeType.COMPANY_EXPENSE_ACCOUNT_DEPOSIT,
          statuses: [TransactionStatusType.VERIFY_PENDING],
          moneyHolderAccountType: MoneyHolderAccountTypeType.EXPENSES,
          bankAccountId: null,
          ewalletAccountId: null,
        },
        dialog: {
          toggle: false,
        },
      },
      companyWithdrawVerification: {
        filter: {
          searchCharacter: '',
          type: TransactionTypeType.COMPANY_EXPENSE_ACCOUNT_WITHDRAW,
          statuses: [TransactionStatusType.VERIFY_PENDING],
          moneyHolderAccountType: MoneyHolderAccountTypeType.EXPENSES,
          bankAccountId: null,
          ewalletAccountId: null,
        },
        dialog: {
          toggle: false,
        },
      },
    },
  },
  selectedTransactionId: null,
};

const slice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    //Common
    setSelectedTransactionId(state, action: PayloadAction<number | null>) {
      state.selectedTransactionId = action.payload;
    },

    //Manager
    //--Verify company deposit
    //----Filter
    setCompanyDepositVerificationFilter: (
      state,
      action: PayloadAction<{
        filter: Partial<VerifyCompanyTransactionFilter>;
      }>,
    ) => {
      const { filter } = action.payload;

      state.manager.expense.companyDepositVerification.filter = {
        ...state.manager.expense.companyDepositVerification.filter,
        ...filter,
      };
    },

    setCompanyWithdrawVerificationFilter: (
      state,
      action: PayloadAction<{
        filter: Partial<VerifyCompanyTransactionFilter>;
        dashboardSide: DashboardSideType;
      }>,
    ) => {
      const { filter, dashboardSide } = action.payload;

      state.manager[dashboardSide].companyWithdrawVerification.filter = {
        ...state.manager[dashboardSide].companyWithdrawVerification.filter,
        ...filter,
      };
    },

    //----Reset filter
    resetCompanyDepositVerificationFilter: (
      state,
      action: PayloadAction<Partial<VerifyCompanyTransactionFilter>>,
    ) => {
      const filter = action.payload;

      state.manager.expense.companyDepositVerification.filter = {
        ...initialState.manager.expense.companyDepositVerification.filter,
        ...filter,
      };
    },

    resetCompanyWithdrawVerificationFilter: (
      state,
      action: PayloadAction<{
        dashboardSide: DashboardSideType;
        filter?: Partial<VerifyCompanyTransactionFilter>;
      }>,
    ) => {
      const { dashboardSide, filter } = action.payload;

      state.manager[dashboardSide].companyWithdrawVerification.filter = {
        ...initialState.manager[dashboardSide].companyWithdrawVerification
          .filter,
        ...filter,
      };
    },

    //----Dialog
    setVerifyDepositManagerDialog: (
      state,
      action: PayloadAction<Partial<VerComTranDiag>>,
    ) => {
      state.manager.expense.companyDepositVerification.dialog = {
        ...state.manager.expense.companyDepositVerification.dialog,
        ...action.payload,
      };
    },

    setVerifyWithdrawManagerDialog: (
      state,
      action: PayloadAction<{
        dialog: Partial<VerComTranDiag>;
        dashboardSide: DashboardSideType;
      }>,
    ) => {
      const { dialog, dashboardSide } = action.payload;

      state.manager[dashboardSide].companyWithdrawVerification.dialog = {
        ...state.manager[dashboardSide].companyWithdrawVerification.dialog,
        ...dialog,
      };
    },

    //----Reset Dialog
    resetVerifyDepositManagerDialog: (state) => {
      state.manager.expense.companyDepositVerification.dialog =
        initialState.manager.expense.companyDepositVerification.dialog;
    },

    resetVerifyWithdrawManagerDialog: (
      state,
      action: PayloadAction<{ dashboardSide: DashboardSideType }>,
    ) => {
      const { dashboardSide } = action.payload;

      state.manager[dashboardSide].companyWithdrawVerification.dialog =
        initialState.manager[dashboardSide].companyWithdrawVerification.dialog;
    },

    //Staff
    //--Create Company Transaction
    //----Dialog
    setCreateCompanyDepositDialog: (
      state,
      action: PayloadAction<{ dialog: Partial<CreateDialog> }>,
    ) => {
      const { dialog } = action.payload;

      state.default.expense.createCompanyDeposit.dialog = {
        ...state.default.expense.createCompanyDeposit.dialog,
        ...dialog,
      };
    },

    setCreateCompanyWithdrawDialog: (
      state,
      action: PayloadAction<{
        dialog: Partial<CreateDialog>;
        dashboardSide: DashboardSideType;
      }>,
    ) => {
      const { dialog, dashboardSide } = action.payload;

      state.default[dashboardSide].createCompanyWithdraw.dialog = {
        ...state.default[dashboardSide].createCompanyWithdraw.dialog,
        ...dialog,
      };
    },

    //----Reset Dialog
    resetCreateCompanyDepositDialog: (state) => {
      state.default.expense.createCompanyDeposit.dialog =
        initialState.default.expense.createCompanyDeposit.dialog;
    },

    resetCreateCompanyWithdrawDialog: (
      state,
      action: PayloadAction<{ dashboardSide: DashboardSideType }>,
    ) => {
      const { dashboardSide } = action.payload;

      state.default[dashboardSide].createCompanyWithdraw.dialog =
        initialState.default[dashboardSide].createCompanyWithdraw.dialog;
    },

    //--Verify Client Deposit Transaction
    //----Set form
    setVerifyDepositTransactionForm: (
      state,
      action: PayloadAction<{
        transactionVerificationImage: File | FileData | null;
      }>,
    ) => {
      state.default.revenue.depositVerification.form = {
        ...state.default.revenue.depositVerification.form,
        ...action.payload,
      };
    },

    //----Set Dialog
    setVerifyDepositTransactionDialog: (
      state,
      action: PayloadAction<Partial<DepositVerificationDialog>>,
    ) => {
      state.default.revenue.depositVerification.dialog = {
        ...state.default.revenue.depositVerification.dialog,
        ...action.payload,
      };
    },

    //----Set Filter
    setVerifyDepositTransactionFilter: (
      state,
      action: PayloadAction<Partial<Filter>>,
    ) => {
      state.default.revenue.depositVerification.filter = {
        ...state.default.revenue.depositVerification.filter,
        ...action.payload,
      };
    },

    //----Reset Filter
    resetVerifyDepositTransactionFilter: (state) => {
      state.default.revenue.depositVerification.filter =
        initialState.default.revenue.depositVerification.filter;
    },

    //--Verify Client Withdraw Transaction
    //----Set Dialog
    setVerifyWithdrawTransactionDialog: (
      state,
      action: PayloadAction<Partial<WithdrawVerificationDialog>>,
    ) => {
      state.default.expense.withdrawVerification.dialog = {
        ...state.default.expense.withdrawVerification.dialog,
        ...action.payload,
      };
    },

    //----Set Check Bank Account Dialog
    setVerifyWithdrawTransactionCheckBankAccountDialog: (
      state,
      action: PayloadAction<Partial<CheckBankAccountDialog>>,
    ) => {
      state.default.expense.withdrawVerification.checkBankAccountDialog = {
        ...state.default.expense.withdrawVerification.checkBankAccountDialog,
        ...action.payload,
      };
    },

    //----Set Filter
    setVerifyWithdrawTransactionFilter: (
      state,
      action: PayloadAction<Partial<Filter>>,
    ) => {
      state.default.expense.withdrawVerification.filter = {
        ...state.default.expense.withdrawVerification.filter,
        ...action.payload,
      };
    },

    //----Reset filter
    resetVerifyWithdrawTransactionFilter: (state) => {
      state.default.expense.withdrawVerification.filter =
        initialState.default.expense.withdrawVerification.filter;
    },
  },
});

export default slice.reducer;

export const {
  setVerifyDepositTransactionForm,
  resetVerifyDepositTransactionFilter,
  resetVerifyDepositManagerDialog,
  resetVerifyWithdrawTransactionFilter,
  resetVerifyWithdrawManagerDialog,
  setCompanyDepositVerificationFilter,
  setCompanyWithdrawVerificationFilter,
  setCreateCompanyDepositDialog,
  setCreateCompanyWithdrawDialog,
  setSelectedTransactionId,
  setVerifyDepositTransactionDialog,
  setVerifyDepositTransactionFilter,
  setVerifyWithdrawTransactionDialog,
  setVerifyWithdrawTransactionCheckBankAccountDialog,
  setVerifyWithdrawTransactionFilter,
  setVerifyDepositManagerDialog,
  setVerifyWithdrawManagerDialog,
  resetCompanyDepositVerificationFilter,
  resetCompanyWithdrawVerificationFilter,
  resetCreateCompanyDepositDialog,
  resetCreateCompanyWithdrawDialog,
} = slice.actions;
