/**
 * redux slice for company money holder account verification
 */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ICompanyMoneyHolderAccountVerificationState } from 'src/@types/company-money-holder-account-verification';

const initialState: ICompanyMoneyHolderAccountVerificationState = {
  bankAccountId: undefined,
  ewalletAccountId: undefined,
  create: {
    form: {
      actualBalance: undefined,
      verificationImage: undefined,
    },
  },
};

const slice = createSlice({
  name: 'companyMoneyHolderAccountVerification',
  initialState,
  reducers: {
    setCreateForm: (
      state,
      action: PayloadAction<
        ICompanyMoneyHolderAccountVerificationState['create']['form']
      >,
    ) => {
      state.create.form = action.payload;
    },
    resetCreateForm: (state) => {
      state.create.form = initialState.create.form;
    },
    setBankAccountId: (
      state,
      action: PayloadAction<
        ICompanyMoneyHolderAccountVerificationState['bankAccountId']
      >,
    ) => {
      state.bankAccountId = action.payload;
    },
    setEwalletAccountId: (
      state,
      action: PayloadAction<
        ICompanyMoneyHolderAccountVerificationState['ewalletAccountId']
      >,
    ) => {
      state.ewalletAccountId = action.payload;
    },
  },
});

//Reducer
export default slice.reducer;

//actions
export const {
  setCreateForm,
  resetCreateForm,
  setEwalletAccountId,
  setBankAccountId,
} = slice.actions;

export const isAlreadySetCreateForm = (
  form: ICompanyMoneyHolderAccountVerificationState['create']['form'],
) => {
  return (
    form.actualBalance !== undefined && form.verificationImage !== undefined
  );
};
