import { PrefixType } from 'src/constants/prefix';
import axiosInstance from 'src/utils/axios';
import { WORK_ATTENDANCE_API } from '../constants/work-attendance.api.constant';
import { WorkAttendance, WorkingNowRequest } from 'src/@types/work-attendance';

export const start = (prefixType: PrefixType): Promise<WorkAttendance> =>
  axiosInstance.post(`${prefixType}/${WORK_ATTENDANCE_API.start}`);

export const end = (
  id: number,
  prefixType: PrefixType,
): Promise<WorkAttendance> =>
  axiosInstance.post(`${prefixType}/${WORK_ATTENDANCE_API.end}`, {
    id,
  });

export const workingNow = (
  request: WorkingNowRequest,
  prefixType: PrefixType,
): Promise<WorkAttendance> =>
  axiosInstance.get(`${prefixType}/${WORK_ATTENDANCE_API.workingNow}`, {
    params: request,
  });
