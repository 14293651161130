import {
  FindCurrentRequest,
  WorkShift,
  OffCountRequest,
  OffCountResponse,
  OffRequest,
  UpdateRequest,
  CreateMultiRequest,
} from 'src/@types/work-shift';
import { PrefixType } from 'src/constants/prefix';
import axiosInstance from 'src/utils/axios';
import { WORK_SHIFT_API } from '../constants/full-time-work-shift.api.constant';

export const findCurrentUser = (
  request: FindCurrentRequest,
  prefixType: PrefixType,
): Promise<WorkShift[]> =>
  axiosInstance.get(`${prefixType}/${WORK_SHIFT_API.CURRENT_USER}`, {
    params: request,
  });

export const off = (
  request: OffRequest,
  prefixType: PrefixType,
): Promise<WorkShift> =>
  axiosInstance.post(`${prefixType}/${WORK_SHIFT_API.OFF}`, request);

export const offCount = (
  request: OffCountRequest,
  prefixType: PrefixType,
): Promise<OffCountResponse> =>
  axiosInstance.get(`${prefixType}/${WORK_SHIFT_API.OFF_COUNT}`, {
    params: request,
  });

export const checkInTimeWork = (prefixType: PrefixType): Promise<WorkShift> =>
  axiosInstance.get(`${prefixType}/${WORK_SHIFT_API.CHECK_IN_TIME_WORK}`);

export const createMulti = (
  request: CreateMultiRequest,
  prefixType: PrefixType,
) => axiosInstance.post(`${prefixType}/${WORK_SHIFT_API.MULTI}`, request);

//Manager
export const managerUpdate = (request: UpdateRequest, prefixType: PrefixType) =>
  axiosInstance.put(`${prefixType}/${WORK_SHIFT_API.MANAGER_ROOT}`, request);
