// @mui
import { Box, List } from '@mui/material';
//
import { grey } from '@mui/material/colors';
import { Fragment } from 'react';
import Image from 'src/components/Image';
import { ICON } from 'src/config';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import useAuth from 'src/hooks/useAuth';
import { NavSectionProps } from '../type';
import NavList from './NavList';
import { ListHeaderItemTextStyle, ListSubheaderStyle } from './style';

// ----------------------------------------------------------------------

export default function NavSectionVertical({
  navConfig,
  isCollapse,
  ...other
}: NavSectionProps) {
  const { userRole } = useAuth();

  return (
    <Box {...other}>
      {navConfig.map((group, index) => (
        <Fragment key={group.subheader}>
          {index > 0 &&
            group.items.find((item) => {
              if (!userRole) return true;
              return item.roles?.includes(userRole);
            }) && (
              <Box
                sx={{
                  width: '100%',
                  height: 10,
                  backgroundColor: grey[100],
                  mt: 3,
                }}
              />
            )}
          <List key={group.subheader} disablePadding sx={{}}>
            <RoleBasedGuard roles={group.roles}>
              <ListSubheaderStyle
                sx={{
                  ...(isCollapse && {
                    opacity: 0,
                  }),
                }}
              >
                <Box display="flex" fontSize={16} alignItems="center">
                  {group.icon && (
                    <Box
                      mr={2}
                      sx={{
                        width: ICON.NAVBAR_HEADER_ITEM,
                        height: ICON.NAVBAR_HEADER_ITEM,
                      }}
                    >
                      <Image src={group.icon.props.src} />
                    </Box>
                    // <ListHeaderItemIconStyle>{group.icon}</ListHeaderItemIconStyle>
                  )}
                  {group.subheader && (
                    <ListHeaderItemTextStyle>
                      {group.subheader}
                    </ListHeaderItemTextStyle>
                  )}
                </Box>
              </ListSubheaderStyle>

              {group.items.map((list) => (
                <NavList
                  key={list.title + list.path}
                  data={{
                    roles: group.roles,
                    ...list,
                  }}
                  depth={1}
                  hasChildren={!!list.children}
                  isCollapse={isCollapse}
                />
              ))}
            </RoleBasedGuard>
          </List>
        </Fragment>
      ))}
    </Box>
  );
}
