import { AnimatePresence, m } from 'framer-motion';
// @mui
import { Box, Dialog, DialogProps, Paper, PaperProps } from '@mui/material';
//
import { varFade } from '../animate/variants';
import { memo, useCallback } from 'react';

// ----------------------------------------------------------------------

export interface Props extends DialogProps {
  variants?: Record<string, unknown>;
  onClose?: VoidFunction;
  minWidth?: number | string;
  disableAnimate?: boolean;
}

const AnimateDialog = memo(function AnimateDialog({
  open = false,
  variants,
  onClose,
  children,
  sx,
  disableAnimate = false,
  minWidth,
  ...other
}: Props) {
  const PaperComponent = useCallback(
    (props: PaperProps) => (
      <Box
        component={m.div}
        {...(!disableAnimate &&
          (variants ||
            varFade({
              distance: 120,
              durationIn: 0.32,
              durationOut: 0.24,
              easeIn: 'easeInOut',
            }).inUp))}
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          onClick={onClose}
          sx={{ width: '100%', height: '100%', position: 'fixed' }}
        />
        <Paper sx={sx} {...props}>
          {props.children}
        </Paper>
      </Box>
    ),
    [],
  );

  return (
    <AnimatePresence>
      {open && (
        <Dialog
          fullWidth
          maxWidth="xs"
          open={open}
          onClose={onClose}
          PaperComponent={PaperComponent}
          {...other}
        >
          {children}
        </Dialog>
      )}
    </AnimatePresence>
  );
});

export default AnimateDialog;
