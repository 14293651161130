import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ITransactionSupportState,
  TransactionSupportRequestTypeType,
} from 'src/@types/transaction-support';
import { UserType } from 'src/@types/user';

const initialState: ITransactionSupportState = {
  depositSupportRequestVerification: {
    mode: 'EDIT',
    form: {
      error: {
        undefinedTransaction: null,
      },
    },
    dialog: {
      toggle: false,
      undefinedTransactionCode: '',
    },
    filter: {
      searchCharacter: '',
      userType: UserType.TEACHER,
      transactionSupportRequestType:
        TransactionSupportRequestTypeType.DEPOSIT_SUPPORT,
    },
    isShowByUndefinedTransaction: false,
  },
  withdrawSupportRequestVerification: {
    mode: 'EDIT',
    userType: UserType.STUDENT,
    dialog: {
      toggle: false,
    },
    confirmForm: {
      isDisabled: false,
    },
    isLoading: false,
    isShowByUndefinedTransaction: false,
    filter: {
      searchCharacter: '',
      userType: UserType.TEACHER,
      transactionSupportRequestType:
        TransactionSupportRequestTypeType.WITHDRAW_SUPPORT,
    },
  },
  selectedTransactionSupportRequestId: null,
};

const slice = createSlice({
  name: 'transactionSupportRequest',
  initialState,
  reducers: {
    //Withdraw
    setWithdrawSupportRequestVerificationFilter(
      state,
      action: PayloadAction<{
        searchCharacter?: string;
        userType?: UserType;
      }>,
    ) {
      state.withdrawSupportRequestVerification.filter = {
        ...state.withdrawSupportRequestVerification.filter,
        ...action.payload,
      };
    },

    resetWithdrawSupportRequestVerificationFilter(state) {
      state.withdrawSupportRequestVerification.filter = {
        searchCharacter: '',
        userType: UserType.TEACHER,
        transactionSupportRequestType:
          TransactionSupportRequestTypeType.WITHDRAW_SUPPORT,
      };
    },

    setWithdrawSupportRequestVerificationUserType(
      state,
      action: PayloadAction<UserType>,
    ) {
      state.withdrawSupportRequestVerification.userType = action.payload;
    },

    setWithdrawSupportRequestVerificationIsLoading(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.withdrawSupportRequestVerification.isLoading = action.payload;
    },

    setWithdrawSupportRequestVerificationConfirmForm(
      state,
      action: PayloadAction<{ isDisabled?: boolean }>,
    ) {
      state.withdrawSupportRequestVerification.confirmForm = {
        ...state.withdrawSupportRequestVerification.confirmForm,
        ...action.payload,
      };
    },

    setWithdrawSupportRequestVerificationMode(
      state,
      action: PayloadAction<{
        mode: 'VIEW' | 'EDIT';
        isShowByUndefinedTransaction?: boolean;
      }>,
    ) {
      state.withdrawSupportRequestVerification.mode = action.payload.mode;
      if (action.payload.isShowByUndefinedTransaction) {
        state.withdrawSupportRequestVerification.isShowByUndefinedTransaction =
          action.payload.isShowByUndefinedTransaction;
      }
    },

    setWithdrawSupportRequestDialogVerificationToggle(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.withdrawSupportRequestVerification.dialog.toggle = action.payload;
    },

    resetWithdrawSupportRequestDialogVerification(state) {
      state.withdrawSupportRequestVerification = {
        ...state.withdrawSupportRequestVerification,
        userType: UserType.STUDENT,
        dialog: {
          toggle: false,
        },
        confirmForm: {
          isDisabled: false,
        },
        isLoading: false,
        isShowByUndefinedTransaction: false,
      };
      state.selectedTransactionSupportRequestId = null;
    },

    //Common
    setSelectedTransactionSupportRequestId(
      state,
      action: PayloadAction<number | null>,
    ) {
      state.selectedTransactionSupportRequestId = action.payload;
    },

    //Deposit
    setDepositSupportRequestVerificationFilter(
      state,
      action: PayloadAction<{
        searchCharacter?: string;
        userType?: UserType;
      }>,
    ) {
      state.depositSupportRequestVerification.filter = {
        ...state.depositSupportRequestVerification.filter,
        ...action.payload,
      };
    },

    resetDepositSupportRequestVerificationFilter(state) {
      state.depositSupportRequestVerification.filter = {
        searchCharacter: '',
        userType: UserType.TEACHER,
        transactionSupportRequestType:
          TransactionSupportRequestTypeType.DEPOSIT_SUPPORT,
      };
    },

    setDepositSupportRequestDialogVerificationToggle(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.depositSupportRequestVerification.dialog.toggle = action.payload;
    },

    setDepositSupportRequestDialogVerificationUndefinedTransactionCode(
      state,
      action: PayloadAction<string>,
    ) {
      state.depositSupportRequestVerification.dialog.undefinedTransactionCode =
        action.payload;
    },

    setDepositSupportRequestVerificationMode(
      state,
      action: PayloadAction<'VIEW' | 'EDIT'>,
    ) {
      state.depositSupportRequestVerification.mode = action.payload;
    },

    setDepositSupportRequestVerificationFormError(
      state,
      action: PayloadAction<{
        undefinedTransaction: any;
      }>,
    ) {
      state.depositSupportRequestVerification.form.error = {
        ...state.depositSupportRequestVerification.form.error,
        ...action.payload,
      };
    },

    setIsShowDepositSupportRequestVerificationByUndefinedTransaction(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.depositSupportRequestVerification.isShowByUndefinedTransaction =
        action.payload;
    },

    resetDepositSupportRequestVerificationForm(state) {
      state.depositSupportRequestVerification.form = {
        error: {
          undefinedTransaction: null,
        },
      };
    },
  },
});

//Reducer
export default slice.reducer;

export const {
  resetWithdrawSupportRequestDialogVerification,
  setWithdrawSupportRequestVerificationUserType,
  setWithdrawSupportRequestVerificationIsLoading,
  setWithdrawSupportRequestVerificationConfirmForm,
  setWithdrawSupportRequestVerificationMode,
  setSelectedTransactionSupportRequestId,
  setDepositSupportRequestVerificationMode,
  setIsShowDepositSupportRequestVerificationByUndefinedTransaction,
  setDepositSupportRequestVerificationFormError,
  setWithdrawSupportRequestDialogVerificationToggle,
  setDepositSupportRequestDialogVerificationToggle,
  resetDepositSupportRequestVerificationForm,
  resetDepositSupportRequestVerificationFilter,
  resetWithdrawSupportRequestVerificationFilter,
  setDepositSupportRequestVerificationFilter,
  setWithdrawSupportRequestVerificationFilter,
  setDepositSupportRequestDialogVerificationUndefinedTransactionCode,
} = slice.actions;
