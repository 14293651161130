import {
  resetVerifyWithdrawManagerDialog,
  setSelectedTransactionId,
  setVerifyWithdrawManagerDialog,
} from 'src/redux/slices/transaction.slice';
import { useDispatch, useSelector } from 'src/redux/store';

export function useVerifyDialog() {
  //state
  const toggle = useSelector(
    (state) =>
      state.transaction.manager.expense.companyWithdrawVerification.dialog
        .toggle,
  );

  //hooks
  const dispatch = useDispatch();

  const openDialogById = (id: number) => {
    dispatch(setSelectedTransactionId(id));
    dispatch(
      setVerifyWithdrawManagerDialog({
        dialog: {
          toggle: true,
        },
        dashboardSide: 'expense',
      }),
    );
  };

  const closeDialog = () => {
    dispatch(
      resetVerifyWithdrawManagerDialog({
        dashboardSide: 'expense',
      }),
    );
  };

  return { toggle, closeDialog, openDialogById };
}
