import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { SlideshowLightbox } from 'lightbox.js-react';
import { useSnackbar } from 'notistack';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import 'react-advanced-cropper/dist/style.css';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import { customIcon } from 'src/assets';
import { ERROR_MESSAGE } from 'src/constants/error-message.contant';
import useInitialRender from 'src/hooks/useInitialRender';
import useToggle from 'src/hooks/useToggle';
import { IconSVG } from '../icon';
import { useDispatch, useSelector } from 'src/redux/store';
import {
  setFileForm,
  setFileFormError,
  setFileToUpload,
} from 'src/redux/slices/file.slice';
import Iconify from '../Iconify';
import Image from '../Image';

// for show lightbox image
export type ImageLightBox = {
  src: string;
  alt: string;
};

type UploadImageDropZoneProps = {
  accept?: Accept;
  onChange?: (acceptedFiles: File[], isUnMount: boolean) => void;
  showLabelError?: boolean;
  mode?: 'VIEW' | 'UPLOAD_IMAGE' | 'ACTION';
  imagesView?: (ImageLightBox | undefined)[];
  isError?: boolean;
  notImageDescription?: string;
  action?: ReactNode;
  isGlobalState?: boolean;
  isErrorGlobalState?: boolean;
  bgColor?: string;
};

export default function TransactionUploadSingleFile({
  accept = {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
  },
  onChange,
  showLabelError = false,
  mode = 'UPLOAD_IMAGE',
  imagesView = [],
  isError = false,
  notImageDescription,
  action,
  isErrorGlobalState = true,
  bgColor = '#32A3AB',
}: UploadImageDropZoneProps) {
  const newImagesView = imagesView.filter((item) => {
    if (!item) return false;
    if (!item.src) return false;
    return true;
  });
  // ------------------------------------------------------------
  // hook
  const theme = useTheme();
  const {
    toggle: toggleLightBox,
    onOpen: onOpenLightBox,
    onClose: onCloseLightBox,
  } = useToggle();
  const { initialRender } = useInitialRender();
  const { enqueueSnackbar } = useSnackbar();

  // ------------------------------------------------------------
  // state
  const [acceptedFilesState, setAcceptedFilesState] = useState<File[]>([]);

  const [acceptedFilesLightBoxState, setAcceptedFilesLightBoxState] = useState<
    ImageLightBox[]
  >([]);
  const [typeFilesAcceptState, setTypeFilesAcceptState] = useState<string[]>(
    [],
  );
  const [isExistFileReject, setIsExistFileReject] = useState<boolean>(false);
  const [isUploadError, setIsUploadError] = useState<boolean>(false);
  const { isError: isErrorState } = useSelector((state) => state.file.form);

  //----------------------------------------------------------------
  // hooks
  const dispatch = useDispatch();

  // ------------------------------------------------------------
  // handle
  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      // check error file type
      if (fileRejections.length > 0) {
        const isHaveInvalidType = fileRejections.some((fileRejection) => {
          const isInValidType = fileRejection.errors.some(
            (error) => error.code === 'file-invalid-type',
          );
          return isInValidType;
        });

        if (isHaveInvalidType) {
          setIsExistFileReject(true);
        } else {
          setIsExistFileReject(false);
        }

        if (!showLabelError)
          return enqueueSnackbar(
            ERROR_MESSAGE.COMMON.UPLOAD_IMAGE_TYPE(
              typeFilesAcceptState.join(' ,'),
            ),
            {
              variant: 'warning',
            },
          );
      }

      // check size
      const isHaveFiveSizeNotValid = acceptedFiles.some((file) => {
        if (file.size > 5 * 1024 * 1024) {
          return true;
        } else {
          return false;
        }
      });

      if (isHaveFiveSizeNotValid)
        return enqueueSnackbar(ERROR_MESSAGE.COMMON.UPLOAD_IMAGE_SIZE('5mb'), {
          variant: 'warning',
        });

      // set state
      setAcceptedFilesState(acceptedFiles);
      dispatch(setFileToUpload({ file: acceptedFiles[0] }));

      const newAcceptedFilesLightBoxState: {
        alt: string;
        src: string;
      }[] = acceptedFiles.map((file) => ({
        alt: file.name,
        src: URL.createObjectURL(file),
      }));

      setAcceptedFilesLightBoxState(newAcceptedFilesLightBoxState);
    },
    [],
  );

  // ------------------------------------------------------------
  // set up drop zone
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept,
    disabled: mode === 'VIEW' || mode === 'ACTION',
  });

  // ------------------------------------------------------------
  // useEffect
  useEffect(() => {
    const typeFilesAccept: string[] = [];

    Object.values(accept).forEach((typeFiles) => {
      typeFiles.map((typeFile) => typeFilesAccept.push(typeFile));
    });

    setTypeFilesAcceptState(typeFilesAccept);

    return () => {
      dispatch(
        setFileToUpload({
          file: null,
          bucket: undefined,
          nameFolder: undefined,
        }),
      );
    };
  }, []);

  useEffect(() => {
    if (acceptedFilesState) {
      setIsUploadError(false);
      dispatch(setFileFormError({ isError: false }));
    }

    if (onChange && initialRender) {
      onChange(acceptedFilesState, false);
    }

    return () => {
      if (onChange) {
        onChange([], true);
      }
      dispatch(setFileForm(null));
      dispatch(setFileFormError({ isError: false }));
    };
  }, [acceptedFilesState]);

  useEffect(() => {
    if (isError) {
      setIsUploadError(isError);
      if (isErrorGlobalState) {
        dispatch(setFileFormError({ isError: isError }));
      }
    }
  }, [isError]);

  useEffect(() => {
    if (isErrorState && isErrorGlobalState) {
      setIsUploadError(isErrorState);
    }
  }, [isErrorState, isErrorGlobalState]);

  return (
    <Stack position={'relative'} alignItems="flex-end">
      <Stack
        direction={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        paddingY={3}
        height={200}
        width={280}
        border={
          mode === 'VIEW'
            ? `1px solid ${grey[700]}`
            : isUploadError
              ? `1px dashed ${theme.palette.error.main}`
              : mode === 'ACTION'
                ? `1px dashed ${theme.palette.primary.main}`
                : `1px dashed ${grey[700]}`
        }
        borderRadius={1}
        sx={{
          cursor: 'pointer',
        }}
        {...getRootProps({ className: 'dropzone' })}
      >
        <input {...getInputProps()} />
        <Box position={'relative'}>
          <Stack
            position={'relative'}
            borderRadius={1}
            overflow={'hidden'}
            width={180}
            height={140}
            bgcolor={bgColor}
          >
            {/* show image */}
            <Box height={116} position={'relative'}>
              {/* show first image for mode upload */}
              {mode === 'UPLOAD_IMAGE' &&
              !acceptedFilesState[0] &&
              newImagesView[0] ? (
                <img
                  src={newImagesView[0].src}
                  width={'100%'}
                  height={'100%'}
                  alt={newImagesView[0].src}
                  style={{
                    objectFit: 'contain',
                  }}
                />
              ) : (
                acceptedFilesState[0] && (
                  <img
                    src={URL.createObjectURL(acceptedFilesState[0])}
                    width={'100%'}
                    height={'100%'}
                    alt="drop-zone"
                    style={{
                      objectFit: 'contain',
                    }}
                  />
                )
              )}

              {/* show first image for mode view */}
              {(mode === 'VIEW' || mode === 'ACTION') && newImagesView[0] && (
                <img
                  src={newImagesView[0].src}
                  width={'100%'}
                  height={'100%'}
                  alt={newImagesView[0].alt}
                  style={{
                    objectFit: 'contain',
                  }}
                />
              )}

              {/* show initial front */}
              {((mode === 'UPLOAD_IMAGE' && !acceptedFilesState[0]) ||
                ((mode === 'VIEW' || mode === 'ACTION') &&
                  !newImagesView[0])) && (
                <Stack
                  width={'100%'}
                  height={'100%'}
                  direction={'column'}
                  spacing={1}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <IconSVG
                    path={customIcon.imageOutline}
                    css={{
                      width: '18px',
                      height: '18px',
                      lineHeight: '18px',
                      color:
                        bgColor === grey[50] || bgColor === 'white'
                          ? '#212121'
                          : 'white',
                    }}
                  />

                  <Typography
                    color={
                      bgColor === grey[50] || bgColor === 'white'
                        ? '#212121'
                        : 'white'
                    }
                  >
                    Tải ảnh lên
                  </Typography>
                </Stack>
              )}
            </Box>

            {/* status */}
            <Stack
              direction={'row'}
              height={24}
              bgcolor={grey[200]}
              alignItems={'center'}
              justifyContent={'center'}
              padding={theme.spacing(0, 1.5)}
            >
              {mode === 'UPLOAD_IMAGE' && (
                <Typography
                  variant="caption"
                  fontWeight={500}
                  color={'#606060'}
                  display="inline-block"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                >
                  {acceptedFilesState[0]
                    ? `Hóa đơn ${acceptedFilesState[0].name}`
                    : notImageDescription || 'Chưa có ảnh'}
                </Typography>
              )}

              {mode === 'VIEW' && (
                <Typography
                  variant="caption"
                  fontWeight={500}
                  color={'#606060'}
                >
                  {`${newImagesView.length} ảnh`}
                </Typography>
              )}
            </Stack>
          </Stack>

          {/* icon upload image */}
          {(mode !== 'VIEW' || (mode === 'VIEW' && action)) && (
            <Box
              position={'absolute'}
              bottom={'-6px'}
              right={'-12px'}
              width={'24px'}
              height={'24px'}
              borderRadius={50}
              bgcolor={'white'}
              boxShadow={theme.shadows[10]}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Stack
                justifyContent={'center'}
                alignItems={'center'}
                direction={'row'}
              >
                {action ? (
                  <>{action}</>
                ) : (
                  <Box p={theme.spacing(0.4)}>
                    <Image src={customIcon.uploadFileOutline} />
                  </Box>
                )}
              </Stack>
            </Box>
          )}
        </Box>
      </Stack>

      {/* label error */}
      {showLabelError && (
        <Stack spacing={3} direction={'row'} mt={3} alignItems={'center'}>
          <Typography color={isExistFileReject ? 'red' : grey[600]}>
            Chỉ chấp nhận File {typeFilesAcceptState.join(', ')}
          </Typography>
        </Stack>
      )}

      {/* show image light box */}
      <SlideshowLightbox
        showAllImages={false}
        showThumbnails
        showThumbnailIcon
        onClose={onCloseLightBox}
        open={toggleLightBox}
        images={
          mode === 'UPLOAD_IMAGE' ? acceptedFilesLightBoxState : newImagesView
        }
      />

      {/* button show light box list image */}
      {((mode === 'UPLOAD_IMAGE' && acceptedFilesLightBoxState[0]) ||
        (mode === 'VIEW' && newImagesView[0])) && (
        <Box
          style={{
            cursor: 'pointer',
          }}
          bgcolor={''}
          position={'absolute'}
          top={2}
          right={2}
          borderRadius={1}
          onClick={onOpenLightBox}
        >
          <IconButton
            size="small"
            color="inherit"
            sx={{
              padding: '2px',
            }}
          >
            <Iconify
              icon="ic:baseline-fullscreen"
              color={theme.palette.primary.main}
              fontSize={20}
            />
          </IconButton>
        </Box>
      )}
    </Stack>
  );
}
