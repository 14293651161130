import { useState } from 'react';

// ----------------------------------------------------------------------

export default function useToggle(defaultChecked?: boolean) {
  const [toggle, setToggle] = useState(defaultChecked || false);
  const [value, setValue] = useState<any>(null);

  return {
    value,
    toggle,
    onToggle: () => setToggle(!toggle),
    onOpen: (value?: any) => {
      setValue(value);
      setToggle(true);
    },
    onClose: () => setToggle(false),
    setToggle,
  };
}
