import { List, styled } from '@mui/material';
import { ReactElement } from 'react';
import HeaderMenuItem from './HeaderMenuItem';

//----------------------------------------------------------------
const CustomList = styled(List)(({ theme }) => ({
  height: ' 100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: 0,
  margin: 0,
  listStyle: 'none',
}));

//----------------------------------------------------------------
type Props = {
  headerMenuConfig: {
    title: string;
    path: string;
    icon?: ReactElement;
    children?: any;
  }[];
};

export default function HeaderMenuSection({ headerMenuConfig }: Props) {
  return (
    <CustomList>
      {headerMenuConfig.map((item, index) => (
        <HeaderMenuItem key={index} {...item} />
      ))}
    </CustomList>
  );
}
