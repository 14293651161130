// --------------------------------------------------

import { PrefixType } from 'src/constants/prefix';
import { Pagination } from './pagination';
import {
  CreateRecruitmentRequirementForm,
  RecruitmentRequirement,
} from './recruitment-requirement';

// enum
export enum RecruitmentLevelType {
  PRESCHOOL = 'Mầm non',
  ELEMENTARY_SCHOOL = 'Tiểu học',
  MIDDLE_SCHOOL = 'Trung học cơ sở',
  HIGH_SCHOOL_GRADUATION = 'Trung học phổ thông',
  INTERMEDIATE = 'Trung cấp',
  COLLEGE = 'Cao đẳng',
  UNIVERSITY = 'Đại học',
  MASTER = 'Thạc sĩ',
  DOCTORATE = 'Tiến sĩ',
}

export enum RecruitmentWorkingFormType {
  FULL_TIME = 'Toàn thời gian',
  PART_TIME = 'Bán thời gian',
}

export enum RecruitmentStaffType {
  ACCOUNTANT = 'Nhân viên kế toán',
  CONSULTANT = 'Nhân viên tư vấn',
}

// --------------------------------------------------
// data
export const dataSelectRecruitmentLevelType = [
  {
    label: 'Mầm non',
    value: RecruitmentLevelType.PRESCHOOL,
  },
  {
    label: 'Tiểu học',
    value: RecruitmentLevelType.ELEMENTARY_SCHOOL,
  },
  {
    label: 'Trung học cơ sở',
    value: RecruitmentLevelType.MIDDLE_SCHOOL,
  },
  {
    label: 'Trung học phổ thông',
    value: RecruitmentLevelType.HIGH_SCHOOL_GRADUATION,
  },
  {
    label: 'Trung cấp',
    value: RecruitmentLevelType.INTERMEDIATE,
  },
  {
    label: 'Cao đẳng',
    value: RecruitmentLevelType.COLLEGE,
  },
  {
    label: 'Đại học',
    value: RecruitmentLevelType.UNIVERSITY,
  },
  {
    label: 'Thạc sĩ',
    value: RecruitmentLevelType.MASTER,
  },
  {
    label: 'Tiến sĩ',
    value: RecruitmentLevelType.DOCTORATE,
  },
];

// --------------------------------------------------
// form
export type CreateRecruitmentForm = {
  levelType: RecruitmentLevelType;
  salaryRange: string;
  workingFormType: RecruitmentWorkingFormType;
  experienceRequired: boolean;
  numberYearExperience: number;
  numberStaff: number;
  expiredAt: Date;
  recruitmentRequirements: CreateRecruitmentRequirementForm[];
  prefix: PrefixType.ACCOUNTANT | PrefixType.CONSULTANT
};

export type SearchRecruitmentForm = {
  workingFormTypes?: string;
  isCurrentlyRecruiting?: boolean;
  isEnded?: boolean;
  searchCharacter?: string;
} & Pagination;

// --------------------------------------------------
// data
export type Recruitment = {
  id: number;
  levelType: RecruitmentLevelType;
  salaryRange: string;
  workingFormType: RecruitmentWorkingFormType;
  experienceRequired: boolean;
  numberYearExperience: number;
  numberStaff: number;
  expiredAt: Date;
  staffType: RecruitmentStaffType;
  endAt: Date;
  code: string;
  numberApplication: number;
  numberHired: number;
  ownerId: number;
  recruitmentRequirements: RecruitmentRequirement[];
};

export type StaticRecruitmentResponse = {
  countRecruitmentWorkFullTime: number;
  countRecruitmentWorkPartTime: number;
  countRecruitmentActive: number;
  countRecruitmentExpired: number;
};
