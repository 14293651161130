import { useEffect, useState } from 'react';

export function useGetPrefixType() {
  const [prefixType, setPrefixType] = useState<string | null>(null);

  useEffect(() => {
    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split('/');
    if (pathSegments.length >= 2) {
      setPrefixType(pathSegments[1]);
    }
  }, []);

  return prefixType;
}
