import { createSlice } from '@reduxjs/toolkit';
import { IClassApplicationState } from 'src/@types/class-application-information';

const initialState: IClassApplicationState = {
  selectedClassApplicationInformationId: null,
};

const slice = createSlice({
  name: 'classApplicationInformation',
  initialState,
  reducers: {
    setSelectedClassApplicationInformationId: (state, action) => {
      state.selectedClassApplicationInformationId = action.payload;
    },
  },
});

//Reducer
export default slice.reducer;

//Actions
export const { setSelectedClassApplicationInformationId } = slice.actions;
