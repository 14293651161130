// routes
import { PATH_ADMIN } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  servicesPlumber: getIcon('services-plumber-icon'),
  archive: getIcon('archive-icon'),
  archiveLine: getIcon('archive-line-icon'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  book: getIcon('book-icon'),
  globe: getIcon('globe-icon'),
  publisher: getIcon('publisher-icon'),
  description: getIcon('description-icon'),
  pen: getIcon('pen-icon'),
  admin: getIcon('admin-icon'),
  bookTool: getIcon('book-tool-icon'),
  penTool1: getIcon('pen-tool-icon-1'),
  briefcaseIcon: getIcon('briefcase-icon'),
  serverSetting: getIcon('server-setting'),
  termPolicy: getIcon('term-policy'),
};

const navConfig = [
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Cấu hình',
    icon: ICONS.serverSetting,
    items: [
      // Industry
      {
        title: 'Ngành dạy và học',
        path: PATH_ADMIN.industry.root,
        icon: ICONS.briefcaseIcon,
      },
      // Location
      {
        title: 'Vị trí địa lý',
        path: PATH_ADMIN.location.root,
        icon: ICONS.globe,
      },
      // Term policy
      {
        title: 'Thỏa thuận sử dụng',
        path: PATH_ADMIN.termPolicy.root,
        icon: ICONS.termPolicy,
      },
    ],
  },
  // Danh sách,
  {
    subheader: 'Danh sách',
    icon: ICONS.description,
    items: [
      // Student
      {
        title: 'Admin',
        path: PATH_ADMIN.user.admin.root,
        icon: ICONS.admin,
      },
      // Teacher
      {
        title: 'Người dạy',
        path: PATH_ADMIN.user.teacher.root,
        icon: ICONS.book,
      },
      // Admin
      {
        title: 'Người học',
        path: PATH_ADMIN.user.student.root,
        icon: ICONS.pen,
      },
    ],
  },
];

export default navConfig;
