import { Container, Stack, Typography, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { m } from 'framer-motion';
import { ReactNode } from 'react';
import {
  WorkAttendance,
  WorkAttendanceStatusType,
} from 'src/@types/work-attendance';
import { imagePath } from 'src/assets';
import Image from 'src/components/Image';
import { MotionContainer, varBounce } from 'src/components/animate';
import { QUERY_KEYS } from 'src/constants/queryKey';
import useAuth from 'src/hooks/useAuth';

type Props = {
  children?: ReactNode;
  hasContent?: boolean;
};

export default function WorkAttendanceGuard({ children, hasContent }: Props) {
  const { isSignInByManager } = useAuth();

  const queryClient = useQueryClient();

  const theme = useTheme();

  const workAttendance = queryClient.getQueryData<WorkAttendance>([
    QUERY_KEYS.WORK_ATTENDANCE,
    QUERY_KEYS.WORKING_NOW,
  ]);

  if (isSignInByManager) {
    return <>{children}</>;
  }

  if (
    !workAttendance ||
    workAttendance.status !== WorkAttendanceStatusType.WORKING
  ) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <Stack mt={8}>
          <m.div variants={varBounce().in}>
            <Typography variant="h4" paragraph>
              Hiện tại bạn không thể truy cập được trang này
            </Typography>
          </m.div>
          <m.div variants={varBounce().in}>
            <Typography sx={{ color: 'text.secondary' }}>
              Bạn đang ngoài giờ làm việc hoặc chưa bấm nút bắt đầu làm
            </Typography>
          </m.div>
          <m.div
            variants={varBounce().in}
            style={{
              marginTop: theme.spacing(5),
            }}
          >
            <Image
              src={imagePath.forbidden}
              sx={{
                height: 260,
              }}
            />
          </m.div>
        </Stack>
      </Container>
    ) : null;
  }

  return <>{children}</>;
}
