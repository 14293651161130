import moment, { unitOfTime } from 'moment';
import 'moment-timezone';
import 'moment/locale/vi';

moment.updateLocale('Vi', {
  week: {
    dow: 1,
    doy: 1,
  },
  weekdaysShort: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
  weekdays: [
    'Chủ nhật',
    'Thứ hai',
    'Thứ ba',
    'Thứ tư',
    'Thứ năm',
    'Thứ sáu',
    'Thứ bảy',
  ],
});

export const dayOfWeekMap = {
  'Chủ nhật': 0,
  'Thứ hai': 1,
  'Thứ ba': 2,
  'Thứ tư': 3,
  'Thứ năm': 4,
  'Thứ sáu': 5,
  'Thứ bảy': 6,
};

// export const reverseDayOfWeekMap = Object.keys(dayOfWeekMap).reduce(
//   (acc, key) => {
//     acc[dayOfWeekMap[key]] = key;
//     return acc;
//   },
//   {} as { [key: number]: string },
// );

// Get age form dob
export const getAgeFromDOB = (dob: string | Date) => {
  const now = getDateNow();
  const birthDate = momentVi(dob).toDate();
  let age = now.getFullYear() - birthDate.getFullYear();
  const monthDifference = now.getMonth() - birthDate.getMonth();
  const dayDifference = now.getDate() - birthDate.getDate();

  // Check if the month or day is not your birthday this year then subtract 1 year
  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--;
  }

  return age;
};

export const getWeekDays = (date?: Date) => {
  const startOfWeek = momentVi(date).startOf('week');
  return Array.from({ length: 7 }).map((_, i) =>
    startOfWeek.clone().add(i, 'day').format('YYYY-MM-DD'),
  );
};

// Get day from date
export const getDayOfWeek = (date: Date) => {
  const daysOfWeek = [
    'Chủ Nhật',
    'Thứ Hai',
    'Thứ Ba',
    'Thứ Tư',
    'Thứ Năm',
    'Thứ Sáu',
    'Thứ Bảy',
  ];
  const dayIndex = date.getDay();
  return daysOfWeek[dayIndex];
};

// Get day from date

// get format date
export const formatDate = (date: Date | string | number, format: string) => {
  const dateConvert = momentVi(date).toDate();
  const formattedDate = momentVi(dateConvert).format(format);
  return formattedDate;
};

// get full days of week
export const getFullDaysOfWeek = (dateStartWeek: Date) => {
  const fullWeekDays: Date[] = [];
  const startDate = moment(dateStartWeek);
  for (let i = 0; i < 7; i++) {
    fullWeekDays.push(startDate.clone().add(i, 'days').toDate());
  }

  return fullWeekDays;
};

export const getDateNow = () => moment().toDate();

export const transformDateVN = (date: Date | string) => {
  // get new date
  let newDate = date;

  if (typeof date == 'string') {
    newDate = momentVi(date).toDate();
  }

  // return turn transform date
  return momentVi(newDate).toDate();
};

export const dateStrVN = (date: Date) => {
  // get new date
  let newDate = date;

  if (typeof date == 'string') {
    newDate = new Date(date);
  }

  const dateStr = `${newDate.getHours()}:${newDate.getMinutes()}:${newDate.getSeconds()} ${newDate.getDate()}-${
    newDate.getMonth() + 1
  }-${newDate.getFullYear()}`;

  // return turn transform date
  return dateStr;
};

export const getNextWeekDays = () => {
  const startOfNextWeek = moment().add(1, 'weeks').startOf('isoWeek');

  const nextWeekDays = [];
  for (let i = 0; i < 7; i++) {
    nextWeekDays.push(startOfNextWeek.clone().add(i, 'days').toDate());
  }

  return nextWeekDays;
};

export const getNextWeekDayNumbers = () => {
  const startOfNextWeek = moment().add(1, 'weeks').startOf('isoWeek');

  const nextWeekDays = [];
  for (let i = 0; i < 7; i++) {
    nextWeekDays.push(startOfNextWeek.clone().add(i, 'days').date());
  }

  return { nextWeekDays, month: startOfNextWeek.month() + 1 };
};

export const checkDateIsNextWeekDay = (date?: Date) => {
  const startOfNextWeek = moment().add(1, 'weeks').startOf('isoWeek').toDate();
  const endOfNextWeek = moment().add(1, 'weeks').endOf('isoWeek').toDate();

  const isInNextWeek =
    startOfNextWeek <= moment(date).toDate() &&
    moment(date).toDate() <= endOfNextWeek;

  return isInNextWeek;
};

export const getTimeDiffFromCurrent = (
  deadline: Date | string | number,
  unitOfTime: unitOfTime.Diff,
) => {
  const momentDeadline = moment(deadline);
  const timeRemaining = momentDeadline.diff(moment(), unitOfTime);
  return timeRemaining;
};

export const getTextTimeFullUnitOfTimeRemain = (
  deadline?: Date | string | number,
) => {
  if (!deadline) return '';
  const durationRemaining = momentVi.duration(
    momentVi(deadline).diff(getDateNow()),
  );

  // get remaining day
  const remainingDay = durationRemaining.days();
  const remainingDayConvert = remainingDay <= 0 ? 0 : remainingDay;

  // get remaining hours
  const remainingHour = durationRemaining.hours();
  const remainingHourConvert = remainingHour <= 0 ? 0 : remainingHour;

  // get remaining minutes
  const remainingMinute = durationRemaining.minutes();
  const remainingMinuteConvert = remainingMinute <= 0 ? 0 : remainingMinute;

  if (!remainingDayConvert && !remainingHourConvert && !remainingMinuteConvert)
    return '0 ngày';

  let TextTimeRemaining = '';

  if (remainingDayConvert) TextTimeRemaining += `${remainingDayConvert} ngày`;
  if (remainingHourConvert) TextTimeRemaining += ` ${remainingHourConvert} giờ`;
  if (remainingMinuteConvert)
    TextTimeRemaining += ` ${remainingMinuteConvert} phút`;

  return TextTimeRemaining.trim();
};

export const getTimeDiff = (
  startDate: Date | string | number,
  deadlineDate: Date | string | number,
  unitOfTime: unitOfTime.Diff,
) => {
  const momentStartDate = moment(startDate);
  const momentDeadlineDate = moment(deadlineDate);
  const timeRemaining = momentDeadlineDate.diff(momentStartDate, unitOfTime);
  return timeRemaining;
};

export const checkExpired = (expirationDate: Date | string) => {
  const momentExpirationDate = moment(expirationDate);

  const today = moment();

  return today.isAfter(momentExpirationDate);
};

export function generateDateArray({
  numberOfDays,
  isExceptCurrentDate,
  daysGet,
  dayStartWeekDate,
}: {
  numberOfDays: number;
  isExceptCurrentDate: boolean;
  daysGet: number[];
  dayStartWeekDate: number;
}) {
  let currentDate = getDateNow();
  const dateArray: Date[] = [];
  const startWeekDateArray: Date[] = [];

  if (isExceptCurrentDate) {
    currentDate = momentVi(currentDate).add(1, 'days').toDate();
  }

  for (let i = 0; i < numberOfDays; i++) {
    // -----------------------------------------
    // check day should get
    const currentDay = currentDate.getDay();

    if (daysGet.some((day) => day === currentDay)) {
      dateArray.push(currentDate);
    }

    // -----------------------------------------
    // get start date week
    const newDateCurrentCheckStartDateWeek = momentVi(currentDate).toDate();
    if (newDateCurrentCheckStartDateWeek.getDay() !== dayStartWeekDate) {
      newDateCurrentCheckStartDateWeek.setDate(
        newDateCurrentCheckStartDateWeek.getDate() +
          (dayStartWeekDate - currentDay),
      ); // go to date start week
    }

    if (
      startWeekDateArray.length === 0 ||
      newDateCurrentCheckStartDateWeek >
        startWeekDateArray[startWeekDateArray.length - 1]
    ) {
      startWeekDateArray.push(newDateCurrentCheckStartDateWeek);
    }

    // -----------------------------------------
    // next date
    currentDate = momentVi(currentDate).add(1, 'days').toDate();
  }

  return {
    dateArray,
    startWeekDateArray,
  };
}

export const formattedTimeString = (time: number) => {
  const timeString = time < 10 ? '0' + time : time.toString();
  return timeString;
};

export const momentVi = moment;
