import jwtDecode from 'jwt-decode';
// routes
import { PATH_ACCOUNTANT, PATH_ADMIN, PATH_CONSULTANT } from '../routes/paths';
//
import axios from './axios';
import { TokenType } from 'src/@types/auth';
import { UserType } from 'src/@types/user';

// ----------------------------------------------------------------------

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const getUserType = (accessToken: string) => {
  if (!accessToken) {
    return null;
  }
  const decoded = jwtDecode<{ userType: UserType }>(accessToken);

  const { userType } = decoded;

  return userType;
};

const getUserId = (accessToken: string) => {
  if (!accessToken) return null;

  const decoded = jwtDecode<{ id: number }>(accessToken);

  const { id } = decoded;

  return id;
};

const handleTokenExpired = (exp: number, accessTokenType: TokenType) => {
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    localStorage.removeItem(accessTokenType);

    if (accessTokenType === TokenType.ADMIN_ACCESS_TOKEN) {
      window.location.href = PATH_ADMIN.auth.login;
    }
    if (accessTokenType === TokenType.CONSULTANT_ACCESS_TOKEN) {
      window.location.href = PATH_CONSULTANT.auth.login;
    }
    if (accessTokenType === TokenType.ACCOUNTANT_ACCESS_TOKEN) {
      window.location.href = PATH_ACCOUNTANT.auth.login;
    }
  }, timeLeft);
};

const setSession = (
  accessToken: string | null,
  accessTokenType?: TokenType,
) => {
  if (accessTokenType) {
    if (accessToken) {
      localStorage.setItem(accessTokenType, accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

      // This function below will handle when token is expired
      const { exp } = jwtDecode<{ exp: number }>(accessToken); // ~3 days by minimals server
      handleTokenExpired(exp, accessTokenType);
    } else {
      localStorage.removeItem(accessTokenType);
      delete axios.defaults.headers.common.Authorization;
    }
  } else {
    localStorage.removeItem(TokenType.ADMIN_ACCESS_TOKEN);
    localStorage.removeItem(TokenType.CONSULTANT_ACCESS_TOKEN);
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession, getUserType, getUserId };
