import { Box, Stack, StackProps, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useSnackbar } from 'notistack';
import { ChangeEvent } from 'react';
import IconifyIcon from 'src/components/icon';
import useResponsive from 'src/hooks/useResponsive';

interface ContactUploadImgProps extends StackProps {
  handleUploadImg: (file: File[]) => void;
  disabled?: boolean;
}

export default function ContactUploadImg({
  handleUploadImg,
  disabled,
  ...other
}: ContactUploadImgProps) {
  const smDown = useResponsive('down', 'sm');
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const acceptFileExtensions = ['jpg', 'jpeg', 'png'];

  const onChangeImage = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Object.values(event.target.files);
      let isChecked = true;

      // Check size image
      const MAX_FILE_SIZE = 5120 * 3; // 5MB
      let isMoreThanMaxSize = false;
      for (const file of Object.values(files)) {
        const size = file.size / 1024;
        if (size > MAX_FILE_SIZE) {
          isMoreThanMaxSize = !isMoreThanMaxSize;
          break;
        }
      }

      if (isMoreThanMaxSize) {
        enqueueSnackbar('Vui lòng lựa chọn hình ảnh có kích cỡ nhỏ hơn 5MB', {
          variant: 'warning',
        });
        isChecked = !isChecked;
      }

      // Check type file
      for (const file of files) {
        const fileExtension = file.name
          .substring(file.name.lastIndexOf('.') + 1)
          .toLowerCase();
        if (!acceptFileExtensions.includes(fileExtension)) {
          enqueueSnackbar(
            `Vui lòng lựa chọn hình ảnh có dạng ${acceptFileExtensions.join()}`,
            {
              variant: 'warning',
            },
          );
          isChecked = !isChecked;
          break;
        }
      }
      if (isChecked) {
        handleUploadImg(Object.values(files));
      }
    }
  };

  return (
    <Box>
      <input
        accept="image/*"
        type={'file'}
        onChange={onChangeImage}
        id={'upload-img-conversation'}
        style={{
          display: 'none',
        }}
        multiple
      />
      <label htmlFor={disabled ? 'block' : 'upload-img-conversation'}>
        <Stack
          style={{
            width: smDown ? 35 : 40,
            height: smDown ? 35 : 40,
            cursor: 'pointer',
          }}
          alignItems={'center'}
          justifyContent={'center'}
          {...other}
        >
          <IconifyIcon
            icon={'material-symbols:imagesmode-rounded'}
            color={disabled ? grey[400] : theme.palette.primary.main}
          />
        </Stack>
      </label>
    </Box>
  );
}
