import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Collapse, List } from '@mui/material';
//
import { getActive } from '..';
import { NavListProps } from '../type';
import NavItem from './NavItem';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import {
  WorkAttendance,
  WorkAttendanceStatusType,
} from 'src/@types/work-attendance';
import { QUERY_KEYS } from 'src/constants/queryKey';
import { AccountantRoleType } from 'src/@types/user';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

type NavListRootProps = {
  data: NavListProps;
  depth: number;
  hasChildren: boolean;
  isCollapse?: boolean;
};

export default function NavList({
  data,
  depth,
  hasChildren,
  isCollapse = false,
}: NavListRootProps) {
  const { isSignInByManager } = useAuth();

  const navigate = useNavigate();

  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const workAttendance = queryClient.getQueryData<WorkAttendance>([
    QUERY_KEYS.WORK_ATTENDANCE,
    QUERY_KEYS.WORKING_NOW,
  ]);

  const active = getActive(data.path, pathname);

  const [open, setOpen] = useState(active);

  const handleClickItem = (event: any) => {
    event.preventDefault();

    if (isSignInByManager) return navigate(data.path);
    if (data.isOutWorkTime) return navigate(data.path);
    if (data.roles?.includes(AccountantRoleType.MANAGER))
      return navigate(data.path);

    if (
      !workAttendance ||
      workAttendance.status !== WorkAttendanceStatusType.WORKING
    ) {
      return enqueueSnackbar(
        'Bạn đang ngoài giờ làm việc hoặc chưa bấm nút bắt đầu làm!',
        {
          variant: 'warning',
        },
      );
    }

    if (!hasChildren) {
      navigate(data.path);
    }
    setOpen(!open);
  };

  return (
    <>
      <NavItem
        item={data}
        depth={depth}
        open={open}
        active={active}
        isCollapse={isCollapse}
        onClick={handleClickItem}
      />

      {!isCollapse && hasChildren && (
        <Collapse in={open} unmountOnExit>
          <List component="div" disablePadding>
            <NavSubList data={data.children} depth={depth} />
          </List>
        </Collapse>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

type NavListSubProps = {
  data: NavListProps[];
  depth: number;
};

function NavSubList({ data, depth }: NavListSubProps) {
  return (
    <>
      {data.map((list) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={depth + 1}
          hasChildren={!!list.children}
        />
      ))}
    </>
  );
}
