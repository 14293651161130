import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IUserState, User } from 'src/@types/user';

const initialState: IUserState = {
  user: null,
};

const slice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
  },
});

export default slice.reducer;

export const { setUser } = slice.actions;
