import { FileData } from './file';
import { Transaction } from './transaction';
import { UndefinedTransaction } from './undefined-transaction';
import { User, UserType } from './user';

//----------------------------------------------------------------
export interface ITransactionSupportState {
  selectedTransactionSupportRequestId: number | null;
  depositSupportRequestVerification: {
    mode: 'VIEW' | 'EDIT';
    form: {
      error: {
        undefinedTransaction: any;
      };
    };
    dialog: {
      toggle: boolean;
      undefinedTransactionCode: string;
    };
    filter: {
      searchCharacter: string;
      userType: UserType;
      transactionSupportRequestType: TransactionSupportRequestTypeType;
    };
    isShowByUndefinedTransaction: boolean;
  };
  withdrawSupportRequestVerification: {
    mode: 'VIEW' | 'EDIT';
    userType: UserType;
    dialog: {
      toggle: boolean;
    };
    confirmForm: {
      isDisabled: boolean;
    };
    filter: {
      searchCharacter: string;
      userType: UserType;
      transactionSupportRequestType: TransactionSupportRequestTypeType;
    };
    isLoading: boolean;
    isShowByUndefinedTransaction: boolean;
  };
}

//----------------------------------------------------------------
export enum TransactionSupportStatusType {
  ACCEPTED = 'Chấp nhận',
  REJECTED = 'Từ chối',
}

export enum TransactionSupportRequestStatusType {
  PENDING = 'Đang chờ xử lý',
  COMPLETED = 'Hoàn thành',
  IN_PROCESS = 'Đang trong quá trình xử lý',
  CANCELED = 'Hủy bỏ',
  FAILED = 'Thất bại',
}

export enum TransactionSupportRequestTypeType {
  DEPOSIT_SUPPORT = 'Hỗ trợ nạp tiền',
  WITHDRAW_SUPPORT = 'Hỗ trợ rút tiền',
}

export enum TransactionSupportRequestSearchType {
  DEPOSIT = 'Nạp tiền',
  WITHDRAW = 'Rút tiền',
}

export enum TransactionSupportRequestCaseType {
  NOT_TRANSFERRED_YET = 'Chưa thực hiện chuyển',
  BANK_REFUND = 'Ngân hàng hoàn trả',
}

//----------------------------------------------------------------

export type TransactionSupport = {
  id: number;
  status: TransactionSupportStatusType;
  supportById: number;
  supportBy: User | null;
  transactionSupportRequestId: number;
  transactionSupportRequest: TransactionSupportRequest | null;
  createdAt: Date | string | number;
};

export type TransactionSupportRequest = {
  id: number;
  type: TransactionSupportRequestTypeType;
  description: string | null;
  status: TransactionSupportRequestStatusType;
  supportCount: number;
  version: number;
  transactionId: number | null;
  transaction: Transaction | null;
  supportRequestImageId: number | null;
  supportRequestImage: FileData | null;
  ownerId: number;
  owner: User | null;
  transactionSupport: TransactionSupport | null;
  undefinedTransactionId: number | null;
  undefinedTransaction: UndefinedTransaction | null;
  createdAt: Date | string | number;
  updatedAt: Date | string | number;
  supportCountByTransaction: number;
};

//----------------------------------------------------------------
//Form
export type VerifyDepositTransactionSupportForm = {
  id: number;
  version: number;
  userType: UserType;
  amount: number;
  undefinedTransactionCode: string;
  isConfirmed: boolean;
};

export type RejectDepositTransactionSupportForm = {
  id: number;
  version: number;
  ownerId: number;
  isConfirmed: boolean;
};

export type VerifyWithdrawTransactionSupportForm = {
  id: number;
  version: number;
  userType: UserType;
  caseType: TransactionSupportRequestCaseType;
  undefinedTransactionCode: string;
  isConfirmed: boolean;
};

//----------------------------------------------------------------
//Service
export type OverviewTaskHistoryRequest = {
  month?: number;
  year?: number;
  type: TransactionSupportRequestTypeType;
};

export type OverviewTaskHistoryResponse = {
  month: number;
  year: number;
  teacherCount: number;
  studentCount: number;
};

export type SearchTaskHistoryRequest = {
  searchCharacter?: string;
  month?: number;
  year?: number;
  userType?: UserType;
  statuses?: TransactionSupportRequestStatusType[];
  type: TransactionSupportRequestTypeType;
};

export type SearchTaskHistoryResponse = {
  id: number;
  updatedAt: Date;
  status: TransactionSupportRequestStatusType;
  description: string;
  ownerStudentCode?: string;
  ownerTeacherCode?: string;
  ownerName: string;
};

export type OverviewRequest = {
  type: TransactionSupportRequestTypeType;
};

export type OverviewResponse = {
  teacherTransactionSupReqCount: number;
  studentTransactionSupReqCount: number;
};

export type SearchTransactionSupportRequestRequest = {
  userType: UserType;
  searchCharacter?: string;
  transactionSupportRequestType: TransactionSupportRequestTypeType;
  limit?: number;
  page: number;
};

export type CheckTransactionSupportRequestRequest = {
  id: number;
  type: TransactionSupportRequestTypeType;
};

export type FindOneTransactionSupportRequestRequest = {
  id: number;
  type: TransactionSupportRequestTypeType;
  statuses: TransactionSupportRequestStatusType[];
};

export type FindOneTransactionSupportRequestByUndefinedTransactionIdService = {
  undefinedTransactionId: number;
  type: TransactionSupportRequestTypeType;
  statuses: TransactionSupportRequestStatusType[];
};

export type VerifyDepositTransactionSupportRequestRequest = {
  id: number;
  version: number;
  userType: UserType;
  amount: number;
  undefinedTransactionCode: string;
};

export type RejectDepositTransactionSupportRequestRequest = {
  id: number;
  version: number;
  ownerId: number;
};

export type VerifyWithdrawTransactionSupportRequestRequest = {
  id: number;
  version: number;
  userType: UserType;
  caseType: TransactionSupportRequestCaseType;
  undefinedTransactionCode?: string;
};

export type RejectWithdrawSupportRequestRequest = {
  id: number;
  version: number;
  ownerId: number;
};
//----------------------------------------------------------------
//Hook
export type TransactionSupportRequestSearchInfinityFilter = {
  searchCharacter?: string;
  userType: UserType;
  transactionSupportRequestType: TransactionSupportRequestTypeType;
};
