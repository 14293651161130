// ** Icon Imports
import { Icon, IconProps } from '@iconify/react';
import { CSSProperties, useState } from 'react';
import { ReactSVG } from 'react-svg';

export interface IconSVGProps {
  css?: CSSProperties;
  path: string;
  strokeWidth?: number;
  colorHover?: string;
  handle?: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const IconSVG = ({ css, path, colorHover, handle }: IconSVGProps) => {
  const [isHover, setIsHover] = useState(false);

  //  return component to handle svg
  return (
    <ReactSVG
      onClick={handle ? handle : () => {}}
      onMouseMove={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      src={path}
      beforeInjection={(svg) => {
        svg.setAttribute('width', css?.width ? `${css.width}` : '20px');
        svg.setAttribute('height', css?.height ? `${css.height}` : '20px');

        const elementsWithStroke = svg.querySelectorAll('[stroke]');
        elementsWithStroke.forEach((element) => {
          element.setAttribute(
            'stroke',
            isHover && colorHover
              ? colorHover
              : css?.color
                ? `${css.color}`
                : 'currentColor',
          );
        });

        const elementsWithFill = svg.querySelectorAll('[fill]');
        elementsWithFill.forEach((element) => {
          element.setAttribute(
            'fill',
            isHover && colorHover
              ? colorHover
              : css?.color
                ? `${css.color}`
                : 'currentColor',
          );
        });
      }}
      style={{ ...css, cursor: handle ? 'pointer' : undefined }}
    />
  );
};

const IconifyIcon = ({ icon, ...rest }: IconProps) => <Icon icon={icon} fontSize="1.5rem" {...rest} />;

export default IconifyIcon;
