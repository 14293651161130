// ----------------------------------------------------------------------

// const breakpoints = {
//   values: {
//     xs: 0,
//     sm: 600,
//     md: 900,
//     lg: 1200,
//     xl: 1536,
//   },
// };
const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1350,
    xl: 1536,
  },
};

export default breakpoints;
