import { useMutation } from '@tanstack/react-query';
import { CallServiceHook } from 'src/@types/hook';
import { createRecordConversation } from 'src/services/consultant/conversation.consultant.service';

export const useCreateRecord = ({ onSuccess, onError }: CallServiceHook) => ({
  ...useMutation({
    mutationFn: createRecordConversation,
    onSuccess,
    onError,
  }),
});
