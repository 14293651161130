import { createSlice } from '@reduxjs/toolkit';
import { IndustryState } from 'src/@types/industry';

const initialState: IndustryState = {
  industries: [],
  nextPage: 1,
  industry: null,
};

const slice = createSlice({
  name: 'industry',
  initialState,
  reducers: {
    //Get industries
    getIndustriesSuccess(state, action) {
      state.industries = action.payload;
      state.nextPage += 1;
    },

    //Get industry
    getIndustrySuccess(state, action) {
      state.industry = action.payload;
    },
  },
});

//Reducer
export default slice.reducer;

//Action
export const { getIndustriesSuccess, getIndustrySuccess } = slice.actions;
