import { skipToken, useQuery } from '@tanstack/react-query';
import { WorkingNowRequest } from 'src/@types/work-attendance';
import { PrefixType } from 'src/constants/prefix';
import { QUERY_KEYS } from 'src/constants/queryKey';
import { workingNow } from 'src/services/common/work-attendance.common.service';

export function useWorkingNow(
  request: WorkingNowRequest,
  prefixType: PrefixType,
) {
  const { fopId } = request;

  return {
    ...useQuery({
      queryKey: [QUERY_KEYS.WORK_ATTENDANCE, QUERY_KEYS.WORKING_NOW],
      queryFn: fopId ? () => workingNow(request, prefixType) : skipToken,
    }),
  };
}
