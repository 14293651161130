import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ICVRequestState } from 'src/@types/cv-request';

const initialState: ICVRequestState = {
  selectedCVRequestId: null,
};

const slice = createSlice({
  name: 'cvRequest',
  initialState,
  reducers: {
    setSelectedCVRequestId: (state, action: PayloadAction<number | null>) => {
      state.selectedCVRequestId = action.payload;
    },
  },
});

//Reducer
export default slice.reducer;

//Actions

export const { setSelectedCVRequestId } = slice.actions;
