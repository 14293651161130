import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ICVState, VerificationMode } from 'src/@types/cv';

const initialState: ICVState = {
  selectedCVId: null,
  verification: {
    mode: 'VERIFY',
    verifyDialog: {
      toggle: false,
    },
    isShowByCVRequest: false,
  },
};

const slice = createSlice({
  name: 'cv',
  initialState,
  reducers: {
    setSelectedCVId: (state, action: PayloadAction<number>) => {
      state.selectedCVId = action.payload;
    },
    setVerifyDialogToggle: (state, action: PayloadAction<boolean>) => {
      state.verification.verifyDialog.toggle = action.payload;
    },
    setVerificationMode: (state, action: PayloadAction<VerificationMode>) => {
      state.verification.mode = action.payload;
    },
    openWithCVId: (state, action: PayloadAction<number>) => {
      state.selectedCVId = action.payload;
      state.verification.verifyDialog.toggle = true;
      state.verification.mode = 'VERIFY';
    },
    openWithCVRequestId: (state) => {
      state.verification.isShowByCVRequest = true;
      state.verification.verifyDialog.toggle = true;
      state.verification.mode = 'VIEW';
    },
    resetVerification: (state) => {
      state.selectedCVId = null;
      state.verification = initialState.verification;
    },
  },
});

//Reducer
export default slice.reducer;

//Actions

export const {
  resetVerification,
  setSelectedCVId,
  setVerificationMode,
  setVerifyDialogToggle,
  openWithCVId,
  openWithCVRequestId,
} = slice.actions;
