import { useDaily } from '@daily-co/daily-react';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { FileType } from 'src/@types/file';
import { useCreateRecord } from 'src/hooks/accountant/conversation/useRecord.conversation';
import { momentVi } from 'src/utils/date-handle';
import { s3UploadFile } from 'src/utils/s3-handle-file';

interface VoiceCallProps {
  roomUrl?: string;
  conversationId?: number;
}

export default function VoiceCall({ roomUrl, conversationId }: VoiceCallProps) {
  const audioContainerRef = useRef<any>(null);
  const daily = useDaily();
  const { enqueueSnackbar } = useSnackbar();
  const ffmpeg = new FFmpeg();
  const [conversationIdState, setConversationIdState] = useState<
    number | undefined
  >(undefined);

  const { mutate: createRecordMutate } = useCreateRecord({
    onSuccess: () => {
      enqueueSnackbar('Lưu bản ghi âm thành công');
    },
    onError: () => {
      enqueueSnackbar('Lưu bản ghi âm thất bại', {
        variant: 'error',
      });
    },
  });

  useEffect(() => {
    if (conversationId) {
      setConversationIdState(conversationId);
    }
  }, [conversationId]);

  const convertAndUpload = async (recordedChunks: any) => {
    await ffmpeg.load();

    const time = momentVi().format('HH:mm DD-MM-YYYY');
    const bucketName = 'user.audio';
    const nameFile = `record-${time}.mp3`;

    const blob = new Blob(recordedChunks, { type: 'audio/webm' });
    const arrayBuffer = await blob.arrayBuffer();
    ffmpeg.writeFile('input.webm', new Uint8Array(arrayBuffer));

    await ffmpeg.exec([
      '-i',
      'input.webm',
      '-b:a',
      '64k',
      '-ar',
      '48000',
      '-ac',
      '1',
      'output.mp3',
    ]);

    const output: any = await ffmpeg.readFile('output.mp3');
    const outputBlob = new Blob([output.buffer], { type: 'audio/mp3' });

    const file = new File([outputBlob], nameFile, {
      type: 'audio/mp3',
      lastModified: new Date().getTime(),
    });

    await s3UploadFile({
      bucket: bucketName,
      file: file,
      fileName: nameFile,
      typeFile: FileType.AUDIO,
      isPublic: false,
    })
      .then(() => {
        console.log('Upload successful');
        if (conversationIdState) {
          createRecordMutate({
            bucketName,
            conversationId: conversationIdState,
            nameFile,
          });
        }
      })
      .catch((error) => {
        console.error('Upload failed:', error);
      });
  };

  useEffect(() => {
    let recorder: MediaRecorder | null = null;
    if (!daily) return;
    const startRecording = () => {
      let recordedChunks: Blob[] = [];
      if (daily && daily.participants()) {
        const audioContext = new AudioContext();
        const destination = audioContext.createMediaStreamDestination();

        // Combine audio tracks of all participants into one stream
        Object.values(daily.participants()).forEach((participant: any) => {
          if (
            participant.audioTrack &&
            participant.tracks.audio.state === 'playable'
          ) {
            const source = audioContext.createMediaStreamSource(
              new MediaStream([participant.audioTrack]),
            );
            source.connect(destination);
          }
        });

        const combinedStream = destination.stream;

        // Create MediaRecorder to record the combined audio stream
        const newRecorder = new MediaRecorder(combinedStream);
        newRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            console.log('Data available:', event.data.size);
            recordedChunks = [...recordedChunks, event.data];
          } else {
            console.log('No data available');
          }
        };
        newRecorder.onstop = async () => {
          console.log('Recorder stopped');
          if (recordedChunks.length > 0) {
            console.log(
              'Recorded chunks found, creating blob and uploading to S3',
            );
            await convertAndUpload(recordedChunks);
          } else {
            console.log('No recorded chunks to process');
          }
        };
        newRecorder.start();
        recorder = newRecorder;
        console.log('Recorder started');
      }
    };

    if (roomUrl) {
      // Join the call with only audio
      daily.join({ url: roomUrl, audioSource: true, videoSource: false });

      daily.on('joined-meeting', () => {
        console.log('Joined meeting');
      });

      daily.on('participant-updated', (event) => {
        const { participant } = event;
        if (participant.local) return;
        if (
          participant.audioTrack &&
          participant.tracks.audio.state === 'playable'
        ) {
          startRecording();
          const audioElement = document.createElement('audio');
          audioElement.srcObject = new MediaStream([participant.audioTrack]);
          audioElement.autoplay = true;
          audioElement.play();
          audioContainerRef.current.appendChild(audioElement);
        }
      });
    }

    return () => {
      daily.leave();
      console.log('Left meeting');
      try {
        recorder?.stop();
      } catch (error) {
        console.log('record error')
      }
      recorder = null;
      console.log('Recorder stopped');

      if (audioContainerRef.current) {
        while (audioContainerRef.current.firstChild) {
          audioContainerRef.current.removeChild(
            audioContainerRef.current.firstChild,
          );
        }
      }
    };
  }, [daily, roomUrl, conversationIdState]);

  return <div ref={audioContainerRef} />;
}
