import { TransactionReportTypeType } from 'src/@types/transaction-report';
import {
  closeCreateDialog,
  setCreateDialog,
  setCreateMode,
  setCreateType,
  setSelectedTransactionReportId,
} from 'src/redux/slices/transaction-report.slice';
import { useDispatch, useSelector } from 'src/redux/store';

export function useCreateDialog() {
  const toggle = useSelector(
    (state) => state.transactionReport.create.withdraw.dialog.toggle,
  );

  const dispatch = useDispatch();

  const open = ({ type }: { type: TransactionReportTypeType }) => {
    dispatch(
      setCreateDialog({
        createDialog: {
          toggle: true,
        },
        createType: 'withdraw',
      }),
    );
    dispatch(
      setCreateType({
        createType: 'withdraw',
        type,
      }),
    );
  };

  const openWithId = (id: number) => {
    dispatch(
      setCreateMode({
        createType: 'withdraw',
        mode: 'VIEW',
      }),
    );
    dispatch(
      setCreateDialog({
        createDialog: {
          toggle: true,
        },
        createType: 'withdraw',
      }),
    );
    dispatch(setSelectedTransactionReportId(id));
  };

  const close = () => {
    dispatch(setSelectedTransactionReportId(null));
    dispatch(
      closeCreateDialog({
        createType: 'withdraw',
      }),
    );
  };

  return { toggle, open, close, openWithId };
}
