// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar, { Props as AvatarProps } from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useAuth();

  return (
    <Avatar
      src={user?.profile?.avatarUrl}
      alt={user?.profile?.fullName}
      {...other}
      style={{
        background: createAvatar(user?.profile?.fullName).color,
        color: 'white',
        ...other.style
      }}
  >
      {createAvatar(user?.profile?.fullName).name}
    </Avatar>
    
  );
}
