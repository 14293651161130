// @mui
import { styled } from '@mui/material/styles';
// hooks
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import AuthTutorialCarousel from './tutorial/AuthTutorialCarousel';

//----------------------------------------------------
const MainStyle = styled('main')(({ theme }) => ({
  flexGrow: 1,
}));

const imagePaths = [
  '/assets/auth/tutorial.svg',
  '/assets/auth/tutorial(1).svg',
  '/assets/auth/tutorial(2).svg',
];

export default function AuthLayout() {
  return (
    <>
      <MainStyle sx={{ display: 'flex', minWidth: 1024 }}>
        <AuthTutorialCarousel imagePaths={imagePaths} />
        <Box flexGrow={1}>
          <Outlet />
        </Box>
      </MainStyle>
    </>
  );
}
