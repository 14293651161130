import { Box, Stack, useTheme } from '@mui/material';
import { SlideshowLightbox } from 'lightbox.js-react';
import useToggle from 'src/hooks/useToggle';
import { IconSVG } from './IconSVG';
import { customIcon } from 'src/assets';

export type ImageZoomNSlideshowLightBox = {
  src?: string;
  alt?: string;
};

type Props = {
  images: ImageZoomNSlideshowLightBox[] | null;
};

export default function ZoomNSlideshowLightBox({ images }: Props) {
  const {
    toggle: toggleLightBox,
    onOpen: onOpenLightBox,
    onClose: onCloseLightBox,
  } = useToggle();
  const theme = useTheme();

  return (
    <Stack
      sx={{
        position: 'absolute',
      }}
    >
      <Box
        style={{
          cursor: 'pointer',
        }}
        bgcolor={''}
        position={'absolute'}
        top={10}
        right={10}
        borderRadius={1}
        onClick={onOpenLightBox}
      >
        <IconSVG
          path={customIcon.zoomImage}
          css={{
            width: '12px',
            height: '12px',
            color: theme.palette.primary.main,
          }}
        />
      </Box>
      {/* show image light box */}
      <SlideshowLightbox
        showAllImages={false}
        showThumbnails
        showThumbnailIcon
        onClose={onCloseLightBox}
        open={toggleLightBox}
        images={images}
      />
    </Stack>
  );
}
