import {
  Box,
  CircularProgress,
  Stack,
  Typography,
  TypographyProps,
  styled,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import CountDown from 'react-countdown';
import {
  ConversationStatus,
  ConversationType,
  ConversationTypeType,
} from 'src/@types/conversation';
import { formatDate, getDateNow, momentVi } from 'src/utils/date-handle';
import MyAvatar from '../MyAvatar';

interface ConversationProps {
  conversation: ConversationType;
  handleCountDown: () => void;
  handleClick: () => void;
}

const Description = styled(Typography)(() => ({
  '&': {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
    overflow: 'hidden',
  },
}));

const StatusConversation = ({
  status = '',
  ...other
}: {
  status?: ConversationStatus | string;
} & TypographyProps) => {
  switch (status) {
    case ConversationStatus.NOT_PROCESSED_YET:
      return (
        <Typography color={'red'} {...other}>
          {status}
        </Typography>
      );
    case ConversationStatus.RECEIVED:
      return (
        <Typography color={'#FFBD00'} {...other}>
          {status}
        </Typography>
      );
    case ConversationStatus.PROCESSED:
      return (
        <Typography color={'green'} {...other}>
          {status}
        </Typography>
      );
    default:
      return null;
  }
};

function Conversation({
  conversation,
  handleCountDown,
  handleClick,
}: ConversationProps) {
  const theme = useTheme();
  const { black } = theme.palette.common;

  const [isClick, setIsClick] = useState(false);

  const timeRemaining =
    60000 -
    (getDateNow().getTime() -
      momentVi(conversation.createdAt).toDate().getTime());
  const isMoreThanSixtyOrEqual = !!(timeRemaining <= 0);

  const status =
    conversation.startTime === null && conversation.endTime
      ? ConversationStatus.NOT_PROCESSED_YET
      : conversation.endTime === null && conversation.startTime
        ? ConversationStatus.RECEIVED
        : conversation.endTime && conversation.startTime
          ? ConversationStatus.PROCESSED
          : '';

  useEffect(() => {
    if (conversation.startTime && isClick) {
      setIsClick(false);
    }
  }, [conversation]);

  return (
    <Box position={'relative'}>
      <Stack
        minHeight={80}
        py={1}
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        bgcolor={!status ? `${theme.palette.primary.lighter}` : 'white'}
        style={{
          cursor: 'pointer',
        }}
        px={3}
        onClick={
          !isMoreThanSixtyOrEqual && status !== ConversationStatus.PROCESSED
            ? () => {
                setIsClick(true);
                handleClick();
              }
            : undefined
        }
      >
        <Stack
          position={'relative'}
          spacing={2}
          direction={'row'}
          paddingRight={2}
          flex={1}
          overflow={'hidden'}
          minHeight={80}
        >
          <Box mt={'7px !important'} position={'relative'}>
            <MyAvatar
              src={conversation.owner?.profile.avatar?.url || ''}
              alt={conversation.owner?.profile.fullName}
            >
              {conversation.owner?.profile.fullName}
            </MyAvatar>

            {!isMoreThanSixtyOrEqual &&
              !conversation.startTime &&
              !conversation.endTime && (
                <Box
                  fontSize={10}
                  textAlign={'center'}
                  lineHeight={'20px'}
                  borderRadius={10}
                  position={'absolute'}
                  top={-7}
                  height={20}
                  width={20}
                  bgcolor={'#FFBD00'}
                  right={-3}
                >
                  <CountDown
                    date={getDateNow().getTime() + timeRemaining}
                    renderer={({ total }) => <span>{total / 1000}</span>}
                    onComplete={handleCountDown}
                  />
                </Box>
              )}
          </Box>

          <Box width={'84%'}>
            <Stack direction={'row'} spacing={1}>
              <Typography
                maxWidth={!status ? '90%' : '50%'}
                overflow={'hidden'}
                textOverflow={'ellipsis'}
                whiteSpace={'nowrap'}
                fontWeight={700}
                variant="subtitle2"
                color={black}
                noWrap
              >
                {conversation.owner?.profile.fullName}
              </Typography>
              <StatusConversation
                status={status}
                display={'inline-block'}
                variant="subtitle2"
                fontWeight={500}
              />
            </Stack>
            <Description fontWeight={500} variant="subtitle2" color={black}>
              {conversation.type === ConversationTypeType.CONSULTANT_AND_STUDENT
                ? 'NH: '
                : 'ND: '}
              {conversation.messages[0].content}
            </Description>
          </Box>
        </Stack>
        <Typography whiteSpace={'nowrap'} variant="caption" color={grey[500]}>
          {formatDate(conversation.createdAt, 'HH:mm')} Hôm nay
        </Typography>
      </Stack>
      {isClick && (
        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          position={'absolute'}
          top={0}
          left={0}
          width={'100%'}
          height={'100%'}
          bgcolor={'rgba(1,1,1,0.2)'}
        >
          <CircularProgress size={40} color="primary" />
        </Stack>
      )}
    </Box>
  );
}

export default Conversation;
