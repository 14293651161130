import { UndefinedTransaction } from 'src/@types/undefined-transaction';
import {
  resetDeleteManagerDialog,
  setDeleteManagerDialogToggle,
  setSelectedUndefinedTransactionId,
} from 'src/redux/slices/undefined-transaction.slice';
import { useDispatch, useSelector } from 'src/redux/store';

export function useDeleteUndefinedTransactionRequestManagerDialog() {
  //state
  const dashboardSide = useSelector(
    (state) => state.undefinedTransaction.manager.dashboardSide,
  );
  const toggle = useSelector(
    (state) =>
      state.undefinedTransaction.manager[dashboardSide].delete.dialog.toggle,
  );

  //hooks
  const dispatch = useDispatch();

  const openDialogById = (undefinedTransactionId: number) => {
    dispatch(setSelectedUndefinedTransactionId(undefinedTransactionId));
    dispatch(
      setDeleteManagerDialogToggle({
        toggle: true,
        dashboardSide: dashboardSide,
      }),
    );
  };

  const openDialog = (undefinedTransaction: UndefinedTransaction) => {
    dispatch(setSelectedUndefinedTransactionId(undefinedTransaction.id));
    dispatch(
      setDeleteManagerDialogToggle({
        toggle: true,
        dashboardSide: dashboardSide,
      }),
    );
  };

  const closeDialog = () => {
    dispatch(
      resetDeleteManagerDialog({
        dashboardSide: dashboardSide,
      }),
    );
  };

  return { toggle, openDialog, closeDialog, openDialogById };
}
