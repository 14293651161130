import { Icon } from '@iconify/react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { FileData } from 'src/@types/file';
import { audioPath } from 'src/assets';
import MyAvatar from 'src/components/MyAvatar';
import { momentVi } from 'src/utils/date-handle';
import useSound from 'use-sound';

interface ConversationCallStatusProps {
  avatar?: FileData;
  name?: string;
  isRing?: boolean;
  isCall?: boolean;
  isStart?: boolean;
  roomUrl?: string;
  onCall: () => void;
  timeCountDown?: number;
  onCloseCall: () => void;
  isReceiver?: boolean;
}

export default function ConversationCall({
  isCall = false,
  avatar,
  name,
  onCall,
  onCloseCall,
  timeCountDown,
  isReceiver,
  isRing = true,
  isStart,
}: ConversationCallStatusProps) {
  const [play, { stop, sound }] = useSound(audioPath.ringtone);
  const [play2, { stop: stop2, sound: sound2 }] = useSound(audioPath.ringtone2);
  const [isClickStartBtn, setIsClickStartBtn] = useState(false);
  const [currentTime, setCurrentTime] = useState(momentVi().startOf('day'));

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;
    if (isStart) {
      interval = setInterval(() => {
        setCurrentTime((prevTime) => prevTime.clone().add(1, 'second'));
      }, 1000);
    } else {
      setCurrentTime(momentVi().startOf('day'));
    }

    return () => {
      clearInterval(interval);
    };
  }, [isStart]);

  useEffect(() => {
    if (isStart || isCall) {
      setIsClickStartBtn(false);
    }
  }, [isStart, isCall]);

  useEffect(() => {
    if (sound) {
      sound.loop(true);
    }
  }, [sound]);

  useEffect(() => {
    if (sound2) {
      sound2.loop(true);
    }
  }, [sound2]);

  useEffect(() => {
    if (isCall && !isStart) {
      if (isReceiver) {
        play2();
      } else {
        play();
      }
    } else {
      stop();
      stop2();
    }
    return () => {
      stop();
      stop2();
    };
  }, [isCall, isReceiver, isStart]);

  return (
    <>
      <Stack p={2} px={3}>
        <Stack
          borderRadius={2}
          p={1.5}
          py={1}
          justifyContent={'space-between'}
          alignItems={'center'}
          direction={'row'}
          style={{
            background: 'black',
          }}
        >
          <Stack spacing={1} alignItems={'center'} direction={'row'}>
            <MyAvatar
              alt={name}
              src={avatar?.url}
              style={{ width: 30, height: 30 }}
            />

            <Typography width={'100%'} maxWidth={160} noWrap color={'white'}>
              {name}
            </Typography>
          </Stack>
          <Stack spacing={1} direction={'row'} alignItems={'center'}>
            {isRing && !isStart ? (
              <Stack
                sx={{
                  '&': {
                    animation: 'ring 1s infinite',
                  },
                }}
              >
                <Icon
                  fontSize={20}
                  icon={'lets-icons:bell'}
                  color={grey[500]}
                />
              </Stack>
            ) : (
              <Stack spacing={0.8} alignItems={'center'} direction={'row'}>
                <Box
                  minWidth={10}
                  minHeight={10}
                  borderRadius={10}
                  bgcolor={'red'}
                />
                <Typography fontWeight={700} color={'red'}>
                  REC
                </Typography>
              </Stack>
            )}
            {isStart && (
              <Typography fontWeight={700} color={grey[500]}>
                {currentTime.format('mm:ss')}
              </Typography>
            )}
            {timeCountDown && !isReceiver && !isStart && (
              <Countdown
                date={timeCountDown}
                renderer={({ minutes, seconds }) => (
                    <Typography fontWeight={700} color={grey[500]}>
                      {minutes < 10 ? '0' + minutes : minutes}:
                      {seconds < 10 ? '0' + seconds : seconds}
                    </Typography>
                  )}
              />
            )}

            <Stack direction={'row'}>
              {isReceiver && !isStart && (
                <IconButton
                  onClick={() => {
                    setIsClickStartBtn(true);
                    onCall();
                  }}
                  disabled={isClickStartBtn}
                >
                  <Stack
                    width={30}
                    height={30}
                    borderRadius={20}
                    bgcolor={isClickStartBtn ? grey[400] : '#1dc346'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Icon color={'white'} icon={'fluent:call-24-filled'} />
                  </Stack>
                </IconButton>
              )}

              <IconButton onClick={onCloseCall}>
                <Stack
                  width={30}
                  height={30}
                  borderRadius={20}
                  bgcolor={'red'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Icon color={'white'} icon={'fluent:call-24-filled'} />
                </Stack>
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
