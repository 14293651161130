import { Icon } from '@iconify/react';
import {
  Avatar,
  Box,
  Stack,
  StackProps,
  Typography,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { ConversationTypeType } from 'src/@types/conversation';
import { User, UserType } from 'src/@types/user';
import { customIcon } from 'src/assets';
import { IconSVG } from 'src/components/icon';

export interface ContactButtonProps extends StackProps {
  user?: User;
  isNewMsg?: boolean;
  type: ConversationTypeType;
  disable?: boolean;
  isCall?: boolean;
}

export default function ContactButton({
  user,
  type,
  isNewMsg,
  disable = false,
  isCall,
  ...other
}: ContactButtonProps) {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        cursor: 'pointer',
      }}
      pl={1.5}
      py={0.1}
      borderRadius={'50px 100px  100px 0px'}
      pr={0.5}
      spacing={user ? 1 : 3}
      alignItems={'center'}
      bgcolor={disable ? grey[800] : theme.palette.primary.main}
      direction={'row'}
      {...other}
    >
      {user ? (
        <Stack spacing={1} alignItems={'center'} direction={'row'}>
          <Box
            border={'1px solid white'}
            borderRadius={'100px'}
            maxHeight={37}
            minHeight={37}
            minWidth={37}
            maxWidth={37}
          >
            <Avatar
              src={user?.profile?.avatar?.url || ''}
              alt={user?.profile?.fullName}
              style={{ width: 35, height: 35 }}
            />
          </Box>
          <Stack color={'white'}>
            <Typography
              whiteSpace={'nowrap'}
              overflow={'hidden'}
              width={120}
              textOverflow={'ellipsis'}
              color={'currentcolor'}
              fontWeight={500}
              variant={'subtitle2'}
            >
              {user?.profile?.fullName}
            </Typography>
            <Typography color={'currentcolor'} variant="caption">
              {type !== ConversationTypeType.CONSULTANT_AND_STUDENT
                ? UserType.TEACHER
                : UserType.STUDENT}
            </Typography>
          </Stack>
        </Stack>
      ) : (
        <Stack spacing={0}>
          <Typography color={'white'} fontWeight={500}>
            Liên hệ
          </Typography>
          <Typography color={'white'} fontWeight={500} variant="subtitle2">
            Trung tâm CSKH
          </Typography>
        </Stack>
      )}
      <Stack
        justifyContent={'center'}
        alignItems={'center'}
        borderRadius={'100%'}
        bgcolor={'white'}
        width={41}
        height={41}
      >
        {isCall ? (
          <Icon
            style={{
              animation: 'shake 1.5s infinite',
            }}
            color="#1dc346"
            fontSize={ 25}
            icon={'ion:call'}
          />
        ) : (
          <IconSVG
            path={
              type === ConversationTypeType.CONSULTANT_AND_STUDENT
                ? customIcon.pen
                : customIcon.bookTeacher
            }
            css={{ color: theme.palette.primary.main, width: 22, height: 22 }}
          />
        )}
      </Stack>

      {isNewMsg && other.overflow !== 'hidden' && (
        <img
          alt="msg"
          src={customIcon.newMsg}
          style={{
            position: 'absolute',
            top: -5,
            right: 0,
            width: 18,
            height: 18,
          }}
        />
      )}
    </Stack>
  );
}
