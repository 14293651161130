const ROOT = 'work-shift';
const MANAGER_ROOT = ROOT + '/manager';

export const WORK_SHIFT_API = Object.freeze({
  ROOT: ROOT,
  CURRENT_USER: ROOT + '/current-user',
  OFF: ROOT + '/off',
  OFF_COUNT: ROOT + '/off-count',
  CHECK_IN_TIME_WORK: ROOT + '/check-in-time-work',
  MULTI: ROOT + '/multi',
  //Manager
  MANAGER_ROOT: MANAGER_ROOT,
});
