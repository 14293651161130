import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TransactionTypeType } from 'src/@types/transaction';
import {
  CreateDialog,
  CreateMode,
  ITransactionReportState,
  ManagerSearchMode,
  TransactionReportTypeType,
  TransactionReportVerifyFilter,
  VerifyDialog,
  VerifyMode,
} from 'src/@types/transaction-report';

type VerifyType = 'deposit' | 'withdraw';
type CreateType = 'deposit' | 'withdraw';

const initialState: ITransactionReportState = {
  selectedTransactionReportId: null,
  create: {
    deposit: {
      type: null,
      mode: 'CREATE',
      dialog: {
        toggle: false,
      },
    },
    withdraw: {
      type: null,
      mode: 'CREATE',
      dialog: {
        toggle: false,
      },
    },
  },
  verify: {
    mode: 'VERIFY',
    type: null,
    filter: {
      searchCharacter: '',
      transactionType: TransactionTypeType.DEPOSIT,
      searchMode: ManagerSearchMode.VERIFY,
      bankAccountId: null,
      ewalletAccountId: null,
    },
    deposit: {
      dialog: {
        toggle: false,
      },
    },
    withdraw: {
      dialog: {
        toggle: false,
      },
    },
  },
};

const slice = createSlice({
  name: 'transactionReport',
  initialState,
  reducers: {
    //Common
    setSelectedTransactionReportId: (
      state,
      action: PayloadAction<number | null>,
    ) => {
      state.selectedTransactionReportId = action.payload;
    },

    //Create
    //--Type
    setCreateType: (
      state,
      action: PayloadAction<{
        type: TransactionReportTypeType | null;
        createType: CreateType;
      }>,
    ) => {
      const { createType, type } = action.payload;

      state.create[createType].type = type;
    },

    //--Mode
    setCreateMode: (
      state,
      action: PayloadAction<{ mode: CreateMode; createType: CreateType }>,
    ) => {
      const { createType, mode } = action.payload;

      state.create[createType].mode = mode;
    },

    //--Dialog
    setCreateDialog: (
      state,
      action: PayloadAction<{
        createType: CreateType;
        createDialog: Partial<CreateDialog>;
      }>,
    ) => {
      const { createDialog, createType } = action.payload;

      state.create[createType].dialog = {
        ...state.create[createType].dialog,
        ...createDialog,
      };
    },

    closeCreateDialog: (
      state,
      action: PayloadAction<{
        createType: CreateType;
      }>,
    ) => {
      const { createType } = action.payload;

      state.selectedTransactionReportId = null;
      state.create[createType] = initialState.create[createType];
    },

    //Verify
    resetVerify: (state) => {
      state.verify = initialState.verify;
    },

    setVerifyFilter: (
      state,
      action: PayloadAction<Partial<TransactionReportVerifyFilter>>,
    ) => {
      state.verify.filter = {
        ...state.verify.filter,
        ...action.payload,
      };
    },

    setVerifyDialog: (
      state,
      action: PayloadAction<{
        verifyDialog: Partial<VerifyDialog>;
        verifyType: VerifyType;
      }>,
    ) => {
      const { verifyDialog, verifyType } = action.payload;

      state.verify[verifyType].dialog = {
        ...state.verify[verifyType].dialog,
        ...verifyDialog,
      };
    },

    setVerifyMode: (state, action: PayloadAction<VerifyMode>) => {
      state.verify.mode = action.payload;
    },

    setVerifyType: (
      state,
      action: PayloadAction<TransactionReportTypeType | null>,
    ) => {
      state.verify.type = action.payload;
    },

    closeVerifyDialog: (
      state,
      action: PayloadAction<{ verifyType: VerifyType }>,
    ) => {
      const { verifyType } = action.payload;

      state.verify[verifyType] = initialState.verify[verifyType];
    },
  },
});

export default slice.reducer;

export const {
  setVerifyFilter,
  setCreateDialog,
  setSelectedTransactionReportId,
  setCreateType,
  setCreateMode,
  closeCreateDialog,
  closeVerifyDialog,
  setVerifyDialog,
  setVerifyMode,
  setVerifyType,
  resetVerify,
} = slice.actions;
