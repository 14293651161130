import { VerifyMode } from 'src/@types/transaction-report';
import {
  closeVerifyDialog,
  setSelectedTransactionReportId,
  setVerifyDialog,
  setVerifyMode,
} from 'src/redux/slices/transaction-report.slice';
import { useDispatch, useSelector } from 'src/redux/store';

export function useVerifyDialog() {
  const toggle = useSelector(
    (state) => state.transactionReport.verify.withdraw.dialog.toggle,
  );

  const dispatch = useDispatch();

  const openWithId = (id: number, mode: VerifyMode) => {
    dispatch(setVerifyMode(mode));
    dispatch(
      setVerifyDialog({
        verifyDialog: {
          toggle: true,
        },
        verifyType: 'withdraw',
      }),
    );
    dispatch(setSelectedTransactionReportId(id));
  };

  const close = () => {
    dispatch(setSelectedTransactionReportId(null));
    dispatch(
      closeVerifyDialog({
        verifyType: 'withdraw',
      }),
    );
  };

  return { toggle, close, openWithId };
}
