import { Stack, StackProps, Typography, styled, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

//----------------------------------------------------------------
type Props = {
  month: number;
  isActive?: boolean;
} & StackProps;

type RootStyleProps = {
  isActive?: boolean;
} & StackProps;

//----------------------------------------------------------------
const RootStyle = styled(Stack)<RootStyleProps>(({ theme, isActive }) => ({
  minHeight: '50px',
  borderRadius: '4px',
  border: `1px solid ${grey[200]}`,
  padding: theme.spacing(0.5, 0.5),
  cursor: 'pointer',
  ':hover': {
    backgroundColor: grey[200],
  },
  ...(isActive && {
    backgroundColor: grey[100],
    border: `1px solid ${grey[200]}`,
  }),
}));

//----------------------------------------------------------------
export default function WorkMonthCalendarItem({ month, ...other }: Props) {
  const theme = useTheme();

  return (
    <RootStyle direction={'row'} {...other}>
      <Stack width={30} />
      <Stack>
        <Typography color={theme.palette.primary.main} fontSize={16}>
          Tháng <b>{month}</b>
        </Typography>
      </Stack>
    </RootStyle>
  );
}
