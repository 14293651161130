// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import {
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
  Stack,
  StackProps,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { ReactNode } from 'react';
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

export type RadioOption<T = any> = {
  label: string | ReactNode;
  value: T;
  endAdornment?: ReactNode;
  startAdornment?: ReactNode;
  isDisabled?: boolean;
};

type IProps = {
  name: string;
  options: RadioOption[];
  radioProps?: RadioProps;
  rowProps?: StackProps;
  labelProps?: TypographyProps;
  stackProps?: StackProps;
  itemStackProps?: StackProps;
  isShowLabel?: boolean;
  formControlLabelProps?: Omit<FormControlLabelProps, 'control' | 'label'>;
  disabled?: boolean;
};

export type RHFRadioProps = IProps & RadioGroupProps;

export default function RHFRadioGroup({
  name,
  options,
  radioProps,
  labelProps,
  rowProps,
  stackProps,
  isShowLabel = true,
  formControlLabelProps,
  itemStackProps,
  disabled,
  ...other
}: RHFRadioProps) {
  const { control } = useFormContext();

  const theme = useTheme();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <RadioGroup {...field} row {...other}>
            <Stack flex={1} {...stackProps}>
              {options.map((option, index) => (
                <Stack
                  className="radio-item"
                  key={index}
                  justifyContent={'space-between'}
                  direction={'row'}
                  alignItems={'center'}
                  width={'100%'}
                  {...itemStackProps}
                >
                  {option.startAdornment}
                  <FormControlLabel
                    value={option.value}
                    control={
                      <Radio
                        icon={
                          <Iconify
                            sx={{
                              height: 20,
                              width: 20,
                            }}
                            color={
                              disabled || option.isDisabled
                                ? grey[500]
                                : !!error
                                  ? theme.palette.error.main
                                  : theme.palette.primary.main
                            }
                            icon={'material-symbols:circle-outline'}
                          />
                        }
                        checkedIcon={
                          <Iconify
                            sx={{
                              height: 20,
                              width: 20,
                            }}
                            color={
                              disabled || option.isDisabled
                                ? grey[500]
                                : !!error
                                  ? theme.palette.error.main
                                  : theme.palette.primary.main
                            }
                            icon={'material-symbols:check-circle'}
                          />
                        }
                        {...radioProps}
                        color={!!error ? 'error' : 'default'}
                      />
                    }
                    label={
                      <>
                        {typeof option.label === 'string' && (
                          <Typography
                            {...labelProps}
                            color={
                              disabled || option.isDisabled
                                ? grey[500]
                                : !!error
                                  ? theme.palette.error.main
                                  : labelProps?.color
                            }
                          >
                            {option.label}
                          </Typography>
                        )}
                        {React.isValidElement(option.label) && option.label}
                      </>
                    }
                    disabled={disabled || option.isDisabled}
                    {...formControlLabelProps}
                  />
                  {option.endAdornment}
                </Stack>
              ))}
            </Stack>
          </RadioGroup>
          {isShowLabel && !!error && (
            <FormHelperText error sx={{ px: 2 }}>
              {error.message}
            </FormHelperText>
          )}
        </>
      )}
    />
  );
}
