import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import io, { Socket } from 'socket.io-client';
import { AuthState } from 'src/@types/auth.admin';
import { HOST_SOCKET_IO, NOTIFICATION_HOST_SOCKET_IO } from 'src/config';
import { getTokenKeyByRole } from 'src/utils/getToken';
import { AuthContext } from './JWTContext';
import { ConsultantRoleType, UserType } from 'src/@types/user';

interface SocketContextType {
  acctSocket?: Socket;
  notiAcctSocket?: Socket;
  contSocket?: Socket;
  notiContSocket?: Socket;
}

const SocketContext = createContext<SocketContextType>({});

// ----------------------------------------------------------------------

type SocketProviderProps = {
  children: ReactNode;
};

//----------------------------------------------------------------

function SocketProvider({ children }: SocketProviderProps) {
  const [acctSocket, setAcctSocket] = useState<Socket>();
  const [notiAcctSocket, setNotiAcctSocket] = useState<Socket>();
  const [contSocket, setContSocket] = useState<Socket>();
  const [notiContSocket, setNotiContSocket] = useState<Socket>();
  const { isAuthenticated, userType, userRole } = useContext(
    AuthContext,
  ) as AuthState;

  const getSocket = useCallback(
    ({ url, token }: { url: string; token: string }) => {
      const socket = io(`${url}?token=Bearer ${token}`, {
        timeout: 30000,
        withCredentials: true,
        transports: ['websocket', 'polling'],
      });
      return socket;
    },
    [],
  );

  useEffect(() => {
    if (isAuthenticated) {
      if (!userType) return;

      let currentAccessToken;
      let isSignInByManager = false;

      //For manager sign in staff account
      if (window.name && window.name !== 'undefined') {
        const data = JSON.parse(window.name);

        if (data.accessToken) {
          currentAccessToken = data.accessToken;

          isSignInByManager = true;
        }
      }

      const tokenKey = getTokenKeyByRole(userType as any);
      const token = localStorage.getItem(tokenKey as string);

      if (!token) return;

      const socket = getSocket({
        url: HOST_SOCKET_IO,
        token: isSignInByManager ? currentAccessToken : token,
      });
      const notiSocket = getSocket({
        url: NOTIFICATION_HOST_SOCKET_IO,
        token: isSignInByManager ? currentAccessToken : token,
      });

      if (
        userType === UserType.CONSULTANT &&
        userRole === ConsultantRoleType.STAFF
      ) {
        socket.emit('join-room-consultant', null);
        setContSocket(socket);
        setNotiContSocket(notiSocket);
      }
      if (userType === UserType.ACCOUNTANT) {
        setAcctSocket(socket);
        setNotiAcctSocket(notiSocket);
      }
    }
  }, [isAuthenticated, userType, userRole]);

  return (
    <SocketContext.Provider
      value={{
        contSocket,
        acctSocket,
        notiAcctSocket,
        notiContSocket,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}

export { SocketContext, SocketProvider };
