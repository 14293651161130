import { ReactNode, Suspense, createContext, lazy } from 'react';
import { Notification } from 'src/@types/notification';
import { UndefinedTransactionTypeType } from 'src/@types/undefined-transaction';
import LoadingScreen from 'src/components/LoadingScreen';
import useToggle from 'src/hooks/useToggle';
import { setSelectedUndefinedTransactionId } from 'src/redux/slices/undefined-transaction.slice';
import { useDispatch } from 'src/redux/store';
import { useCreateDialog as useCreateCompanyDepositTransaction } from 'src/sections/accountant/@dashboard/account-money-manage/common/deposit/dialog/useCreateDialog,';
import { useCreateDialog as useCreateCompanyWithdrawTransaction } from 'src/sections/accountant/@dashboard/account-money-manage/common/withdraw/dialog/useCreateDialog,';
import { useCreateDialog as useCreateExpenseReportTransactionDialog } from 'src/sections/accountant/@dashboard/account-money-manage/expense/report/dialog/useCreateDialog';
import { useCreateDialog as useCreateIncomeReportTransactionDialog } from 'src/sections/accountant/@dashboard/account-money-manage/income/report/dialog/useCreateDialog';
import { useVerifyDialog as useVerifyCompanyDepositTransactionDialog } from 'src/sections/accountant/@dashboard/company-transaction/deposit/dialog/useVerifyDialog';
import { useVerifyDialog as useVerifyCompanyWithdrawTransactionDialog } from 'src/sections/accountant/@dashboard/company-transaction/withdraw/dialog/useVerifyDialog';
import useDepositVerificationDialog from 'src/sections/accountant/@dashboard/deposit-request/dialog/useDepositVerificationDialog';
import { useDepositSupportRequestVerificationDialog } from 'src/sections/accountant/@dashboard/deposit-support-request/dialog/useDepositSupportRequestVerificationDialog';
import { useVerifyDialog as userVerifyDepositTransactionReportDialog } from 'src/sections/accountant/@dashboard/transaction-report/deposit/dialog/useVerifyDialog';
import { useVerifyDialog as userVerifyWithdrawTransactionReportDialog } from 'src/sections/accountant/@dashboard/transaction-report/withdraw/dialog/useVerifyDialog';
import { useDeleteUndefinedTransactionRequestManagerDialog } from 'src/sections/accountant/@dashboard/undefined-transaction/dialog/useDeleteUndefinedTransactionRequestManagerDialog';
import { useVerifyUndefinedTransactionDialog } from 'src/sections/accountant/@dashboard/undefined-transaction/dialog/useVerifyUndefinedTransactionRequestDialog';
import { useVerifyUndefinedTransactionRequestManagerDialog } from 'src/sections/accountant/@dashboard/undefined-transaction/dialog/useVerifyUndefinedTransactionRequestManagerDialog';
import { useWithdrawVerificationDialog } from 'src/sections/accountant/@dashboard/withdraw-request/dialog/useWithdrawVerificationDialog';
import { useWithdrawSupportVerificationDialog } from 'src/sections/accountant/@dashboard/withdraw-support-request/dialog/useWithdrawSupportVerificationDialog';

const CreateCompanyWithdrawTransactionDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/account-money-manage/common/withdraw/dialog/CreateDialog'
    ),
);
const CreateCompanyDepositTransactionDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/account-money-manage/common/deposit/dialog/CreateDialog'
    ),
);
const RejectComDepositTranDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/account-money-manage/common/deposit/dialog/RejectDialog'
    ),
);
const RejectComWithdrawTranDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/account-money-manage/common/withdraw/dialog/RejectDialog'
    ),
);
const CreateIncomeReportDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/account-money-manage/income/report/dialog/CreateDialog'
    ),
);
const CreateExpenseReportDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/account-money-manage/expense/report/dialog/CreateDialog'
    ),
);
const TranReptRejectedDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/account-money-manage/income/report/dialog/RejectDialog'
    ),
);
const VerifyCompanyWithdrawTransactionDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/company-transaction/withdraw/dialog/VerifyDialog'
    ),
);
const VerifyCompanyDepositTransactionDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/company-transaction/deposit/dialog/VerifyDialog'
    ),
);
const VerifyDepositTransactionReportDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/transaction-report/deposit/dialog/VerifyDialog'
    ),
);
const VerifyWithdrawTransactionReportDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/transaction-report/withdraw/dialog/VerifyDialog'
    ),
);
const DeleteUndefinedTransactionConfirmDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/undefined-transaction/dialog/DeleteUndefinedTransactionConfirmDialog'
    ),
);
const DeleteUndefinedTransactionRequestManagerDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/undefined-transaction/dialog/DeleteUndefinedTransactionRequestManagerDialog'
    ),
);
const RejectUndefinedTransactionStaffDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/undefined-transaction/dialog/RejectUndefinedTransactionStaffDialog'
    ),
);
const RejectVerifyUndefinedTransactionRequestReasonDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/undefined-transaction/dialog/RejectVerifyUndefinedTransactionRequestReasonDialog'
    ),
);
const VerifyUndefinedReceiptTransactionManagerDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/undefined-transaction/dialog/VerifyUndefinedTransactionRequestManagerDialog'
    ),
);
//
const VerifyUndefinedTransactionDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/undefined-transaction/dialog/VerifyUndefinedTransactionRequestDialog'
    ),
);
const DepositVerificationDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/deposit-request/dialog/DepositVerificationDialog'
    ),
);
const VerifyDepSupReqVerDialogContainer = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/deposit-support-request/dialog/VerifyDepSupReqVerDialogContainer'
    ),
);
const ViewDepSupReqVerByUndDialogContainer = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/deposit-support-request/dialog/ViewDepSupReqVerByUndDialogContainer'
    ),
);
const ViewDepSupReqVerDialogContainer = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/deposit-support-request/dialog/ViewDepSupReqVerDialogContainer'
    ),
);
const WithdrawVerificationDialog = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/withdraw-request/dialog/WithdrawVerificationDialog'
    ),
);
const VerifyWithSupReqVerContainer = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/withdraw-support-request/dialog/VerifyWithSupReqVerContainer'
    ),
);
const ViewWithSupReqVerContainer = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/withdraw-support-request/dialog/ViewWithSupReqVerContainer'
    ),
);
const ViewWithSupReqVerByUndContainer = lazy(
  () =>
    import(
      'src/sections/accountant/@dashboard/withdraw-support-request/dialog/ViewWithSupReqVerByUndContainer'
    ),
);

export type DialogContextType = {
  handleOpenRejDelUndTranStaffDia: (notification: Notification) => void;
  handleOpenConDelUndTranStaffDia: (notification: Notification) => void;
  handleOpenRejVerUndTranReasonsDia: (notification: Notification) => void;
  handleOpenRejVerDepositTranReptReasonsDia: (
    notification: Notification,
  ) => void;
  handleOpenRejVerWithdrawTranReptReasonsDia: (
    notification: Notification,
  ) => void;
  handleOpenRejComWithdrawTranVerReasonsDia: (
    notification: Notification,
  ) => void;
  handleOpenRejComDepositTranVerReasonsDia: (
    notification: Notification,
  ) => void;
};

const DialogContext = createContext<DialogContextType | null>(null);

type DialogProviderProps = {
  children: ReactNode;
};

function DialogProvider({ children }: DialogProviderProps) {
  //Hook
  const dispatch = useDispatch();

  //Toggle Hook
  const {
    onOpen: openRejDelUndTranStaffDia,
    onClose: closeRejDelUndTranStaffDia,
    toggle: rejDelUndTranStaffDiaToggle,
    value: rejDelUndTranStaffDiaValue,
  } = useToggle();
  const {
    onOpen: openConDelUndTranStaffDia,
    onClose: closeConDelUndTranStaffDia,
    toggle: conDelUndTranStaffDiaToggle,
  } = useToggle();
  const {
    onOpen: openRejVerUndTranReasonsDia,
    onClose: closeRejVerUndTranReasonsDia,
    toggle: rejVerUndTranReasonsDiaToggle,
    value: rejVerUndTranReasonsDiaValue,
  } = useToggle();
  const {
    onOpen: openRejVerDepositTranReptReasonsDia,
    onClose: closeRejVerDepositTranReptReasonsDia,
    toggle: rejVerDepositTranReptReasonsDiaToggle,
    value: rejVerDepositTranReptReasonsDiaValue,
  } = useToggle();
  const {
    onOpen: openRejVerWithdrawTranReptReasonsDia,
    onClose: closeRejVerWithdrawTranReptReasonsDia,
    toggle: rejVerWithdrawTranReptReasonsDiaToggle,
    value: rejVerWithdrawTranReptReasonsDiaValue,
  } = useToggle();
  const {
    onOpen: openRejComWithdrawTranVerReasonsDia,
    onClose: closeRejComWithdrawTranVerReasonsDia,
    toggle: rejComWithdrawTranVerReasonsDiaToggle,
    value: rejComWithdrawTranVerReasonsDiaValue,
  } = useToggle();
  const {
    onOpen: openRejComDepositTranVerReasonsDia,
    onClose: closeRejComDepositTranVerReasonsDia,
    toggle: rejComDepositTranVerReasonsDiaToggle,
    value: rejComDepositTranVerReasonsDiaValue,
  } = useToggle();

  //Specific hooks
  const {
    close: closeRevenueCompanyWithdrawTransaction,
    toggle: companyRevenueCompanyWithdrawTransactionToggle,
  } = useCreateCompanyWithdrawTransaction({
    dashboardSide: 'revenue',
  });
  const {
    close: closeExpenseCompanyWithdrawTransaction,
    toggle: companyExpenseCompanyWithdrawTransactionToggle,
  } = useCreateCompanyWithdrawTransaction({
    dashboardSide: 'expense',
  });
  const {
    close: closeCompanyDepositTransaction,
    toggle: companyDepositTransactionToggle,
  } = useCreateCompanyDepositTransaction();
  const {
    close: closeIncomeReportDialogClose,
    toggle: incomeReportDialogToggle,
  } = useCreateIncomeReportTransactionDialog();
  const {
    close: closeExpenseReportDialogClose,
    toggle: expenseReportDialogToggle,
  } = useCreateExpenseReportTransactionDialog();
  const {
    close: closeVerifyDepositTransactionReportDialog,
    toggle: verifyDepositTransactionReportDialogToggle,
  } = userVerifyDepositTransactionReportDialog();
  const {
    close: closeVerifyWithdrawTransactionReportDialog,
    toggle: verifyWithdrawTransactionReportDialogToggle,
  } = userVerifyWithdrawTransactionReportDialog();
  const {
    closeDialog: closeDelUndTransReqManDia,
    toggle: delUndTransReqManDiaToggle,
  } = useDeleteUndefinedTransactionRequestManagerDialog();
  const {
    closeDialog: closeVerUndTransReqManDia,
    toggle: verUndTransReqManDiaToggle,
  } = useVerifyUndefinedTransactionRequestManagerDialog();
  const {
    closeDialog: closeVerUndTranReqDialog,
    toggle: openVerUndTranReqToggle,
  } = useVerifyUndefinedTransactionDialog();
  const {
    closeDialog: closeVerifyCompanyWithdrawTransactionDialog,
    toggle: verifyCompanyWithdrawTransactionDialogToggle,
  } = useVerifyCompanyWithdrawTransactionDialog();
  const {
    closeDialog: closeVerifyCompanyDepositTransactionDialog,
    toggle: verifyCompanyDepositTransactionDialogToggle,
  } = useVerifyCompanyDepositTransactionDialog();
  const {
    closeTransactionDialog: closeVerifyDepositRequestDialog,
    toggle: verifyDepositRequestToggle,
  } = useDepositVerificationDialog();
  const {
    closeDialog: closeVerifyWithdrawRequestDialog,
    toggle: verifyWithdrawRequestToggle,
  } = useWithdrawVerificationDialog();
  const {
    closeTransactionDialog: closeDepositSupportRequestDialog,
    toggle: depositSupportRequestToggle,
    mode: depositSupportRequestMode,
    isShowByUndefinedTransaction:
      isDepositSupportRequestShowByUndefinedTransaction,
  } = useDepositSupportRequestVerificationDialog();
  const {
    toggle: withdrawSupportRequestToggle,
    mode: withdrawSupportRequestMode,
    closeDialog: closeWithdrawSupportRequestDialog,
    closeByUndefinedTransactionDialog:
      closeWithdrawSupportRequestDialogByUndefinedTransaction,
    isShowByUndefinedTransaction:
      isWithdrawSupportRequestShowByUndefinedTransaction,
  } = useWithdrawSupportVerificationDialog();

  //Function handle for toggle hooks
  const handleOpenRejDelUndTranStaffDia = (data: Notification) => {
    const value: { [key: string]: any } = {};
    data.templateParameter.forEach((item) => {
      value[item.name] = item.value;
    });
    openRejDelUndTranStaffDia(value);
  };

  const handleOpenConDelUndTranStaffDia = (data: Notification) => {
    if (data) {
      dispatch(setSelectedUndefinedTransactionId(data.topic.value));
      openConDelUndTranStaffDia();
    }
  };

  const handleCloseConDelUndTranStaffDia = () => {
    dispatch(setSelectedUndefinedTransactionId(null));
    closeConDelUndTranStaffDia();
  };

  const handleOpenRejVerUndTranReasonsDia = (data: Notification) => {
    const value: { [key: string]: any } = {};
    data.templateParameter.forEach((item) => {
      value[item.name] = item.value;
    });

    openRejVerUndTranReasonsDia({
      undTranId: data.topic.value,
      ...value,
    });
  };

  const handleOpenRejVerDepositTranReptReasonsDia = (data: Notification) => {
    openRejVerDepositTranReptReasonsDia(data.topic.value);
  };

  const handleOpenRejVerWithdrawTranReptReasonsDia = (data: Notification) => {
    openRejVerWithdrawTranReptReasonsDia(data.topic.value);
  };

  const handleOpenRejComWithdrawTranVerReasonsDia = (data: Notification) => {
    openRejComWithdrawTranVerReasonsDia(data.topic.value);
  };

  const handleOpenRejComDepositTranVerReasonsDia = (data: Notification) => {
    openRejComDepositTranVerReasonsDia(data.topic.value);
  };

  const values = {
    handleOpenRejDelUndTranStaffDia,
    handleOpenConDelUndTranStaffDia,
    handleOpenRejVerUndTranReasonsDia,
    handleOpenRejVerDepositTranReptReasonsDia,
    handleOpenRejVerWithdrawTranReptReasonsDia,
    handleOpenRejComWithdrawTranVerReasonsDia,
    handleOpenRejComDepositTranVerReasonsDia,
  };

  return (
    <DialogContext.Provider value={values}>
      {children}
      <Suspense fallback={<LoadingScreen isBlurryBackground />}>
        {verifyDepositRequestToggle && (
          <DepositVerificationDialog
            onClose={closeVerifyDepositRequestDialog}
            open={verifyDepositRequestToggle}
          />
        )}
        {verifyWithdrawRequestToggle && (
          <WithdrawVerificationDialog
            onClose={closeVerifyWithdrawRequestDialog}
            open={verifyWithdrawRequestToggle}
          />
        )}
        {depositSupportRequestToggle &&
          depositSupportRequestMode === 'EDIT' && (
            <VerifyDepSupReqVerDialogContainer
              open={depositSupportRequestToggle}
              onClose={closeDepositSupportRequestDialog}
            />
          )}
        {depositSupportRequestToggle &&
          depositSupportRequestMode === 'VIEW' &&
          !isDepositSupportRequestShowByUndefinedTransaction && (
            <ViewDepSupReqVerDialogContainer
              open={depositSupportRequestToggle}
              onClose={closeDepositSupportRequestDialog}
            />
          )}
        {depositSupportRequestToggle &&
          depositSupportRequestMode === 'VIEW' &&
          isDepositSupportRequestShowByUndefinedTransaction && (
            <ViewDepSupReqVerByUndDialogContainer
              open={depositSupportRequestToggle}
              onClose={closeDepositSupportRequestDialog}
            />
          )}
        {withdrawSupportRequestToggle &&
          withdrawSupportRequestMode === 'EDIT' && (
            <VerifyWithSupReqVerContainer
              open={withdrawSupportRequestToggle}
              onClose={closeWithdrawSupportRequestDialog}
            />
          )}
        {withdrawSupportRequestToggle &&
          withdrawSupportRequestMode === 'VIEW' &&
          !isWithdrawSupportRequestShowByUndefinedTransaction && (
            <ViewWithSupReqVerContainer
              open={withdrawSupportRequestToggle}
              onClose={closeWithdrawSupportRequestDialog}
            />
          )}
        {withdrawSupportRequestToggle &&
          withdrawSupportRequestMode === 'VIEW' &&
          isWithdrawSupportRequestShowByUndefinedTransaction && (
            <ViewWithSupReqVerByUndContainer
              open={withdrawSupportRequestToggle}
              onClose={closeWithdrawSupportRequestDialogByUndefinedTransaction}
            />
          )}
        {openVerUndTranReqToggle && (
          <VerifyUndefinedTransactionDialog
            open={openVerUndTranReqToggle}
            onClose={closeVerUndTranReqDialog}
          />
        )}
        {delUndTransReqManDiaToggle && (
          <DeleteUndefinedTransactionRequestManagerDialog
            open={delUndTransReqManDiaToggle}
            onClose={closeDelUndTransReqManDia}
          />
        )}
        {verUndTransReqManDiaToggle && (
          <VerifyUndefinedReceiptTransactionManagerDialog
            open={verUndTransReqManDiaToggle}
            onClose={closeVerUndTransReqManDia}
          />
        )}
        {rejDelUndTranStaffDiaToggle && (
          <RejectUndefinedTransactionStaffDialog
            open={rejDelUndTranStaffDiaToggle}
            type={UndefinedTransactionTypeType.UNIDENTIFIED_RECEIPT}
            onClose={closeRejDelUndTranStaffDia}
            data={rejDelUndTranStaffDiaValue}
          />
        )}
        {conDelUndTranStaffDiaToggle && (
          <DeleteUndefinedTransactionConfirmDialog
            open={conDelUndTranStaffDiaToggle}
            onClose={handleCloseConDelUndTranStaffDia}
          />
        )}
        {rejVerUndTranReasonsDiaToggle && (
          <RejectVerifyUndefinedTransactionRequestReasonDialog
            open={rejVerUndTranReasonsDiaToggle}
            onClose={closeRejVerUndTranReasonsDia}
            data={rejVerUndTranReasonsDiaValue}
          />
        )}
        {incomeReportDialogToggle && (
          <CreateIncomeReportDialog
            open={incomeReportDialogToggle}
            onClose={closeIncomeReportDialogClose}
          />
        )}
        {expenseReportDialogToggle && (
          <CreateExpenseReportDialog
            open={expenseReportDialogToggle}
            onClose={closeExpenseReportDialogClose}
          />
        )}
        {companyRevenueCompanyWithdrawTransactionToggle && (
          <CreateCompanyWithdrawTransactionDialog
            open={companyRevenueCompanyWithdrawTransactionToggle}
            onClose={closeRevenueCompanyWithdrawTransaction}
            dashboardSide="revenue"
          />
        )}
        {companyExpenseCompanyWithdrawTransactionToggle && (
          <CreateCompanyWithdrawTransactionDialog
            open={companyExpenseCompanyWithdrawTransactionToggle}
            onClose={closeExpenseCompanyWithdrawTransaction}
            dashboardSide="expense"
          />
        )}
        {companyDepositTransactionToggle && (
          <CreateCompanyDepositTransactionDialog
            open={companyDepositTransactionToggle}
            onClose={closeCompanyDepositTransaction}
          />
        )}
        {verifyDepositTransactionReportDialogToggle && (
          <VerifyDepositTransactionReportDialog
            open={verifyDepositTransactionReportDialogToggle}
            onClose={closeVerifyDepositTransactionReportDialog}
          />
        )}
        {verifyWithdrawTransactionReportDialogToggle && (
          <VerifyWithdrawTransactionReportDialog
            open={verifyWithdrawTransactionReportDialogToggle}
            onClose={closeVerifyWithdrawTransactionReportDialog}
          />
        )}
        {rejVerDepositTranReptReasonsDiaToggle && (
          <TranReptRejectedDialog
            open={rejVerDepositTranReptReasonsDiaToggle}
            onClose={closeRejVerDepositTranReptReasonsDia}
            id={rejVerDepositTranReptReasonsDiaValue}
          />
        )}
        {rejVerWithdrawTranReptReasonsDiaToggle && (
          <TranReptRejectedDialog
            open={rejVerWithdrawTranReptReasonsDiaToggle}
            onClose={closeRejVerWithdrawTranReptReasonsDia}
            id={rejVerWithdrawTranReptReasonsDiaValue}
          />
        )}
        {verifyCompanyWithdrawTransactionDialogToggle && (
          <VerifyCompanyWithdrawTransactionDialog
            open={verifyCompanyWithdrawTransactionDialogToggle}
            onClose={closeVerifyCompanyWithdrawTransactionDialog}
          />
        )}
        {verifyCompanyDepositTransactionDialogToggle && (
          <VerifyCompanyDepositTransactionDialog
            open={verifyCompanyDepositTransactionDialogToggle}
            onClose={closeVerifyCompanyDepositTransactionDialog}
          />
        )}
        {rejComDepositTranVerReasonsDiaToggle && (
          <RejectComDepositTranDialog
            open={rejComDepositTranVerReasonsDiaToggle}
            onClose={closeRejComDepositTranVerReasonsDia}
            id={rejComDepositTranVerReasonsDiaValue}
          />
        )}
        {rejComWithdrawTranVerReasonsDiaToggle && (
          <RejectComWithdrawTranDialog
            open={rejComWithdrawTranVerReasonsDiaToggle}
            onClose={closeRejComWithdrawTranVerReasonsDia}
            id={rejComWithdrawTranVerReasonsDiaValue}
          />
        )}
      </Suspense>
    </DialogContext.Provider>
  );
}

export { DialogContext, DialogProvider };
