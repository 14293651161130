import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface INotificationConfig {
  userId: number;
  isOpenVolume: boolean;
}

export interface INotificationState {
  configs: INotificationConfig[];
}

export type NotificationPayloadData = {
  isOpenVolume?: boolean;
};

export type NotificationPayload<T = any> = {
  data: T;
  userId: number;
};

const initialState: INotificationState = {
  configs: [],
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    update: (
      state,
      action: PayloadAction<NotificationPayload<NotificationPayloadData>>,
    ) => {
      const { data, userId } = action.payload;

      let flag = false;

      const configs = state.configs
        ? state.configs?.map((notiArea) => {
            if (notiArea.userId === userId) {
              flag = true;
              return {
                ...notiArea,
                ...data,
              };
            }

            return notiArea;
          })
        : [];

      state.configs = configs;

      if (flag) return;

      state.configs = [
        ...state.configs,
        {
          userId,
          isOpenVolume: data.isOpenVolume || true,
        },
      ];
    },
  },
});

export default slice.reducer;

export const { update } = slice.actions;
