export const CONVERSATION_API = Object.freeze({
  ROOT: '/conversation',
  OVERVIEW_TASK_HISTORY: '/conversation/overview-task-history',
  SEARCH_TASK_HISTORY: '/conversation/search-task-history',
});

export const CONTACT_CONVERSATION_API = Object.freeze({
  ROOT: '/contact-conversation',
  CONVERSATIONS_TODAY: '/conversations-today',
  RECORD: '/record'
});
